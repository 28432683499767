import "./ScheduledScreen.css";

import React, { Component } from "react";
import { utcStringToLocalString } from "../../../../helpers/dateHelper";
import AdsBar from "../RideScreen/AdsBar/AdsBar";
import RoundButton from "../../CustomComponents/Button/RoundButton";
import { Trans } from "react-i18next";
import { CANNABIS } from "../../../../const/RideTypes";

var moment = require("moment");

class ScheduledScreen extends Component {
  constructor(props) {
    super(props);
    const scheduledDatetimeFormatted = moment(utcStringToLocalString(this.props.scheduledDatetime));
    const expectedDeliveredByDatetimeFormatted = moment(utcStringToLocalString(this.props.expectedDeliveredByDatetime));
    this.state = {
      scheduledTime: scheduledDatetimeFormatted.format('LLL'),
      expectedDeliveredBy: {
          date: expectedDeliveredByDatetimeFormatted.format('dddd LL'),
          fromTime: moment(expectedDeliveredByDatetimeFormatted).subtract(30, 'minutes').format('LT'),
          toTime: moment(expectedDeliveredByDatetimeFormatted).format('LT'),
      }
    };
  }

  render() {
    const scheduledTimeDisplay = (this.props.rideServiceTypeId == CANNABIS) ?
      (<div>
        <h2 className="scheduled-body">
          <Trans>SCHEDULED_ON_DELIVER_BY</Trans>
        </h2>
        <p className="scheduled-datetime">
          {this.state.expectedDeliveredBy.date}
        </p>
        <p className="scheduled-datetime-alt">
          <Trans>SCHEDULED_BETWEEN</Trans>&nbsp;
          <strong>
            {this.state.expectedDeliveredBy.fromTime}&nbsp;
          </strong>
          <Trans>SCHEDULED_AND</Trans>&nbsp;
          <strong>
            {this.state.expectedDeliveredBy.toTime}
          </strong>
        </p>
      </div>) :
      (<div>
        <h2 className="scheduled-body">
          <Trans>SCHEDULED_ON</Trans>
        </h2>
        <p className="scheduled-datetime">
          {this.state.scheduledTime}
        </p>
      </div>);
    return (
      <div className="scheduled-screen-wrapper">
        <div className="scheduled-title" style={{color: this.props.style && this.props.style.titleColor}}>
          <Trans>SCHEDULED</Trans>{" "}
          <span className="underlined-eva" style={{ borderBottom: this.props.style ? "4px solid " + this.props.style.primaryColor : "" }}>
            <Trans>RIDE</Trans>
          </span>
        </div>
        <div style={{ color: this.props.style && this.props.style.textColor}}>
        {scheduledTimeDisplay}
        <div className="scheduled-body" id="tracking-id">
          <Trans>ID</Trans>: {this.props.trackingId}
        </div>
        <div className="scheduled-contact">
          <Trans>JOIN_US</Trans>
          <a href="contactus@goeva.com" className="eva-link">
            contactus@goeva.com
          </a>
        </div>
        </div>
        <RoundButton
          large
          icon="fa fa-phone"
          action={this.callDriver}
          style={{ width: "70px", height: "70px", paddingTop: "24px", backgroundColor: this.props.style && this.props.style.primaryColor }}
        />
        <div style={{ display: this.props.isBusiness ? "none" : "" }}>
          <AdsBar />
        </div>
      </div>
    );
  }
}

export default ScheduledScreen;

