import * as actionType from "../actions/action_types";

const initialState = {
  last_mia_error_reported_at: null
};

const SpamReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REPORT_MIA_ERROR_AT:
      return Object.assign({}, state, {
        last_mia_error_reported_at: action.payload.time,
      });
    default:
      return state;
  }
};

export default SpamReducer;
