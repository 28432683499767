import React, { Component } from "react";
import i18n from "../../i18n";
import TermsAndConditionsEn from "../../assets/files/terms_and_conditions_en.pdf";
import TermsAndConditionsFr from "../../assets/files/terms_and_conditions_fr.pdf";

class TermsAndConditions extends Component {
  render() {
    let pdfFile = TermsAndConditionsEn;
    if (i18n.language.startsWith('fr')) {
      pdfFile = TermsAndConditionsFr;
    }

    return (
      <a
        href={pdfFile}
        target="_blank"
        {...this.props}
      >
        {i18n.t("TERMS_AND_CONDITIONS")}
      </a>
    )
  }
}

export default TermsAndConditions;