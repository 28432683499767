export const UPDATE_ISAUTHENTICATED = "UPDATE_ISAUTHENTICATED";
export const LOGOUT = "LOGOUT";
export const ON_LOG_IN_SUCCESS = "ON_LOG_IN_SUCCESS";
export const ON_UPDATE_SELECT_COMMUNITY = "ON_UPDATE_SELECT_COMMUNITY";
export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const UPDATE_RIDES = 'UPDATE_RIDES';
export const REPORT_MIA_ERROR_AT = "REPORT_MIA_ERROR_AT";
export const UPDATE_EXCHANGE_RATES = "UPDATE_EXCHANGE_RATES";
export const UPDATE_PARTNERS = "UPDATE_PARTNERS";
export const IMPERSONATE_PARTNER = "IMPERSONATE_PARTNER";
