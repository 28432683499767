import React, { Component } from "react";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

class Footer extends Component {
  render() {
    return (
      <footer 
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "")
        }
      >
          <p>
            <TermsAndConditions 
              style={{ marginRight: 10 }}/>| 
            <span style={{ marginLeft: 10 }}>
              &copy; {1900 + new Date().getYear()}{" "}
              <a className="link" href="https://eva.coop/" target="_blank" rel="noopener noreferrer">Eva Coop</a>
            </span>
          </p>
        
      </footer>
    );
  }
}
export default Footer;
