import "./Drawer.css";

import React, { Component } from "react";

import HelpScreen from "./HelpScreen/HelpScreen";
import RideScreen from "./RideScreen/RideScreen";
import ScheduledScreen from "./ScheduledScreen/ScheduledScreen";
import { Trans } from "react-i18next";
import { RideRequestStatus } from "../../../const/RideRequestStatus";

class Drawer extends Component {
  state = {
    helpScreen: false,
  };

  showHideHelp = () => {
    this.setState({ helpScreen: !this.state.helpScreen });
  };

  render() {
    const isScheduledForLater = (this.props.requestStatus === RideRequestStatus.SCHEDULED_FOR_LATER);
    return (
      <div className="tracking-link-drawer-container">
        {(!this.props.isRideCancelled || this.props.isMiaRide) &&
          <button type="button"
            className="drawer-help-btn"
            onClick={() => this.showHideHelp()}
            style={{ display: this.state.helpScreen ? "none" : "", color: this.props.style && this.props.style.textColor }}
          >
            <span style={{color: this.props.style && this.props.style.primaryColor}}><Trans>HELP</Trans></span>
            <div
                  className="live-tracker-help-screen-back-arrow-title"
                  style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
                />
          </button>}
        {this.state.helpScreen && (!this.props.isRideCancelled || this.props.isMiaRide) ? (
          <HelpScreen {...this.props} goBackFunc={this.showHideHelp} />
        ) : isScheduledForLater ? (
          <ScheduledScreen {...this.props} />
        ) : (
          <RideScreen {...this.props} />
        )}
      </div>
    );
  }
}

export default Drawer;

