import "./TextInput.css";

import React, { Component } from "react";

class TextInput extends Component {
  render() {
    return this.props.textArea ? (
      <textarea
        value={this.props.value}
        style={{ ...this.props.style }}
        placeholder={this.props.placeholder}
        type="text"
        class="live-tracker-text-input-textarea"
        onChange={this.props.onChange}
      />
    ) : (
      <input
        value={this.props.value}
        style={{ ...this.props.style }}
        placeholder={this.props.placeholder}
        type="text"
        class="live-tracker-text-input"
        onChange={this.props.onChange}
      />
    );
  }
}

export default TextInput;

