import "./HelpScreen.css";

import * as actionTypes from "../../../../actions/action_types";

import React, { Component } from "react";
import { cancelRide, reportIssue } from "../../../../helpers/communityApiHelper";
import {
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
} from "../../../../helpers/alertHelper";

import EvaButton from "../../CustomComponents/Button/EvaButton";
import MenuOption from "../../CustomComponents/MenuOption/MenuOption";
import { Redirect } from "react-router-dom";
import SendMessageForm from "../../CustomComponents/SendMessageForm/SendMessageForm";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { formatPhoneNumber } from "../../../../helpers/viewsHelper";
import { store } from "../../../../store/index";
import { getCommunity } from "../../../../helpers/blockchainHelper";

const RIDE_SHARING = 0;
const DELIVERY = 1;
const ANTI_SPAM_INTERVAL_SECONDS = 180; //3 mins

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});


class HelpScreen extends Component {
  state = {
    alert: null,
    view: "default",
    showMessageSent: false,
    showMessageError: false,
    menuItemsClientDelivery: [
      { label: <Trans>ORDER_LATE</Trans>, action: () => this.showView("order-late") },
      { label: <Trans>ORDER_DAMAGED</Trans>, action: () => this.showView("order-damaged") },
      {
        label: <Trans>ORDER_NOT_DELIVERED</Trans>,
        action: () => this.showView("order-never-arrived"),
      },
    ],
    menuItemsClientRideSharing: [
      { label: <Trans>DRIVER_HASNT_ARRIVED</Trans>, action: () => this.showView("driver-late") },
      { label: <Trans>DRIVER_ISSUES</Trans>, action: () => this.showView("report-driver") },
    ],
    menuItemsBusiness: [
      { label: <Trans>CHANGE_DESTINATION</Trans>, comingSoon: true },
      { label: <Trans>REQUEST_REFUND</Trans>, comingSoon: true },
      { label: <Trans>CANCEL_RIDE</Trans>, action: () => this.showView("cancel-ride") },
    ],
  };

  firstUpdate = true;

  async componentDidUpdate(prevProps) {
    if (this.firstUpdate === true || prevProps.last_mia_error_reported_at !== this.props.last_mia_error_reported_at) {
      this.firstUpdate = false;
      const date = new Date(this.props.last_mia_error_reported_at);
      this.setState({showMessageSent: (new Date() - date) / 1000 < ANTI_SPAM_INTERVAL_SECONDS});

      const communities = {
        "Montreal": "evacaqc1",
        "Quebec": "evacaqc2",
        "Saguenay": "evacaqc3",
        "Mauricie": "evacaqc4",
        "Gatineau": "evacaqc5",
        "Sherbrooke": "evacaqc11",
        "Granby": "evacaqc12"
      }
      const commInfo = JSON.parse((await getCommunity(communities[storeState.userData.selectedCommunityName])).cie_info);
      this.setState({communityInfo: commInfo})
    }
  }

  showView = (view) => {
    this.setState({ view });
  };

  showMessageSent = () => {
    store.dispatch({type: actionTypes.REPORT_MIA_ERROR_AT, payload: {time: new Date()}})
  };

  showMessageError = () => {
    this.setState({ showMessageError: true });
    const that = this;
    setTimeout(() => {
      that.setState({ showMessageError: false });
    }, 4000);
  };

  reportIssue = (code, message) => {
    if (this.props.showDemo) return;
    reportIssue(
      this.props.trackingId,
      null,
      null,
      null,
      code,
      message,
      this.props.isBusiness,
      this.props.isMiaRide
    )
      .then(() => this.showMessageSent())
      .catch(() => this.showMessageError());
  };

  cancelRide = async () => {
    if (this.props.showDemo) return;
    this.loadingHandlerAlert();
    const response = await cancelRide(this.props.trackingId);
    if (response.success) {
      this.successHandlerAlert(<Trans>CANCEL_SUCCESS</Trans>);
    } else {
      this.errorHandlerAlert(<Trans>ERROR</Trans>, <Trans>CANCEL_ERROR</Trans>);
    }
  };

  successHandlerAlert = (message) => {
    this.setState({
      alert: getSuccessAlert(
        () => this.setState({ rideCancelled: true }),
        <Trans>SUCCESS</Trans>,
        message
      ),
    });
  };

  errorHandlerAlert = (error, msg) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(), error, msg),
    });
  };

  loadingHandlerAlert = () => {
    this.setState({
      alert: getLoadingAlert(),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  getHelpOptionView = (title, body, key, message) => {
    return (
      <div className="help-screen-container"  style={{ color: this.props.style && this.props.style.textColor, backgroundColor: this.props.style && this.props.style.backgroundColor || "white"}}>
        <table className="live-tracker-help-screen-table">
          <tr
            onClick={() => this.showView("default")}
            style={{ cursor: "pointer" }}
            className="help-screen-smaller-margin-mobile"
          >
             <div
                  className="live-tracker-help-screen-back-arrow"
                  style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
                />
            <div className="live-tracker-help-screen-title" style={(this.props.style && this.props.style.primaryColor) ? {color: this.props.style.primaryColor} : {}}>{title}</div>
          </tr>
          {this.state.showMessageSent ? (
            <div className="live-tracker-message-sent">
              <Trans>MESSAGE_SENT</Trans>
              <br />
              <span style={{ color: this.props.style ? this.props.style.primaryColor : "var(--eva-yellow)" }}><Trans>THANK_YOU</Trans></span>
            </div>
          ) : this.state.showMessageError ? (
            <div className="live-tracker-message-sent">
              <Trans>MESSAGE_ERROR</Trans>
              <br />
              <span><Trans>TRY_AGAIN_LATER_2</Trans></span>
            </div>
          ) : (
            <div>
              <div className="live-tracker-option-paragraph">
                {body}<br />
                <br />
                <Trans>CLICK_FOR_CONTACT</Trans>
              </div>
              <EvaButton
                label={<Trans>ALERT_COOP</Trans>}
                action={() =>
                  this.reportIssue(key, message)
                }
                style={{backgroundColor: this.props.style && this.props.style.primaryColor, color: this.props.style && this.props.style.secondaryColor}}
              />
            </div>
          )}
        </table>
      </div>
    );
  }

  render() {
    const clientMenuItems = this.props.rideServiceTypeId === RIDE_SHARING ? this.state.menuItemsClientRideSharing : this.state.menuItemsClientDelivery;
    let view = null;
    switch (this.state.view) {
      case "default":
        view = (
          <div className="help-screen-container" style={{backgroundColor: this.props.style && this.props.style.backgroundColor || "white"}}>
            <table className="live-tracker-help-screen-table">
              <tbody>                  
                <tr className="help-screen-smaller-margin-mobile" onClick={this.props.goBackFunc} style={{ cursor: "pointer" }}>
                  <td>                    
                    <div className="live-tracker-help-screen-back-arrow" style={{backgroundColor: this.props.style && this.props.style.primaryColor}} />
                    <div className="live-tracker-help-screen-title" style={(this.props.style && this.props.style.primaryColor) ? {color: this.props.style.primaryColor} : {}}><Trans>HELP</Trans></div>
                  </td>
                </tr>
                <tr>
                  <td>                        
                    {clientMenuItems.map((e, i) => {
                      return (
                        <MenuOption
                          key={'client_' + i}
                          label={e.label}
                          action={e.action}
                          comingSoon={e.comingSoon || false}
                          style={this.props.style}
                        />
                      );
                    })}
                    {this.props.isBusiness && !this.props.isPublic
                      ? this.state.menuItemsBusiness.map((e, i) => {
                          return (
                            <MenuOption
                              key={'business_' + i}
                              label={e.label}
                              action={e.action}
                              comingSoon={e.comingSoon || false}
                              style={this.props.style}
                            />
                          );
                        })
                      : null}
                    <MenuOption
                      label={<Trans>OTHER</Trans>}
                      action={() => this.showView("other")}
                      style={this.props.style}
                    />
                  </td>
                </tr>
                <tr>
                  {/* <div className="live-tracker-help-screen-contact">
                    <Trans>SPEAK_TO_REPRESENTATIVE</Trans>{" "}
                    {this.state.communityInfo && <a
                      style={{ color: this.props.style && this.props.style.textColor }}
                      href={(this.props.showDemo) ? "" : "tel:" + this.state.communityInfo.en.phone}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {formatPhoneNumber(this.state.communityInfo.en.phone)}
                      </span>
                    </a>}
                  </div> */}
                </tr>
              </tbody>
            </table>
          </div>
        );
        break;
      case "other":
        view = (
          <div className="help-screen-container" style={{backgroundColor: this.props.style && this.props.style.backgroundColor || "white"}}>
            <table className="live-tracker-help-screen-table">
              <tr
                onClick={() => this.showView("default")}
                style={{ cursor: "pointer" }}
                className="help-screen-smaller-margin-mobile"
              >
                <div
                  className="live-tracker-help-screen-back-arrow"
                  style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
                />
                <div className="live-tracker-help-screen-title" style={(this.props.style && this.props.style.primaryColor) ? {color: this.props.style.primaryColor} : {}}><Trans>OTHER</Trans></div>
              </tr>
              {this.state.showMessageSent ? (
                <div className="live-tracker-message-sent">
                  <Trans>MESSAGE_SENT</Trans>
                  <br />
                  <span style={{ color: this.props.style ? this.props.style.primaryColor : "var(--eva-yellow)" }}><Trans>THANK_YOU</Trans></span>
                </div>
              ) : this.state.showMessageError ? (
                <div className="live-tracker-message-sent">
                  <Trans>MESSAGE_ERROR</Trans>
                  <br />
                  <span><Trans>TRY_AGAIN_LATER_2</Trans></span>
                </div>
              ) : (
                <SendMessageForm
                  {...this.props}
                  submitFormSuccess={() => this.showMessageSent()}
                  submitFormError={() => this.showMessageError()}
                />
              )}
            </table>
          </div>
        );
        break;
        case "cancel-ride":
        view = (
          <div className="help-screen-container" style={{backgroundColor: this.props.style && this.props.style.backgroundColor || "white"}}>
            <table className="live-tracker-help-screen-table">
              <tr
                onClick={() => this.showView("default")}
                style={{ cursor: "pointer" }}
                className="help-screen-smaller-margin-mobile"
              >
                 <div
                  className="live-tracker-help-screen-back-arrow"
                  style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
                />
                <div className="live-tracker-help-screen-title" style={(this.props.style && this.props.style.primaryColor) ? {color: this.props.style.primaryColor} : {}}><Trans>CANCEL_RIDE</Trans></div>
              </tr>
              {this.state.showMessageSent ? (
                <div className="live-tracker-message-sent">
                  <Trans>MESSAGE_SENT</Trans>
                  <br />
                  <span style={{ color: this.props.style ? this.props.style.primaryColor : "var(--eva-yellow)" }}><Trans>THANK_YOU</Trans></span>
                </div>
              ) : (
                <div>
                  <div className="live-tracker-option-paragraph">
                    <b>
                      <Trans>ARE_YOU</Trans>{" "}
                      <span><Trans>SURE</Trans></span>{" "}
                      <Trans>WANT_TO_CANCEL</Trans>
                    </b>
                  </div>
                  <EvaButton
                    label={<Trans>CANCEL_RIDE</Trans>}
                    action={() => this.cancelRide()}
                    style={{backgroundColor: this.props.style && this.props.style.primaryColor, color: this.props.style && this.props.style.secondaryColor}}
                  />
                </div>
              )}
            </table>
          </div>
        );
        break;
      case "order-late":
        view = this.getHelpOptionView(<Trans>ORDER_LATE</Trans>, <Trans>ORDER_LATE_MSG</Trans>, "LATE", "Order is late");
        break;
      case "order-damaged":
        view = this.getHelpOptionView(<Trans>ORDER_DAMAGED</Trans>, <Trans>ORDER_DAMAGED_MSG</Trans>, "DAMAGED", "Order is damaged");
        break;
      case "order-never-arrived":
        view = this.getHelpOptionView(<Trans>ORDER_NOT_DELIVERED</Trans>, <Trans>ORDER_NOT_DELIVERED_MSG</Trans>, "NOT_DELIVERED", "Order was not delivered");
        break;
        case "driver-late":
          view = this.getHelpOptionView(<Trans>DRIVER_HASNT_ARRIVED</Trans>, <Trans>DRIVER_HASNT_ARRIVED_MSG</Trans>, "NO_DRIVER", "Driver is late");
          break;
          case "report-driver":
            view = this.getHelpOptionView(<Trans>DRIVER_ISSUES</Trans>, <Trans>DRIVER_ISSUES_MSG</Trans>, "DRIVER_ISSUE", "The driver has been reported.");
            break;
        default:
          break;
    }
    return (
      <div style={{color: this.props.style && this.props.style.textColor}}>
        {this.state.rideCancelled ? (
          <Redirect to={{ pathname: "/home" }} />
        ) : null}
        {this.state.alert}
        {view}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    last_mia_error_reported_at: state.spam.last_mia_error_reported_at
  };
};

export default connect(mapStateToProps)(HelpScreen);

