import * as actionType from "../actions/action_types";

const initialState = {
  rides: []
};

const RidesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_RIDES:
      return Object.assign({}, state, {
        rides: action.payload,
      });
    default:
      return state;
  }
};

export default RidesReducer;
