import React from "react";
import i18n from "../../../../i18n";
import "./FileUpload.css";

/**
 * Thanks to Codemzy for providing a nice starting point for this component.
 * https://www.codemzy.com/blog/react-drag-drop-file-upload
 */

const MAX_FILE_SIZE = 1024 * 1024 * 5; //5MB

function FileUpload(props) {
  const [dragActive, setDragActive] = React.useState(false);
  const [filename, setFilename] = React.useState(null);
  const inputRef = React.useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files[0].size > MAX_FILE_SIZE) {
        alert(i18n.t("FILE_TOO_BIG"));
        return;
      }
      props.handleFileUpload(e.dataTransfer.files[0]);
      setFilename(e.dataTransfer.files[0].name);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > MAX_FILE_SIZE) {
        alert(i18n.t("FILE_TOO_BIG"));
        return;
      }
      props.handleFileUpload(e.target.files[0]);
      setFilename(e.target.files[0].name);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="mia-form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="mia-input-file-upload"
        multiple={true}
        onChange={handleChange}
        accept=".csv"
      />
      <label
        id="mia-label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "mia-drag-active" : ""}
      >
        <div>
          <p>{i18n.t("DRAG_DROP_FILE")}</p>
          <button className="mia-upload-button" onClick={onButtonClick}>
            {i18n.t("CLICK_TO_UPLOAD_FILE")}
          </button>
          <p style={{ fontSize: "1.1rem", marginTop: "5%" }}>
            {" "}
            {i18n.t("FILE_UPLOAD_DISCLAIMER", {
              sizeInMB: "5",
              acceptedFormats: "csv",
            })}
          </p>
          {filename ? (
            <p style={{ fontSize: "1.1rem", marginTop: "5%" }}>
              {i18n.t("SELECTED_FILE") + " " + filename}
            </p>
          ) : null}
        </div>
      </label>
      {dragActive && (
        <div
          id="mia-drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default FileUpload;
