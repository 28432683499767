import React from "react";
import { connect } from "react-redux";
import { store } from "../store/index";
import { updatePartners } from "../actions/index";
import { getPartners } from "../helpers/apiHelper";

class FetchPartnersStrategy extends React.Component {
 
  constructor(props) {
    super(props);
    this.taskStarted = false;
  }

  componentDidMount(){
    this.execute();
  }

  componentDidUpdate() {
    this.execute();
  }

  execute = async () => {
    if (!this.taskStarted){
      this.taskStarted=true;
      
      getPartners()
        .then((data) => {
          store.dispatch(updatePartners(data.data));
          this.taskStarted = false;
        })
        .catch(() => {
          store.dispatch(updatePartners([]));
          this.taskStarted = false;
        });
    }
  }

  render () {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps)(FetchPartnersStrategy);
