import {
  OSRMDirectionsApi,
  mapboxDirectionsApi,
  mapboxToken,
} from "../variables/secretEnvVariables";

/**
 * A generic method to make an XMLHTTP request as a Promise
 * Headers and params are optional
 * @param {Request Options} opts
 */
function makeRequest(opts) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method, opts.url);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    if (opts.headers) {
      Object.keys(opts.headers).forEach(function (key) {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }
    var params = opts.params;
    if (params && typeof params === "object") {
      params = Object.keys(params)
        .map(function (key) {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&");
    }
    xhr.send(params);
  });
}

export async function getMapBoxDirections(points) {
  let url = mapboxDirectionsApi;
  points.forEach((point) => {
    url += `${point[0]},${point[1]};`;
  });
  url = url.slice(0, -1);
  url += `?geometries=geojson&waypoints=0;${
    points.length - 1
    }&access_token=${mapboxToken}`;

  return await makeRequest({
    method: "GET",
    url: url,
  }).then(function (response_string) {
    const response = JSON.parse(response_string);
    let data = response.routes[0]
      ? response.routes[0]
      : {
        geometry: {
          coordinates: [],
        },
      };
    let route = data.geometry.coordinates;
    let eta = parseInt(response.routes[0].legs[0].duration / 60);
    let directions = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route,
      },
    };
    return { directions, eta };
  });
}

export async function getOSRMDirections(points) {
  let url = OSRMDirectionsApi;
  points.forEach((point) => {
    if (typeof point[0] === 'undefined' 
          || typeof point[1] === 'undefined'
          || point[0] === null 
          || point[1] === null) {
            return;
          }
    url += `${point[0]},${point[1]};`;
  });
  url = url.slice(0, -1);
  url += `?geometries=geojson&waypoints=0;${
    points.length - 1
    }`;

  return await makeRequest({
    method: "GET",
    url: url,
  }).then(function (response_string) {
    const response = JSON.parse(response_string);
    let data = response.routes[0]
      ? response.routes[0]
      : {
        geometry: {
          coordinates: [],
        },
      };
    let route = data.geometry.coordinates;
    let timeEstimation = parseInt(response.routes[0].legs[0].duration / 60);
    let directions = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route,
      },
    };
    return { directions, timeEstimation };
  });
}