export function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export function isDay() {
    const date = new Date();
    return date.getHours() > 6 && date.getHours() < 19;
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  export function isUndefined(v) {
    return typeof v === 'undefined';
  }