import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import i18n from "i18next";
import { Trans } from "react-i18next";

export function getLoadingAlert() {
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={<Trans>SENDING</Trans>}
      closeOnClickOutside={false}
      allowEscape={false}
      showCancel={false}
      showConfirm={false}
    >
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <p>
        <Trans>SENDING_REQUEST</Trans>
      </p>
    </SweetAlert>
  );
}

export function getConfirmationAlert(onConfirm, onCancel, title, body) {
  return (
    <SweetAlert
      warning
      style={{
        display: "block",
        marginTop: "-100px",
      }}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnBsStyle="warning"
      cancelBtnBsStyle="danger"
      confirmBtnText={i18n.t("CONFIRM")}
      cancelBtnText={i18n.t("CANCEL")}
      showCancel
      showLoaderOnConfirm
    >
      {body}
    </SweetAlert>
  );
}

export function getSuccessAlert(onConfirm, title, body) {
  return (
    <SweetAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onConfirm={onConfirm}
      confirmBtnBsStyle="info"
      showCancel={false}
    >
      <p>{body}</p>
    </SweetAlert>
  );
}

export function getErrorAlert(onConfirm, error, message) {
  return (
    <SweetAlert
      style={{ display: "block" }}
      danger
      title={error || i18n.t("ERROR")}
      onConfirm={onConfirm}
      confirmBtnBsStyle="info"
    >
      <p>{message || <Trans>ERROR</Trans>}</p>
    </SweetAlert>
  );
}

