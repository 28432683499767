import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { ColorPicker, useColor } from "react-color-palette";
import { Redirect } from "react-router-dom";
import LiveTracker from "../LiveTracker/LiveTracker";
import ColorWheelPng from "../../assets/img/color-wheel.png";
import FileUploaderB64 from "./components/FileUploaderB64";
import {
  saveTrackingStyle,
  getTrackingStyles,
  getRiderAccount,
} from "../../helpers/communityApiHelper";
import TabSelector from "./components/TabSelector";
import { RideRequestStatus } from "../../const/RideRequestStatus";
import SharePng from "../../assets/img/share.png";
import { NEW, DAY, NIGHT } from "./Constants";
import {
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
  getConfirmationAlert,
} from "../../helpers/alertHelper";
import "react-color-palette/lib/css/styles.css";
import "./WhiteLabel.css";

function WhiteLabelForm() {
  const [colorPickerOpen, setColorPickerOpen] = useState({
    field: null,
    open: false,
  });
  const [color, setColor] = useColor("rgb", "rgb(0,0,0)");
  const [redirect, setRedirect] = useState(false);
  const [view, setView] = useState(RideRequestStatus.LIVE);
  const [forceMapUpdate, setForceMapUpdate] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState(DAY);
  const { white_label_id } = useParams();
  const [_alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  //Mechanism to bypass DeckGL icon rendering issues.
  useEffect(() => {
    if (forceMapUpdate === false) return;
    setForceMapUpdate(false);
  }, [forceMapUpdate]);

  //Form values
  const [alias, setAlias] = useState(null);
  const [aliasRequiredText, setAliasRequiredText] = useState(null);
  const [description, setDescription] = useState(null);
  const [descriptionRequiredText, setDescriptionRequiredText] = useState(null);
  const [primaryColor, setPrimaryColor] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [titleColor, setTitleColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [headerColor, setHeaderColor] = useState(null);
  const [lineToPickupColor, setLineToPickupColor] = useState(null);
  const [lineToDestinationColor, setLineToDestinationColor] = useState(null);
  const [logoRedirect, setLogoRedirect] = useState(null);
  const [logoIcon, setLogoIcon] = useState({ base64: null, fileName: null });
  const [carIcon, setCarIcon] = useState({ base64: null, fileName: null });
  const [carIconWidth, setCarIconWidth] = useState(47);
  const [carIconHeight, setCarIconHeight] = useState(128);
  const [pickupIcon, setPickupIcon] = useState({
    base64: null,
    fileName: null,
  });
  const [pickupIconWidth, setPickupIconWidth] = useState(128);
  const [pickupIconHeight, setPickupIconHeight] = useState(128);
  const [destinationIcon, setDestinationIcon] = useState({
    base64: null,
    fileName: null,
  });
  const [destinationIconWidth, setDestinationIconWidth] = useState(128);
  const [destinationIconHeight, setDestinationIconHeight] = useState(128);

  const [carIconNight, setCarIconNight] = useState({
    base64: null,
    fileName: null,
  });
  const [pickupIconNight, setPickupIconNight] = useState({
    base64: null,
    fileName: null,
  });
  const [destinationIconNight, setDestinationIconNight] = useState({
    base64: null,
    fileName: null,
  });

  useEffect(() => {
    if (white_label_id !== NEW) {
      getTrackingStyles(white_label_id).then((styles) => {
        if (!styles.length) {
          setAlert(
            getErrorAlert(
              () => setRedirect(true),
              i18n.t("WHITE_LABEL_NO_STLYE_FOUND_TITLE"),
              i18n.t("WHITE_LABEL_NO_STLYE_FOUND_BODY")
            )
          );
          return;
        }
        const style = styles[0];
        setAlias(style.alias);
        setDescription(style.description);
        setPrimaryColor(style.primary_color);
        setSecondaryColor(style.secondary_color);
        setTextColor(style.text_color);
        setTitleColor(style.title_color);
        setHeaderColor(style.header_color);
        setBackgroundColor(style.background_color);
        setLineToPickupColor(style.line_to_pickup_color);
        setLineToDestinationColor(style.line_to_destination_color);
        setLogoRedirect(style.logo_redirect);
        setLogoIcon({
          base64: style.company_logo_b64,
          fileName: style.company_logo_b64 ? "Icon.png" : null,
        });
        setCarIcon({
          base64: style.car_icon_b64,
          fileName: style.car_icon_b64 ? "Icon.png" : null,
        });
        setCarIconWidth(style.car_icon_width || 47);
        setCarIconHeight(style.car_icon_height || 128);
        setPickupIcon({
          base64: style.pickup_icon_b64,
          fileName: style.pickup_icon_b64 ? "Icon.png" : null,
        });
        setPickupIconWidth(style.pickup_icon_width || 128);
        setPickupIconHeight(style.pickup_icon_height || 128);
        setDestinationIcon({
          base64: style.destination_icon_b64,
          fileName: style.destination_icon_b64 ? "Icon.png" : null,
        });
        setDestinationIconWidth(style.destination_icon_width || 128);
        setDestinationIconHeight(style.destination_icon_height || 128);
        setCarIconNight({
          base64: style.car_icon_night_b64,
          fileName: style.car_icon_night_b64 ? "Icon.png" : null,
        });
        setPickupIconNight({
          base64: style.pickup_icon_night_b64,
          fileName: style.pickup_icon_night_b64 ? "Icon.png" : null,
        });
        setDestinationIconNight({
          base64: style.destination_icon_night_b64,
          fileName: style.destination_icon_night_b64 ? "Icon.png" : null,
        });
        setAliasRequiredText(!style.alias);
        setDescriptionRequiredText(!style.description);
      });
    }
  }, []);

  const resetForm = () => {
    setForceMapUpdate(true);
    setView(RideRequestStatus.LIVE);
    setTimeOfDay(DAY);
    setDescription("");
    setPrimaryColor("");
    setSecondaryColor("");
    setTextColor("");
    setTitleColor("");
    setHeaderColor("");
    setBackgroundColor("");
    setLineToPickupColor("");
    setLineToDestinationColor("");
    setLogoRedirect("");
    setLogoIcon({ base64: null, fileName: "" });
    setCarIcon({ base64: null, fileName: "" });
    setCarIconWidth(47);
    setCarIconHeight(128);
    setPickupIcon({ base64: null, fileName: "" });
    setPickupIconWidth(128);
    setPickupIconHeight(128);
    setDestinationIcon({ base64: null, fileName: "" });
    setDestinationIconWidth(128);
    setDestinationIconHeight(128);
    setCarIconNight({ base64: null, fileName: "" });
    setPickupIconNight({ base64: null, fileName: "" });
    setDestinationIconNight({ base64: null, fileName: "" });
    setDescriptionRequiredText(true);
    setAlias("");
    setAliasRequiredText(true);
  };

  const getTrackingStyleJson = () => {
    return {
      alias: alias,
      description: description,
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      text_color: textColor,
      title_color: titleColor,
      background_color: backgroundColor,
      header_color: headerColor,
      line_to_pickup_color: lineToPickupColor,
      line_to_destination_color: lineToDestinationColor,
      logo_redirect: logoRedirect,
      company_logo_b64: logoIcon.base64,
      car_icon_b64: carIcon.base64,
      pickup_icon_b64: pickupIcon.base64,
      destination_icon_b64: destinationIcon.base64,
      car_icon_night_b64: carIconNight.base64,
      pickup_icon_night_b64: pickupIconNight.base64,
      destination_icon_night_b64: destinationIconNight.base64,
      car_icon_width: parseFloat(carIconWidth),
      car_icon_height: parseFloat(carIconHeight),
      destination_icon_width: parseFloat(destinationIconWidth),
      destination_icon_height: parseFloat(destinationIconHeight),
      pickup_icon_width: parseFloat(pickupIconWidth),
      pickup_icon_height: parseFloat(pickupIconHeight),
      timeOfDay: timeOfDay,
      forceMapUpdate,
    };
  };

  const onColorChange = (color) => {
    setColor(color);
    const { r, g, b } = color.rgb;
    switch (colorPickerOpen.field) {
      case "PRIMARY_COLOR":
        setPrimaryColor(`${r}, ${g}, ${b}`);
        break;
      case "SECONDARY_COLOR":
        setSecondaryColor(`${r}, ${g}, ${b}`);
        break;
      case "TEXT_COLOR":
        setTextColor(`${r}, ${g}, ${b}`);
        break;
      case "TITLE_COLOR":
        setTitleColor(`${r}, ${g}, ${b}`);
        break;
      case "BACKGROUND_COLOR":
        setBackgroundColor(`${r}, ${g}, ${b}`);
        break;
      case "HEADER_COLOR":
        setHeaderColor(`${r}, ${g}, ${b}`);
        break;
      case "LINE_TO_PICKUP_COLOR":
        setLineToPickupColor(`${r}, ${g}, ${b}`);
        break;
      case "LINE_TO_DESTINATION_COLOR":
        setLineToDestinationColor(`${r}, ${g}, ${b}`);
        break;
      default:
        return;
    }
  };

  const handleReset = () => {
    setAlert(
      getConfirmationAlert(
        () => {
          resetForm();
          hideAlert();
        },
        () => hideAlert(),
        i18n.t("WHITE_LABEL_RESET_TITLE"),
        i18n.t("WHITE_LABEL_RESET_BODY")
      )
    );
  };

  const handleSave = async () => {
    setAlert(getLoadingAlert());
    if (aliasRequiredText !== false || descriptionRequiredText !== false) {
      setAlert(
        getErrorAlert(
          () => hideAlert(),
          i18n.t("WHITE_LABEL_SAVE_VALIDATION_TITLE"),
          i18n.t("WHITE_LABEL_SAVE_VALIDATION_BODY")
        )
      );
      return;
    }

    try {
      await saveTrackingStyle(
        white_label_id === NEW ? undefined : white_label_id,
        getTrackingStyleJson()
      );
      setAlert(
        getSuccessAlert(
          () => setRedirect(true),
          i18n.t("WHITE_LABEL_SAVE_SUCCESS_TITLE"),
          ""
        )
      );
    } catch (error) {
      setAlert(getErrorAlert(() => hideAlert(), i18n.t("ERROR"), error));
    }
  };

  return (
    <div>
      {redirect && <Redirect to="/white_label_list" />}
      {_alert}
      <div
        className="color-wheel-select-wrapper"
        style={colorPickerOpen.open ? {} : { display: "none" }}
      >
        <div className="color-wheel-select">
          <div
            className="color-wheel-select-close"
            onClick={() => setColorPickerOpen({ field: null, open: false })}
          >
            <i style={{ fontSize: "35px" }} className="pe-7s-close-circle " />
          </div>
          <ColorPicker
            width={456}
            height={228}
            color={color}
            onChange={onColorChange}
            hideHSV
            dark
          />
        </div>
      </div>
      <div className="white-label-form-container">
        <div className="white-label-form-wrapper">
          <h3 className="white-label-form-title">
            <Trans>INFORMATION</Trans>
          </h3>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>ALIAS</Trans>*:{" "}
            </div>
            <input
              type="text"
              id="alias"
              name="alias"
              placeholder={i18n.t("Default")}
              className="customEvaInput left"
              value={alias}
              onChange={(e) => {
                setAlias(e.target.value);
                setAliasRequiredText(!e.target.value);
              }}
              style={{
                border: aliasRequiredText === true ? "1px solid red" : "",
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>DESCRIPTION</Trans>*:{" "}
            </div>
            <input
              type="text"
              id="description"
              name="description"
              placeholder={i18n.t("My new style!")}
              className="customEvaInput left"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionRequiredText(!e.target.value);
              }}
              style={{
                border: descriptionRequiredText === true ? "1px solid red" : "",
              }}
            />
          </div>
          <h3 className="white-label-form-title">
            <Trans>OPTIONS</Trans>
          </h3>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>PRIMARY_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="primaryColor"
                name="primaryColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={primaryColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "PRIMARY_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>SECONDARY_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="secondaryColor"
                name="secondaryColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={secondaryColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "SECONDARY_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>TEXT_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="textColor"
                name="textColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={textColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "TEXT_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>TITLE_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="titleColor"
                name="titleColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={titleColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "TITLE_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>HEADER_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="headerColor"
                name="headerColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={headerColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "HEADER_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>BACKGROUND_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="backgroundColor"
                name="backgroundColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={backgroundColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({ field: "BACKGROUND_COLOR", open: true })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>LINE_1_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="lineToPickupColor"
                name="lineToPickupColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={lineToPickupColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({
                      field: "LINE_TO_PICKUP_COLOR",
                      open: true,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>LINE_2_COLOR</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="lineToDestinationColor"
                name="lineToDestinationColor"
                placeholder={i18n.t("Color")}
                className="customEvaInput color"
                readOnly
                value={lineToDestinationColor}
              />
              <div className="color-wheel-icon-wrapper">
                <img
                  src={ColorWheelPng}
                  alt="color_wheel"
                  height="25px"
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setColorPickerOpen({
                      field: "LINE_TO_DESTINATION_COLOR",
                      open: true,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>LOGO_REDIRECTION</Trans>:{" "}
            </div>
            <input
              type="text"
              id="logoRedirect"
              name="logoRedirect"
              placeholder={"https://goeva.com"}
              className="customEvaInput left"
              value={logoRedirect}
              onChange={(e) => setLogoRedirect(e.target.value)}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>LOGO_ICON</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="logoIcon"
                name="logoIcon"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={logoIcon.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64 onChange={setLogoIcon} />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>CAR_ICON</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="carIcon"
                name="carIcon"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={carIcon.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setCarIcon(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>CAR_ICON_NIGHT</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="carIconNight"
                name="carIconNight"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={carIconNight.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setCarIconNight(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>CAR_ICON_WIDTH</Trans>:{" "}
            </div>
            <input
              type="number"
              id="carIconWidth"
              name="carIconWidth"
              placeholder={47}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={carIconWidth}
              onChange={(e) => {
                setForceMapUpdate(true);
                setCarIconWidth(e.target.value);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>CAR_ICON_HEIGHT</Trans>:{" "}
            </div>
            <input
              type="number"
              id="carIconHeight"
              name="carIconHeight"
              placeholder={128}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={carIconHeight}
              onChange={(e) => {
                setForceMapUpdate(true);
                setCarIconHeight(e.target.value);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>PICKUP_ICON</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="pickupIcon"
                name="pickupIcon"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={pickupIcon.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setPickupIcon(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>PICKUP_ICON_NIGHT</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="pickupIconNight"
                name="pickupIconNight"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={pickupIconNight.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setPickupIconNight(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>PICKUP_ICON_WIDTH</Trans>:{" "}
            </div>
            <input
              type="number"
              id="pickupIconWidth"
              name="pickupIconWidth"
              placeholder={47}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={pickupIconWidth}
              onChange={(e) => {
                setForceMapUpdate(true);
                setPickupIconWidth(e.target.value);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>PICKUP_ICON_HEIGHT</Trans>:
            </div>
            <input
              type="number"
              id="pickupIconHeight"
              name="pickupIconHeight"
              placeholder={128}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={pickupIconHeight}
              onChange={(e) => {
                setForceMapUpdate(true);
                setPickupIconHeight(e.target.value);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>DESTINATION_ICON</Trans>:{" "}
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="destinationIcon"
                name="destinationIcon"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={destinationIcon.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setDestinationIcon(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>DESTINATION_ICON_NIGHT</Trans>:
            </div>
            <div className="customEvaInputColor">
              <input
                type="text"
                id="destinationIconNight"
                name="destinationIconNight"
                placeholder={i18n.t("Icon")}
                className="customEvaInput color"
                readOnly
                value={destinationIconNight.fileName}
              />
              <div className="color-wheel-icon-wrapper">
                <FileUploaderB64
                  onChange={(e) => {
                    setForceMapUpdate(true);
                    setDestinationIconNight(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>DESTINATION_ICON_WIDTH</Trans>:
            </div>
            <input
              type="number"
              id="destinationIconWidth"
              name="destinationIconWidth"
              placeholder={128}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={destinationIconWidth}
              onChange={(e) => {
                setForceMapUpdate(true);
                setDestinationIconWidth(e.target.value);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>DESTINATION_ICON_HEIGHT</Trans>:
            </div>
            <input
              type="number"
              id="destinationIconHeight"
              name="destinationIconHeight"
              placeholder={128}
              className="customEvaInput"
              style={{ width: "30%" }}
              value={destinationIconHeight}
              onChange={(e) => {
                setForceMapUpdate(true);
                setDestinationIconHeight(e.target.value);
              }}
            />
          </div>
          <h3 className="white-label-form-title">
            <Trans>PREVIEW</Trans>
          </h3>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>TIME_OF_DAY</Trans>:{" "}
            </div>
            <TabSelector
              tabs={[DAY, NIGHT]}
              value={timeOfDay}
              onChange={(e) => {
                setForceMapUpdate(true);
                setTimeOfDay(e);
              }}
            />
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>View</Trans>:{" "}
            </div>
            <select
              className="white-label-form-dropdown"
              value={view}
              onChange={(e) => {
                setView(e.target.value);
              }}
            >
              <option value={RideRequestStatus.LIVE}>{i18n.t("LIVE")}</option>
              <option value={RideRequestStatus.SCHEDULED_FOR_LATER}>
                {i18n.t("SCHEDULED_FOR_LATER")}
              </option>
              <option value={RideRequestStatus.CANCELLED_BY_RIDER}>
                {i18n.t("UNKNOWN_CANCELLATION")}
              </option>
              <option value={RideRequestStatus.SUCCESS}>
                {i18n.t("SUCCESS")}
              </option>
            </select>
          </div>
          <div className="white-label-form-input-row">
            <div className="customEvaInputLabel right">
              <Trans>GET_DEMO_LINK</Trans>:
            </div>
            <img
              src={SharePng}
              alt="color_wheel"
              height="25px"
              width="25px"
              style={{ cursor: "pointer" }}
              onClick={() => {
                alert(
                  window.location.href.replace(
                    "/white_label/",
                    `/public/demo/live_tracker?rider_account=${getRiderAccount()}&style_id=`
                  )
                );
              }}
            />
          </div>
          <div className="white-label-form-button-container">
            <div className="action-button-dark" onClick={() => handleReset()}>
              <div style={{ margin: "auto" }}>
                <Trans>RESET</Trans>
              </div>
            </div>
            <div className="action-button" onClick={() => handleSave()}>
              <div style={{ margin: "auto" }}>
                <Trans>SAVE</Trans>
              </div>
            </div>
          </div>
        </div>
        <div>
          <LiveTracker
            showDemo={{
              showPage: view,
              style: getTrackingStyleJson(),
              refreshId: Math.random(),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WhiteLabelForm;
