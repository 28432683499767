import "./EvaButton.css";

import React, { Component } from "react";

class EvaButton extends Component {
  render() {
    return (
      <div className="eva-y-button-wrapper">
        <div
          style={{ ...this.props.style }}
          className="eva-y-button"
          onClick={this.props.action}
        >
          {this.props.label}
        </div>
      </div>
    );
  }
}

export default EvaButton;

