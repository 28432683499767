import "./Separator.css";

import React, { Component } from "react";

class Separator extends Component {
  render() {
    return (
      <div className="separator-wrapper">
        <div className="separator" style={{backgroundColor: this.props.style && this.props.style.primaryColor}} />
      </div>
    );
  }
}

export default Separator;

