import React, { Component } from "react";

import LiveTracker from "./LiveTracker";
import QueryString from "query-string";

class LiveTrackerMia extends Component {
  state = {
    tracking_id: null,
    isCompleted: false,
  };

  componentDidMount = async () => {
    if (this.props.location.state) {
      const { isCompleted, tracking_id } = this.props.location.state;
      await this.setState({ isCompleted, tracking_id });
    } else {
      const tracking_id = QueryString.parse(this.props.location.search).tracking_id;
      await this.setState({ tracking_id });
    }
  };

  render() {
    if (this.state.tracking_id === null) return null;
    return (
      <LiveTracker
        {...this.props}
        public={false}
        tracking_id={this.state.tracking_id}
        isCompleted={this.state.isCompleted}
      />
    );
  }
}

export default LiveTrackerMia;

