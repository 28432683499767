import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import i18n from "../../i18n";
// dinamically create dashboard routes
import publicRoutes from "../../routes/public.jsx";

class Public extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: {
        Key: i18n.language,
        Label: i18n.language.toUpperCase(),
      },
    };
  }
  changeLanguage = () => {
    if (this.state.currentLanguage.Key.includes("en")) {
      i18n.changeLanguage(this.state.currentLanguage.Key);
      this.setState({ currentLanguage: { Key: "fr", Label: "FR" } });
    } else {
      i18n.changeLanguage(this.state.currentLanguage.Key);
      this.setState({ currentLanguage: { Key: "en", Label: "EN" } });
    }
  };

  componentDidMount() {
    this.changeLanguage();
  }

  render() {
    return (
      <div className="wrapper">
        <Switch>
          {publicRoutes.map((prop, key) => {
            if (prop.collapse) {
              return prop.views.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              });
            } else {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              else
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
            }
          })}
        </Switch>
        {/* <Footer fluid transparent /> */}
      </div>
    );
  }
}

export default Public;

