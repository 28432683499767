import styles from './OrderRideStyle.module.css';
import React, { Component } from 'react';

export default class AutoComplete extends Component {

    render() {
        let options = [];
        if (this.props.options){
            options = this.props.options;
        }
        return (
            <div>
                <div 
                    style={{ 
                        display: this.props.showOptions ? 'block' : 'none',
                        ...this.props.style }}
                        className={this.props.className || styles.autoComplete}>
                    {options.map((value) => {
                        return <div
                            onClick={(e) => {this.props.handleClick(e.target); this.setState({ showOptions: false})}}
                            className={styles.selectableList}
                            key={value[this.props.idKey]}
                            id={value[this.props.idKey]}
                            style={this.props.inputStyle}>
                            {value[this.props.valueKey]}
                        </div>
                    })}
                </div>
            </div>
        )

    }

}
