import "./SendMessageForm.css";

import React, { Component } from "react";

import EvaButton from "../Button/EvaButton";
import TextInput from "../TextInput/TextInput";
import i18n from "i18next";
import { reportIssue } from "../../../../helpers/communityApiHelper";

class SendMessageForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    message: "",
  };

  reportIssue = () => {
    if (this.props.showDemo) return;
    console.log(this.state);
    if (this.props.isBusiness) {
      reportIssue(
        this.props.trackingId,
        this.state.businessName,
        this.state.email,
        this.state.phone,
        "OTHER",
        this.state.message,
        this.props.isBusiness,
        this.props.isMiaRide
      )
        .then(() => this.props.submitFormSuccess())
        .catch(() => this.props.submitFormError());
    } else {
      reportIssue(
        this.props.trackingId,
        this.state.firstName + " " + this.state.lastName,
        this.state.email,
        this.state.phone,
        "OTHER",
        this.state.message,
        this.props.isBusiness,
        this.props.isMiaRide
      )
        .then(() => this.props.submitFormSuccess())
        .catch(() => this.props.submitFormError());
    }
  };

  onFirstNameChange = (text) => {
    this.setState({ firstName: text });
  };

  onLastNameChange = (text) => {
    this.setState({ lastName: text });
  };

  onEmailChange = (text) => {
    this.setState({ email: text });
  };

  onPhoneChange = (text) => {
    this.setState({ phone: text });
  };

  onBusinessNameChange = (text) => {
    this.setState({ businessName: text });
  };

  onMessageChange = (text) => {
    this.setState({ message: text });
  };

  render() {
    return (
      <div class="tracking-link-message-form-container">
        <table class="tracking-link-message-form-table">
          {this.props.isBusiness ? (
            <tr>
              <td>
                <TextInput
                  onChange={(e) => this.onBusinessNameChange(e.target.value)}
                  value={this.state.businessName}
                  placeholder={i18n.t("BUSINESS_NAME")}
                />
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                <TextInput
                  onChange={(e) => this.onLastNameChange(e.target.value)}
                  value={this.state.lastName}
                  placeholder={i18n.t("LAST_NAME")}
                />
              </td>
              <td>
                <TextInput
                  onChange={(e) => this.onFirstNameChange(e.target.value)}
                  value={this.state.firstName}
                  placeholder={i18n.t("FIRST_NAME")}
                />
              </td>
            </tr>
          )}
          <tr>
            <td>
              <TextInput
                onChange={(e) => this.onEmailChange(e.target.value)}
                value={this.state.email}
                placeholder={i18n.t("EMAIL")}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextInput
                onChange={(e) => this.onPhoneChange(e.target.value)}
                value={this.state.phone}
                placeholder={i18n.t("TELEPHONE")}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextInput
                onChange={(e) => this.onMessageChange(e.target.value)}
                value={this.state.message}
                placeholder={i18n.t("MESSAGE_BODY")}
                style={{ height: "200px" }}
                textArea
              />
            </td>
          </tr>
          <tr>
            <td>
              <EvaButton
                action={() => this.reportIssue()}
                label={i18n.t("SEND")}
                style={{ color: "white", fontSize: "1em", backgroundColor: this.props.style && this.props.style.primaryColor, color: this.props.style && this.props.style.secondaryColor}}
              />
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default SendMessageForm;

