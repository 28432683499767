import "./AddressBar.css";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import AnimatedPlusButton from "../../../../../components/AnimatedPlusButton/AnimatedPlusButton";

class AddressBar extends Component {
  state = {
    expanded: false,
  };

  componentDidMount() {
    const expanded = this.props.expanded || false;
    this.setState({ expanded });
  }

  expandSection = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const style = this.props.style;
    return (
      <section className="tracking-link-addresses-container">
        <div className="addresses-title">
          <h4 style={{color: this.props.style && this.props.style.titleColor}}><Trans>ITINERARY</Trans></h4>
          <AnimatedPlusButton
            color={style && style.primaryColor}
            stroke="3"
            width="17"
            onClick={this.expandSection}
          />
        </div>
        {this.state.expanded &&
          <div className="addresses-content">
            <div className="address-box" style={{ color: this.props.style && this.props.style.textColor}}>
              <span className="dot" style={{ backgroundColor: style && style.primaryColor }} />
              <p>{this.props.fromAddress}</p>
            </div>
            <div className="vertical-line-block" style={{ borderColor: style && style.primaryColor }} />
            <div className="address-box" style={{ color: this.props.style && this.props.style.textColor}}>
              <span className="dot" style={{ backgroundColor: style && style.primaryColor }} />
              <p>{this.props.toAddress}</p>
            </div>
          </div>
        }
      </section>
    );
  }
}

export default AddressBar;

