import React, { Component } from "react";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Trans } from "react-i18next";
import i18n from 'i18next';
import { InlineShareButtons, InlineFollowButtons } from "sharethis-reactjs";
import { isMobile } from "../../helpers/viewsHelper";
import { connect } from "react-redux";
import LiveTracker from "../LiveTracker/LiveTracker";

//Images
import phone_img from "../../assets/img/phone_img.png";
import footer_google from "../../assets/img/footer_google.png";
import footer_apple from "../../assets/img/footer_apple.png";
import { isEmptyObject } from "../util";

class Home extends Component {
  state = {
    tracking_id: null,
  };
  handleTrackClick = () => {
    this.props.history.push({
      pathname: "/live_tracker_list",
    });
  };

  componentDidMount() {
    let params = this.getParams();
    if (params.get("tracking_id")){
      this.setState({ tracking_id: params.get("tracking_id") });
    } else if (params.get('token')){
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: ''
      });
    }
  }


  getParams() {
    // get the query string from the URL
    const queryString = window.location.search;
    // parse the query string to get the params
    const params = new URLSearchParams(queryString);
    return params;
  }

  render() {
    // Refresh the URL if the URI token is set.
    
    const language = i18n.language.split('-')[0];
    const cieInfo = this.props.communityInfo && this.props.communityInfo[language];

    //MOBILE VARS
    let phoneImgFloat,
      phoneImgMarginRight,
      footerPicsWidth,
      phoneImgWidth,
      socialMediaButtonsSize;
    if (isMobile()) {
      phoneImgFloat = "none";
      phoneImgMarginRight = "20%";
      footerPicsWidth = "35%";
      phoneImgWidth = "30%";
      socialMediaButtonsSize = 25;
    } else {
      phoneImgFloat = "left";
      phoneImgMarginRight = "";
      footerPicsWidth = "15%";
      phoneImgWidth = "15%";
      socialMediaButtonsSize = 50;
    }

    if (this.state.tracking_id ==   null) {
        return (
          <div style={{ minHeight: "85vh", backgroundColor: "white" }}>
            <div
              style={{
                padding: "10%",
                backgroundColor: "black",
                textAlign: "center",
              }}
            >
              <h2 style={{ color: "white" }}>
                <b><Trans>HOME_HEADER_1</Trans></b><Trans>HOME_HEADER_2</Trans>
              </h2>
              <CustomButton
                className
                onClick={() => this.handleTrackClick()}
                fill
                wd
                style={{ padding: "1%" }}
              >
                <Trans>TRACK_YOUR_ORDER</Trans>
              </CustomButton>
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                alt="phone_img"
                src={phone_img}
                width={phoneImgWidth}
                style={{
                  marginRight: phoneImgMarginRight,
                  marginLeft: "20%",
                  marginTop: "15%",
                  marginBottom: "15%",
                  float: phoneImgFloat,
                }}
              />
            </div>
            <div
              style={{
                marginTop: "5%",
                padding: "10%",
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              <h2 style={{ color: "black" }}>
                <Trans>HOME_JOIN_COOP</Trans>
              </h2>
              <div style={{ paddingTop: "5%" }}>
                <a href="https://play.google.com/store/apps/details?id=com.goeva.reskin">
                  <img
                    alt="googleplay_img"
                    src={footer_google}
                    width={footerPicsWidth}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <a href="https://apps.apple.com/ca/app/eva-driver/id6483758712">
                  <img
                    alt="appstore_img"
                    src={footer_apple}
                    width={footerPicsWidth}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <br />
                <div style={{ marginTop: "10%", textAlign: "center" }}>
                  <InlineShareButtons
                    config={{
                      alignment: "center", // alignment of buttons (left, center, right)
                      color: "social", // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)
                      font_size: 16, // font size for the buttons
                      labels: "cta", // button labels (cta, counts, null)
                      language: "en", // which language to use (see LANGUAGES)
                      networks: ["messenger", "facebook", "twitter"],
                      padding: 12, // padding within buttons (INTEGER)
                      radius: 10, // the corner radius on each button (INTEGER)
                      show_total: false,
                      size: 40, // the size of each button (INTEGER)

                      // OPTIONAL PARAMETERS
                      url: "https://www.eva.coop", // (defaults to current url)
                    }}
                  />
                  <br />
                  <InlineShareButtons
                    config={{
                      alignment: "center", // alignment of buttons (left, center, right)
                      color: "social", // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)
                      font_size: 16, // font size for the buttons
                      labels: "cta", // button labels (cta, counts, null)
                      language: "en", // which language to use (see LANGUAGES)
                      networks: ["telegram", "linkedin", "reddit", "medium"],
                      padding: 12, // padding within buttons (INTEGER)
                      radius: 10, // the corner radius on each button (INTEGER)
                      show_total: false,
                      size: 40, // the size of each button (INTEGER)

                      // OPTIONAL PARAMETERS
                      url: "https://www.eva.coop", // (defaults to current url)
                    }}
                  />
                </div>
              </div>
            </div>
            <br /> <br />
            <div
              style={{
                padding: "4%",
                marginTop: "10%",
                textAlign: "center",
                backgroundColor: "rgb(258,193,22)",
              }}
            >
              <InlineFollowButtons
                config={{
                  action_enable: false, // show/hide call to action (true, false)
                  action_pos: "bottom", // position of call to action (left, top, right)
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  networks: [
                    // which networks to include (see FOLLOW NETWORKS)
                    "twitter",
                    "facebook",
                    "instagram",
                    "youtube",
                  ],
                  padding: 15, // padding within buttons (INTEGER)
                  profiles: {
                    // social profile links for buttons
                    twitter: "Eva_Coop",
                    facebook: "Eva.coop.OFFICIAL",
                    instagram: "eva.coop",
                    youtube: "/channel/UCWkWaWh4WiOWze_iwPDaHBQ/featured",
                  },
                  radius: 9, // the corner radius on each button (INTEGER)
                  size: socialMediaButtonsSize, // the size of each button (INTEGER)
                  spacing: 8, // the spacing between buttons (INTEGER)
                }}
              />
              <br />
              <InlineFollowButtons
                config={{
                  action_enable: false, // show/hide call to action (true, false)
                  action_pos: "bottom", // position of call to action (left, top, right)
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  networks: ["linkedin", "telegram", "github", "reddit", "medium"],
                  padding: 15, // padding within buttons (INTEGER)
                  profiles: {
                    linkedin: "/company/evacoop/",
                    telegram: "joinchat/HsnXxxMCpEAj0xcTmbaKQg",
                    github: "EvaCoop",
                    reddit: "/user/Eva_coop",
                    medium: "@eva.coop",
                  },
                  radius: 9, // the corner radius on each button (INTEGER)
                  size: socialMediaButtonsSize, // the size of each button (INTEGER)
                  spacing: 8, // the spacing between buttons (INTEGER)
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: "black",
                padding: "6%",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#BBB" }}>
                {cieInfo && cieInfo.phone}
                <br />
                <br />
                {cieInfo && cieInfo.email}
                <br />
                <br />
                {cieInfo && cieInfo.address}
                <br />
                <br />
                {cieInfo && cieInfo.city}, {cieInfo && cieInfo.state}, {cieInfo && cieInfo.country}, {cieInfo && cieInfo.postal_code}
                <br />
                <br />
                © 2024 Eva Global
              </p>
            </div>
          </div>
        );
    } else {
      return (
        <LiveTracker
          {...this.props}
          public
          tracking_id={this.state.tracking_id}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    communityInfo: (!isEmptyObject(state.communities) && state.userData.selectedCommunityName !== "") ? JSON.parse(state.communities[state.userData.selectedCommunityName].comm_info) : null,
    exchangeRates: (state.exchangeRates) ? state.exchangeRates : null,
  };
};

export default connect(mapStateToProps)(Home);

