import React from "react";
import { Component } from "react";
import logo from "../../assets/img/eva.png";

export default class LoadingIcon extends Component {
  state = {
    opacity: 50,
    increasing: true,
  };

  interval = null;

  componentDidMount() {
    this.interval = setInterval(() => {
      let opacity = this.state.opacity;
      let increasing = this.state.increasing;

      if (opacity >= 100) {
        this.setState({ increasing: false });
      }

      if (opacity <= 0) {
        this.setState({ increasing: true });
      }

      this.setState({ opacity: increasing ? opacity + 1 : opacity - 1 });
    }, 15);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        style={{
          marginTop: "15%",
          textAlign: "center",
        }}>
        <img
          src={logo}
          alt="LOADING_ICON"
          style={{ opacity: this.state.opacity / 100 }}
          width="100px"
          height="100px"
        />
      </div>
    );
  }
}

