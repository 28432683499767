import * as actionTypes from '../actions/action_types';
import { removeAccents } from '../views/util';

const initialState = {
    isAuthenticated: false,
    userAuthKey: null,
    partnerName: ""
}; 

export default (state  = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_LOG_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                partnerName: action.payload.partnerName,
                userAuthKey: action.payload.userAuthKey
            };
        case actionTypes.ON_UPDATE_SELECT_COMMUNITY:
            return {
                ...state,
                selectedCommunityName: removeAccents(action.payload.selectedCommunityName)
            }
        case actionTypes.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state; 
    }
};