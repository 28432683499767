import React from "react";
import LiveTrackerPublic from "../views/LiveTracker/LiveTrackerPublic.jsx";
import LiveTrackerDemoPublic from "../views/LiveTracker/LiveTrackerDemoPublic.jsx";

import { Trans } from "react-i18next";

var publicRoutes = [
  {
    path: "/public/live_tracker",
    name: <Trans>LIVETRACKER</Trans>,
    icon: "pe-7s-graph",
    component: LiveTrackerPublic
  },
  {
    path: "/public/demo/live_tracker",
    name: <Trans>LIVETRACKER_DEMO</Trans>,
    icon: "pe-7s-graph",
    component: LiveTrackerDemoPublic
  }
];
export default publicRoutes;
