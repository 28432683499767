import "./AccountSettingsStyle.css";

import {
  Button,
  ButtonGroup,
  Col,
  FormControl,
  Grid,
  Row,
  ToggleButton,
} from "react-bootstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAccountSettings,
  getAddressAutoComplete,
  getSelectedCommunity,
  saveAccountSettings,
} from "../../helpers/communityApiHelper";
import {
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
} from "../../helpers/alertHelper";

import AutoComplete from "../OrderRide/AutoComplete";
import { Trans } from "react-i18next";

let timeout = null;

class AccountSettings extends Component {
  state = {
    alert: null,
    originalSettings: null,
    email: "",
    sendSmsTracking: false,
    sendEmailTracking: false,
    showOptionsForAddress: false,
    autoCompleteResults: []
  };

  async componentDidMount() {
    const accountSettings = await getAccountSettings();
    this.setState({
      originalSettings: accountSettings,
      email: accountSettings.email,
      sendSmsTracking: accountSettings.send_sms_tracking,
      sendEmailTracking: accountSettings.send_email_tracking,
      defaultAddress: accountSettings.default_address,
      aptNumber: accountSettings.apt_number || "",
    });
  }

  async componentDidUpdate(prevProps) {
    // Check if selectedData has changed
    if (prevProps.userData.selectedCommunityName !== this.props.userData.selectedCommunityName) {
      const accountSettings = await getAccountSettings();
      this.setState({
        originalSettings: accountSettings,
        email: accountSettings.email,
        sendSmsTracking: accountSettings.send_sms_tracking,
        sendEmailTracking: accountSettings.send_email_tracking,
        defaultAddress: accountSettings.default_address,
        aptNumber: accountSettings.apt_number || "",
      });
    }
  }

  changeSmsTracking = (bool) => {
    this.setState({ sendSmsTracking: bool });
  };

  changeEmailTracking = (bool) => {
    this.setState({ sendEmailTracking: bool });
  };

  saveSettings = async () => {
    this.loadingHandlerAlert();
    const {
      email,
      sendSmsTracking,
      sendEmailTracking,
      defaultAddress,
      aptNumber,
    } = this.state;
    const { success } = await saveAccountSettings(
      email,
      sendSmsTracking,
      sendEmailTracking,
      defaultAddress,
      aptNumber
    );
    if (success) {
      this.setState({
        originalSettings: await getAccountSettings(),
      });
      this.successHandlerAlert();
    } else {
      this.errorHandlerAlert();
    }
  };

  errorHandlerAlert = (error, msg) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(), error, msg),
    });
  };

  loadingHandlerAlert = () => {
    this.setState({
      alert: getLoadingAlert(),
    });
  };

  successHandlerAlert = () => {
    this.setState({
      alert: getSuccessAlert(() => this.hideAlert()),
    });
  };

  hideAlert() {
    this.setState({ alert: null });
  }

  handleAutoCompleteSelect(e) {
    this.setState({
      defaultAddress: e.textContent,
      showOptionsForAddress: false,
      autoCompleteResults: [],
    });
  }

  handleChangeAddress(e) {
    let defaultAddress = e.target.value;
    this.setState({
      defaultAddress,
      locationStepDone: false,
      selectedRideServiceId: -1,
      showOptionsForStart: false,
    });

    this.getAddressAutoCompleteResults(defaultAddress);
  }

  handleLocationInput(e) {
    this.setState({
      showOptionsForAddress: true,
      selectedBox: e.target,
      autoCompleteResults: [],
    });
  }

  getAddressAutoCompleteResults(query) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      getAddressAutoComplete(0, 0, query).then((data) => {
        this.setState({ autoCompleteResults: data.predictions });
      });
    }, 250);
  }

  handleChangeApartment = (e) => {
    const { value } = e.target;
    if (isNaN(value)) return;
    this.setState({ aptNumber: parseInt(value) || "" });
  };

  render() {
    return (
      <div
        className="account-settings-container"
        style={{
          minHeight: "85vh",
          padding: "6%",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        {this.state.alert}
        <Grid>
        <Row>
            <Col md={6} className="col-d">
              <p className="setting-title">
                <Trans>BLOCKCHAIN_ID</Trans>
              </p>
              <p className="setting-subtitle">
                <i>
                  <Trans>BLOCKCHAIN_ID_SUBTITLE</Trans>
                </i>
              </p>
              <FormControl
                style={{ margin: "auto", width: "50%", textAlign: "center"}}
                id="blockchain_id"
                value={getSelectedCommunity().riderAccount}
                name="blockchain_id"
                disabled={true}
              />
              </Col>
              <Col md={6} className="col-d">
              <p className="setting-title">
                <Trans>ACCESS_TOKEN</Trans>
              </p>
              <p className="setting-subtitle">
                <i>
                  <Trans>ACCESS_TOKEN_SUBTITLE</Trans>
                </i>
              </p>
              <FormControl
                style={{ margin: "auto", width: "50%", textAlign: "center" }}
                id="access_token"
                value={getSelectedCommunity().token}
                name="access_token"
                disabled={true}
              />
          </Col>
          </Row>
          <Row>
            <Col md={6} className="col-d">
              <p className="setting-title">
                <Trans>EMAIL_TITLE</Trans>
              </p>
              <p className="setting-subtitle">
                <i>
                  <Trans>EMAIL_SUBTITLE</Trans>
                </i>
              </p>
              <FormControl
                style={{ margin: "auto", width: "50%", textAlign: "center"}}
                id="email"
                value={this.state.email}
                name="email"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              </Col>
              <Col md={6} className="col-d">
              <p className="setting-title">
                <Trans>ADDRESS_TITLE</Trans>
              </p>
              <p className="setting-subtitle">
                <i>
                  <Trans>ADDRESS_SUBTITLE</Trans>
                </i>
              </p>
              <FormControl
                style={{ margin: "auto", width: "50%", textAlign: "center" }}
                id="default_address"
                value={this.state.defaultAddress}
                name="default_address"
                onChange={(e) => this.handleChangeAddress(e)}
                onClick={(e) => this.handleLocationInput(e)}
                autoComplete="off"
              />
          </Col>
          </Row>
          <Row>
          <Col md={6} className="col-d">
              <p className="setting-subtitle">
                <i>
                  <Trans>SEND_EMAIL_TRACKING</Trans>
                </i>
              </p>
              <ButtonGroup
                toggle="true"
                className="mb-2"
                onChange={(e) =>
                  this.setState({
                    sendEmailTracking: e.target.value === "true" ? true : false,
                  })
                }
              >
                <ToggleButton
                style={{borderColor: "#CCC", height: "40px"}}
                  type="radio"
                  name="send-email-tracking-radio"
                  value="true"
                  checked={this.state.sendEmailTracking === true}
                >
                  &ensp;<Trans>YES</Trans>
                </ToggleButton>
                <ToggleButton
                style={{borderColor: "#CCC", height: "40px"}}
                  type="radio"
                  name="send-email-tracking-radio"
                  value="false"
                  checked={this.state.sendEmailTracking === false}
                >
                  &ensp;<Trans>NO</Trans>
                </ToggleButton>
                </ButtonGroup>
            </Col>
            <Col md={6} className="col-d">
            <p className="setting-subtitle">
                <i>
                  <Trans>COMPANY_APARTMENT_NUMBER</Trans>
                </i>
              </p>
              <FormControl
                style={{ margin: "auto", width: "50%", textAlign: "center" }}
                id="apartment_number"
                value={this.state.aptNumber}
                name="apartment_number"
                onChange={(e) => this.handleChangeApartment(e)}
                autoComplete="off"
              />
              <AutoComplete
                options={this.state.autoCompleteResults}
                idKey={"id"}
                valueKey={"description"}
                showOptions={this.state.showOptionsForAddress}
                handleClick={(e) => this.handleAutoCompleteSelect(e)}
                style={{
                  top: 148,
                }}
                inputStyle={{
                  fontSize: 15,
                  width: "50%",
                  margin: "auto",
                }}
              />
            </Col>
            </Row>
            <Row>
            <Col md={6} className="col-d">
            <p className="setting-subtitle">
                <i>
                  <Trans>SEND_SMS_TRACKING</Trans>
                </i>
              </p>
              <ButtonGroup
                toggle
                className="mb-2"
                onChange={(e) =>
                  this.setState({
                    sendSmsTracking: e.target.value === "true" ? true : false,
                  })
                }
              >
                <ToggleButton
style={{borderColor: "#CCC", height: "40px"}}
                  type="radio"
                  name="send-sms-tracking-radio"
                  value="true"
                  checked={this.state.sendSmsTracking === true}
                >
                  &ensp;<Trans>YES</Trans>
                </ToggleButton>
                <ToggleButton
style={{borderColor: "#CCC", height: "40px"}}
                  type="radio"
                  name="send-sms-tracking-radio"
                  value="false"
                  checked={this.state.sendSmsTracking === false}
                >
                  &ensp; <Trans>NO</Trans>
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: "20%" }}>
            <Col md={6}>
              <Button
                style={{ width: "40%" }}
                onClick={() =>
                  this.setState({
                    email: this.state.originalSettings.email,
                    sendEmailTracking:
                      this.state.originalSettings.send_email_tracking,
                    sendSmsTracking:
                      this.state.originalSettings.send_sms_tracking,
                  })
                }
              >
                <Trans>RESET</Trans>
              </Button>
            </Col>
            <Col md={6}>
              <Button
                style={{ width: "40%" }}
                onClick={() => this.saveSettings()}
              >
                <Trans>SAVE</Trans>
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData
  };
};

export default connect(mapStateToProps)(AccountSettings);
