import { localTimeZone } from "../variables/secretEnvVariables";
import i18n from "../i18n";
import moment from 'moment';
require('moment/locale/fr');

function setLocaleWithCurrentLanguage() {
  if (i18n.language.includes("fr")) {
    moment.locale('fr');
  } else {
    moment.locale('en');
  }
}

export function isToday(someDate) {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

export function utcStringToLocalString(utcString) {
  setLocaleWithCurrentLanguage();
  var utcTime = moment.utc(utcString).toDate();
  return moment(utcTime).format('YYYY-MM-DD HH:mm:ss');
}

export function isoToDateTime(isoString) {
  return new Date(isoString);
}

export function utcStringToLocalDateString(utcString) {
  if (!utcString) {
    return "";
  } else {
    return moment.utc(utcString).format('YYYY-MM-DD');
  }
}

export function convertLocalDateToBlockTime(date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}