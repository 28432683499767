import React, { useState, useEffect } from 'react';
import "./AnimatedPlusButton.css";


function AnimatedPlusButton(props) {
  const { color, width, stroke, isInitiallyRotated, onClick } = props;

  // Using this unique class name for js query
  const uniqueId = (Math.random() + 1).toString(36).substring(9);
  const [uniqueClassName, setUniqueClassName] = useState(`element-${uniqueId}`);

  useEffect(() => {
    if (color) {
      const bars = document.querySelectorAll(`div.${uniqueClassName}>div`);
      bars.forEach(bar => bar.style.backgroundColor = color);
    }

    if (width) {
      const barsContainer = document.querySelector(`div.${uniqueClassName}`);
      const widthInPx = width + "px";
      barsContainer.style.minWidth = widthInPx;
      barsContainer.style.minHeight = widthInPx;
      barsContainer.style.width = widthInPx;
      barsContainer.style.height = widthInPx;
    }

    if (stroke) {
      const bars = document.querySelectorAll(`div.${uniqueClassName}>div`);
      bars.forEach(bar => {
        bar.style.height = stroke + "px";
        bar.style.top = `calc(50% - ${stroke / 2}px)`;
      });
      //calc(50% - 4px)
    }
  }, [color]);

  const handleClick = () => {
    if (typeof hasRotated === 'undefined') {
      const parentWrapper = document.querySelector(`div.${uniqueClassName}`);
      const movingBar = parentWrapper.querySelector(".moving-bar");
      movingBar.classList.toggle('move');
    }
    onClick();
  }

  return (
    <div className={`animated-plus-button ${uniqueClassName}`} onClick={handleClick}>
      <div className={`moving-bar${isInitiallyRotated ? " move" : ""}`} />
      <div />
    </div>
  );
}

export default AnimatedPlusButton;

