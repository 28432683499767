import { Col, Grid, Row } from "react-bootstrap";
import React, { Component } from "react";

import Card from "../../components/Card/Card.jsx";
import { RideRequestStatus, RideRequestStatusV2, RideRequestStatusV2_LABELS } from "../../const/RideRequestStatus";
import RideServiceNames from "../../const/RideServiceNames";
import TableAPI from "../../components/Tables/TableAPI";
import { Trans } from "react-i18next";
import {
  getCompletedCorporateRides,
  getDeliveryProofExistence,
  getDeliveryProof
} from "../../helpers/apiHelper";
import { utcStringToLocalString } from "../../helpers/dateHelper";
import cameraImg from "../../assets/img/camera.png";
import { CurrencyFormatter } from "../../helpers/CurrencyFormatter";
import { connect } from "react-redux";

class RideHistory extends Component {
  state = {
    imageUrl: null,
  };
  onRideClick = (rowInfo) => {
    const win = window.open("/live_tracker?tracking_id=" + rowInfo.original.business_tracking_id, "_blank");
    win.focus();
  };

  onProofClick = async (tracking_id) => {
    try {
      const doesExist = await getDeliveryProofExistence(tracking_id);
      console.log('doesExist', doesExist);
  
      if (doesExist.data) {
        const proofResponse = await getDeliveryProof(tracking_id);
  
        const imageData = proofResponse.data;
        const imageUrl = `data:${proofResponse.headers['Content-Type']};base64,${imageData}`;
  
        var w = window.open("", "_blank");
        w.document.write(`<img src="${imageUrl}" style="max-height: 100%; max-width: 100%;" />`);
      } else {
        window.open('/not_found');
      }
    } catch (error) {
      console.error('Error fetching delivery proof:', error);
    }
  };

  getRideStatusStyle = (status) => {
    let color = null;
    switch (status) {
      case RideRequestStatusV2.COMPLETED:
        color = "green";
        break;
      case RideRequestStatusV2.CANCELLED:
        color = "red";
        break;
      default:
        color = "gray";
        break;
    }
    return color;
  };

  canGetDeliveryProof = (rowInfo) => {
    return [1,2,3,4].includes(rowInfo.ride_type_id) && rowInfo.ride_status_id === RideRequestStatusV2.COMPLETED;
  }

  render() {
    const defaultView = (
      <div>
        <br />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                plain
                ctTableFullWidth
                ctTableResponsive
                content={
                  <TableAPI
                    fetchData={getCompletedCorporateRides}
                    getTrProps={(state, rowInfo) => ({
                      onClick: () => this.onRideClick(rowInfo),
                    })}
                    columns={[
                      {
                        Header: <Trans>DATE</Trans>,
                        canFilter: true,
                        canSort: true,
                        defaultSort: true,
                        filterMode: "date",
                        id: "scheduled_timestamp",
                        width: 150,
                        accessor: (el) => {
                          return utcStringToLocalString(el.scheduled_timestamp);
                        },
                      },
                      {
                        Header: <Trans>CUSTOMER_FIRST_NAME</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "customer_first_name",
                        width: 100,
                        accessor: (el) => {
                          return el.customer_first_name;
                        },
                      },
                      {
                        Header: <Trans>CUSTOMER_LAST_NAME</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "customer_last_name",
                        width: 100,
                        accessor: (el) => {
                          return el.customer_last_name;
                        },
                      },
                      {
                        Header: <Trans>CUSTOMER_PHONE</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "customer_phone_number",
                        width: 100,
                        accessor: (el) => {
                          return el.customer_phone_number;
                        },
                      },
                      {
                        Header: <Trans>ORDER_NUMBER</Trans>,
                        canFilter: true,
                        canSort: true,
                        id: "order_number",
                        width: 100,
                        accessor: (el) => {
                          return el.order_number;
                        },
                      },
                      {
                        Header: <Trans>STATUS</Trans>,
                        id: "ride_status_id",
                        width: 140,
                        canFilter: true,
                        filterMode: "select",
                        options: [
                          { value: "", label: <Trans>ALL</Trans> },
                          { value: 8, label: RideRequestStatusV2_LABELS[8] },
                          { value: 9, label: RideRequestStatusV2_LABELS[9] },
                          // { value: 1, label: RideRequestStatusV2_LABELS[1] },
                          // { value: 2, label: RideRequestStatusV2_LABELS[2] },
                          // { value: 3, label: RideRequestStatusV2_LABELS[3] },
                          // { value: 4, label: RideRequestStatusV2_LABELS[4] },
                          // { value: 5, label: RideRequestStatusV2_LABELS[5] },
                          // { value: 6, label: RideRequestStatusV2_LABELS[6] },
                          // { value: 7, label: RideRequestStatusV2_LABELS[7] },
                          // { value: 10, label: RideRequestStatusV2_LABELS[10] },
                        ],
                        accessor: (el) => {
                          return RideRequestStatusV2_LABELS[el.ride_status_id];
                        },
                        Cell: (row) => <Trans>{row.value}</Trans>,
                        getProps: (state, row, column) => {
                          if (row && row.original) {
                            const color = this.getRideStatusStyle(row.original.ride_status_id);
                            return { style: { color } }
                          } else {
                            return {}
                          }
                        },
                      },
                      {
                        Header: <Trans>RIDE_SERVICE_NAME</Trans>,
                        id: "ride_type_id",
                        width: 100,
                        canFilter: true,
                        filterMode: "select",
                        options: [{ value: "", label: <Trans>ALL</Trans> }].concat(
                          [1,2,3,4,5].map(rideServiceType => {
                            return {
                              value: rideServiceType,
                              label: <Trans>{RideServiceNames[rideServiceType]}</Trans>
                            }
                          })),
                        accessor: (el) => {
                          return el.ride_type_id;
                        },
                        Cell: (row) => (
                          <Trans>{RideServiceNames[row.value]}</Trans>
                        ),
                      },
                      {
                        Header: <Trans>FROM_ADDRESS</Trans>,
                        canFilter: true,
                        width: 130,
                        id: "from_address",
                        accessor: (el) => {
                          return el.from_address;
                        },
                      },
                      {
                        Header: <Trans>TO_ADDRESS</Trans>,
                        canFilter: true,
                        id: "to_address",
                        width: 130,
                        accessor: (el) => {
                          return el.to_address;
                        },
                      },
                      {
                        Header: <Trans>TOTAL_COST</Trans>,
                        canFilter: false,
                        id: "total_order_amount",
                        width: 75,
                        accessor: (el) => {
                          if (!el.total_cost) return '-';
                          
                          return CurrencyFormatter.formatAmount(el.total_cost, el.currency);
                        },
                      },
                      {
                        Header: <Trans>PROOF</Trans>,
                        canFilter: false,
                        id: "delivery_proof",
                        width: 30,
                        Cell: (e) => {
                          // console.log(e)
                          return this.canGetDeliveryProof(e.original) ?
                            <span
                              onClick={(event) => {
                                event.stopPropagation();
                                this.onProofClick(e.original.tracking_id)
                              }}
                            >
                              <img style={{ maxHeight: "30px", cursor: "pointer", marginTop: "-5px", marginTopBottom: "-5px" }} src={cameraImg} />
                            </span>
                            : <></>
                        },
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              overflow: "visible",
                            },
                          };
                        },
                      },
                    ]}
                    className="-highlight"
                    extraFilters={{ 
                      'impersonate_id': this.props.impersonated_partner ? this.props.impersonated_partner.partner_id : null,
                     }}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <p></p>
        <br></br>
        <br></br>
      </div>
    );

    return <div className="content">{defaultView}</div>;
  }
}


const mapStateToProps = (state) => {
  return {
    ...state.partners,
  };
};

export default connect(mapStateToProps)(RideHistory);
