import "./EvaButton.css";

import React, { Component } from "react";

class RoundButton extends Component {
  render() {
    return (
      <div class="eva-y-button-wrapper-round">
        <div
          style={{ ...this.props.style }}
          class={"eva-y-button-round"}
          onClick={this.props.action}
        >
          <div
            className={this.props.icon + (this.props.large ? " fa-lg" : "")}
          />
        </div>
      </div>
    );
  }
}

export default RoundButton;

