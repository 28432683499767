import React, { Component } from "react";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import {
  getInvoices,
  sendLoggedGET,
  sendLoggedPOST,
  getPaymentPermissions,
  getSelectedCommunity
} from "../../helpers/communityApiHelper";
import "./Payment.css";

import { connect } from 'react-redux';
import { CurrencyFormatter } from "../../helpers/CurrencyFormatter";
import { INVOICE_TYPES } from "../../const/Invoices";
import moment from "moment";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      credit_card_payment_allowed: false,
      credit_card_form_toggle: false,
      success: null,
      set: null
    };
  }

  componentDidMount = async () => {
    this.fetchPendingInvoices();
    this.fetchPaymentPermissions();
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );
    if (clientSecret) {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({setupIntent}) => {
          switch (setupIntent.status) {
            case 'succeeded':
              this.setState({ success: true })
              break;
  
            default:
              this.setState({ success: false })
          }
        });
    }
  }

  async componentDidUpdate(prevProps) {
    // Check if selectedData has changed
    if (prevProps.selectedCommunityName !== this.props.selectedCommunityName) {
      this.fetchPaymentPermissions();
      this.fetchPendingInvoices();
    }
  }

  fetchPaymentPermissions = async () => {
    const data = await getPaymentPermissions();
    this.setState({credit_card_payment_allowed: data.permission_credit_card_payment});
    this.setState({set: data.has_card_already_set});
  }

  fetchPendingInvoices = async () => {
    await getInvoices().then(invoices => {
      invoices.sort((a, b) => {
        if ((a.paid === false && b.paid === false) || (a.paid === true && b.paid === true)) return new Date(a.from_date_inclusive) - new Date(b.from_date_inclusive)
        else if (a.paid == true && b.paid === false) return 1
        else return -1
      })
      this.setState({ invoices });
    }).catch(error => {
      console.error(error)
      this.errorAlert({ message: "ERROR_ALERT" });
    });
  }

  errorAlert = (error) => {
    const { title, message } = error;
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={i18n.t(title || "ERROR")}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          <p><Trans>{i18n.t(message)}</Trans></p>
        </SweetAlert>
      )
    })
  }

  download = (endpoint, filename) => {
    sendLoggedGET("api/v1/mia/" + endpoint, true, "", { params: { zone_id: 1 }, responseType: "blob" })
        .then(response => new Blob([response.data], { type: response.headers["Content-Type"] }))
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
  }

  handleSubmit = async (event, stripe, elements) => {
    try {
      event.preventDefault();

      if (elements == null) {
        return;
      }

      const element = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: element,
      });

      element.clear();

      if (error) {
        console.log(error);
      }

      if (!paymentMethod) {
        alert("please enter valid credit card info");
        return;
      }

      const response = await sendLoggedPOST("api/v1/mia/create_payment_method", {
        "payment_method_id": paymentMethod.id,
      })

      // console.log('response', response)
      this.setState({credit_card_form_toggle: false });
      CardElement.clear();

      if (response.data && response.data.success === true && response.data.redirect_url) {
        window.open(response.data.redirect_url);
      } else if (response.data && response.data && response.data.success === true && !response.data.redirect_url) {
        this.setState({success: true})
      }
    } catch (error) {

    }
  };

  handleClick = () => {
    this.setState({credit_card_form_toggle: !this.state.credit_card_form_toggle});
  };
  render() {
    const invoices = this.state.invoices;
    return (
      <div className="invoices-list-container">
        {this.state.credit_card_payment_allowed ? (
        <div> 
          {this.state.set && <div style={{color: "dark-grey"}} className="success-card-message"><Trans>CARD_IS_SET</Trans></div>}
          <div className="toggle-text" onClick={this.handleClick}>
            <h4><u><Trans>SET_CARD</Trans></u> {this.state.credit_card_form_toggle ? "V" : ">"}</h4>
          </div>
          <div className="invoices-card-container">
            <div className={`${this.state.credit_card_form_toggle ? "open" : "closed"}`}>
              <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { locale: i18n.language })} >
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <form onSubmit={(event) => this.handleSubmit(event, stripe, elements)} className="invoices-element">
                      <CardElement countryCode="CA"/>
                      <button type="submit" disabled={!stripe} className="btn btn-default" style={{marginBottom: "10px"}}>
                        <Trans>SAVE_CARD</Trans>
                      </button>
                    </form>
                  )}
                </ElementsConsumer>
                
              </Elements>
                <div style={{display: "block", fontSize: "8pt"}}><Trans>PAYMENT_FOOTNOTE_1</Trans></div>
                <div style={{display: "block", fontSize: "8pt"}}><Trans>PAYMENT_FOOTNOTE_2</Trans></div>
                <div style={{display: "block", fontSize: "8pt"}}><Trans>PAYMENT_FOOTNOTE_3</Trans></div>
            </div>
            </div>
          </div>) : <></>}
        {this.state.success && <div style={{color: "green"}} className="success-card-message"><Trans>SAVE_CARD_SUCCESS</Trans></div>}
        {this.state.success === false && <div style={{color: "red"}} className="error-card-messages"><Trans>SAVE_CARD_FAILURE</Trans></div>}

        <ReactTable
          data={invoices}
          filterable
          getTrProps={(state, rowInfo) => {
            return {
              style: {
                cursor: "pointer",
                backgroundColor:
                  rowInfo && rowInfo.original.is_active
                    ? "rgba(236,208,49,0.4)"
                    : "",
                borderRadius: "30px",
              }
            };
          }}
          columns={[
            {
              Header: <Trans>INVOICE_NUMBER</Trans>,
              id: "invoice_number",
              accessor: (invoice) => {
                return invoice.invoice_number;
              },
              filterable: true
            },
            {
              Header: <Trans>PAID</Trans>,
              id: "paid",
              accessor: (invoice) => {
                let checkbox = <input type="checkbox"
                  checked={invoice.paid}
                  disabled={true}>
                </input>

                return checkbox;
              },
              sortable: true,
              filterable: false
            },
            {
                Header: <Trans>Type</Trans>,
                id: "invoice_type",
                accessor: (invoice) => {
                    return INVOICE_TYPES[invoice.invoice_type];
                },
                filterable: true
            },
            {
              Header: <Trans>Total</Trans>,
              id: "total",
              accessor: (invoice) => {
                  return CurrencyFormatter.formatAmount(invoice.total_charge_with_tax_with_tip_with_refund, invoice.currency);
              },
              filterable: false
           },
           {
               Header: <Trans>FROM_INVOICE</Trans>,
               id: "from_date_inclusive",
               accessor: (invoice) => {
                   return moment.utc(invoice.from_date_inclusive).format('YYYY-MM-DD');
               },
               filterable: true
           },
           {
               Header: <Trans>TO_INVOICE</Trans>,
               id: "to_date_inclusive",
               accessor: (invoice) => {
                   return moment.utc(invoice.to_date_inclusive).format('YYYY-MM-DD');
               },
               filterable: true
            },
            {
              Header: <Trans>INVOICE_FILES</Trans>,
              id: "select",
              accessor: (invoice) => {
                //will download file in browser
                return <span>
                  <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/csv`, `invoice-${invoice.invoice_number}.csv`) }}>csv </a>
                  <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/pdf`, `invoice-${invoice.invoice_number}.pdf`) }}>pdf </a>
                  <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/excel`, `invoice-${invoice.invoice_number}.xlsx`) }}>excel</a>
                </span>;
              },
              filterable: false
            }
          ]}
          className="-highlight"
          noDataText={<Trans>NO_INVOICES</Trans>}
          sorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />
      </div>
    )
  };
}

const mapStateToProps = state => {
  return {
    companyName: state.userData.companyName,
    selectedCommunityName: state.userData.selectedCommunityName
  };
};

export default connect(mapStateToProps)(Payment);
