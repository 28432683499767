import "./NoteBar.css";

import React, { Component } from "react";

import { Trans } from "react-i18next";
import AnimatedPlusButton from "../../../../../components/AnimatedPlusButton/AnimatedPlusButton";

class NoteBar extends Component {
  state = {
    expanded: false,
  };

  componentDidMount() {
    const expanded = this.props.expanded || false;
    this.setState({ expanded });
  }

  expandSection = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const style = this.props.style;
    return (
      <div className="tracking-link-note-container">
        <div className="note-title">
          <h4 style={(this.props.style && this.props.style.titleColor) ? {color: this.props.style.titleColor} : {}}><Trans>NOTE</Trans></h4>
          <AnimatedPlusButton
            color={style && style.primaryColor}
            stroke="3"
            width="17"
            onClick={this.expandSection}
          />
        </div>
        {
          this.state.expanded &&
          <p className="note-text" style={{color: this.props.style && this.props.style.textColor}}>
            {this.props.note}
          </p>
        }
      </div>
    )
  }
}

export default NoteBar;


/*

      <div className="tracking-link-note-container">
        <div className="note-title" style={{color: this.props.style && this.props.style.primaryColor}}>
        <Trans>RIDER_NOTE</Trans>
        </div>
        <div
          className="note-menu-arrow"
          onClick={() => this.minimize()}
          style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
        />
      </div>
    ) : this.props.version === 1 ? (
      <div className="note-container">
        <div className="note-title" style={{color: this.props.style && this.props.style.primaryColor}}>
          <Trans>RIDER_NOTE</Trans>
        </div>
        <div
          className="note-menu-arrow-1"
          onClick={() => this.minimize()}
          style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
        />
        <div className="note-text">
          {this.props.note}
        </div>
      </div>
    ) : (
      <div className="note-container">
        <div
          className="note-menu-arrow-1"
          onClick={() => this.minimize()}
          style={{backgroundColor: this.props.style && this.props.style.primaryColor}}
        />
      </div>

*/
