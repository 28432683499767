import "./DriverBar.css";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import i18n from "../../../../../i18n";
import RoundButton from "../../../CustomComponents/Button/RoundButton";
import AnimatedPlusButton from "../../../../../components/AnimatedPlusButton/AnimatedPlusButton";
import defaultAvatar from "../../../../../assets/img/default_avatar.png";
import {getPhoneProxy} from "../../../../../helpers/evaApiHelper";
import {getErrorAlert, getLoadingAlert} from "../../../../../helpers/alertHelper";

class DriverBar extends Component {
  state = {
    expanded: false,
    alert: null
  };

  componentDidMount() {
    const expanded = this.props.expanded || false;
    this.setState({expanded});
  }

  getStars = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      let color =
        rating <= 0 ? "grey" : this.props.style ? this.props.style.primaryColor : "var(--eva-yellow)";
      stars.push(
        <span
          key={i}
          className="star-div-driver"
          style={{ backgroundColor: color }}
        />
      );
      rating--;
    }
    return stars;
  };

  callDriver = async () => {
    if (this.props.showDemo) return;
  
    const errorTitle = <Trans>ERROR_ALERT</Trans>
    const errorBody = <Trans>PROXY_ERROR</Trans>
  
    try {
      const driverPhone = this.props.driverProfile.phone;
      const callerPhone = this.props.callerPhone;

      this.setState({
        alert: getLoadingAlert()
      });
  
      const response = await getPhoneProxy(
        callerPhone,
        driverPhone,
        this.props.community
      );
  
      await this.setState({
        alert: null
      });

      this.props.showProxyPopup(response.proxy, callerPhone);
    } catch (error) {
      this.errorHandlerAlert(errorTitle, errorBody);
    }
  };

  errorHandlerAlert = (title, body) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(),title,body),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  expandSection = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const style = this.props.style;
    const driverProfile = { ...this.props.driverProfile, ...this.props.driverVehicle } || {};
    const driverImage = this.props.driverImage ? `data:image/png;base64, ${this.props.driverImage}` : defaultAvatar ;

    return (
      <section class="tracking-link-driver-info-container">
       {this.state.alert}
        <div class="driver-title">
          <div className="name-and-stars-group">
            <h4 style={(this.props.style && this.props.style.titleColor) ? {color: this.props.style.titleColor} : {}}>{`${driverProfile.first_name} ${driverProfile.last_name}`}</h4>
            <div className="stars-wrapper">
              {this.getStars(driverProfile.driverRating)}
            </div>
          </div>
          <AnimatedPlusButton
            color={style && style.primaryColor}
            stroke="3"
            width="17"
            onClick={this.expandSection}
          />
        </div>
        {this.state.expanded &&
          <div className="driver-content" style={{ color: this.props.style && this.props.style.textColor}}>
            <div class="driver-picture-container">
              <img
                src={driverImage}
                alt="profile_avatar"
                className="driver-picture"
              />
            </div>
            <div class="driver-brand-and-call-icon-wrapper">
              <p>
                {(driverProfile.vehicle_brand || i18n.t('DRIVER_INFO_UNAVAILABLE')) +
                  " " +
                  (driverProfile.vehicle_model || "") +
                  " " +
                  (driverProfile.vehicle_year || "")}
                 {(driverProfile.vehicle_color) ? driverProfile.vehicle_color.toUpperCase() : ""}
              </p>
              <p>{driverProfile.vehicle_registration_plate || ""}</p>
              {this.props.callerPhone && <RoundButton style={{ backgroundColor: style && style.primaryColor }} icon="fa fa-phone" action={this.callDriver} />}
            </div>
          </div>
        }
      </section>
    );
  }
}

export default DriverBar;

