import CommunityReducer from "./CommunityReducer";
import RidesReducer from "./RidesReducer";
import ExchangeRatesReducer from "./ExchangeRatesReducer";
import SpamReducer from "./SpamReducer";
import UserReducer from "./UserReducer";
import PartnersReducer from "./PartnersReducer";
import { combineReducers } from "redux";

export default combineReducers({
  communities: CommunityReducer,
  userData: UserReducer,
  rides: RidesReducer,
  spam: SpamReducer,
  exchangeRates: ExchangeRatesReducer,
  partners: PartnersReducer,
});
