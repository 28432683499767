import React, { useState } from "react";
import { Trans } from "react-i18next";
import "../WhiteLabel.css";

function TabSelector(props) {
  let { tabs, onChange } = props;
  if (!tabs) tabs = [];

  const [selected, setSelected] = useState(tabs.length > 0 ? tabs[0] : null);

  const handleSelectionChange = (tab) => {
    setSelected(tab);
    onChange && onChange(tab);
  };

  return (
    <div className="mia-selection-container">
      {tabs.map((tab) => (
        <div
          onClick={() => handleSelectionChange(tab)}
          className={"mia-selection-item"}
          style={{
            backgroundColor: tab === selected ? "rgb(255, 192, 5)" : "",
          }}
        >
          <div style={{ margin: "auto" }}><Trans>{tab}</Trans></div>
        </div>
      ))}
    </div>
  );
}

export default TabSelector;
