import React, { useState, useEffect } from 'react';
import { store } from "../../store/index";
import { useSelector } from 'react-redux';
import { chooseCommunity } from '../../actions';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import "./DropDown.css";
import { Trans } from 'react-i18next';

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

function Dropdown() {

  const options = useSelector((state) => {
    return state.userData.communitiesAuthList ? state.userData.communitiesAuthList.map(auth => { return { value: auth.communityName, label: auth.communityName } }) : []
  });
  const [selectedValue, setSelectedValue] = useState(storeState.userData.selectedCommunityName ? storeState.userData.selectedCommunityName : (options.length > 0 ? options[0].value : ""));

  useEffect(() => {
    store.dispatch(chooseCommunity({ selectedCommunityName: selectedValue }));
  }, [selectedValue]);

  const handleChange = (value) => {
    setSelectedValue(value);
    store.dispatch(chooseCommunity({ selectedCommunityName: value }));
    setTimeout(() => {
      window.location.reload();
    }, 1000)
  };

  return (
    <>
      <NavDropdown
        title={
          <div>
            {selectedValue}
            <p className="hidden-md hidden-lg">
              <Trans>CITY</Trans>
              <b className="caret" />
            </p>
          </div>
        }
        noCaret
        id="basic-nav-dropdown-2"
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleChange(option.value)}>{option.label}</MenuItem>
        ))}
      </NavDropdown>
    </>
  );
}

export default Dropdown;
