import React, { Component, useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Trans } from "react-i18next";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { connect } from 'react-redux';
import { store } from "../../store/index";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
// backgroundImage for Sidebar
import image from "../../assets/img/sidebar-5.jpg";
// image for avatar in Sidebar
// import avatar from "assets/img/default-avatar.png";
// logo for sidebar
import logo from "../../assets/img/eva.png";
import { MenuItem, Nav, NavDropdown, NavItem } from "react-bootstrap";

import dashboardRoutes from "../../routes/dashboard.jsx";
import I18n from '../../i18n';
import { getSelectedCommunity } from "../../helpers/communityApiHelper.js";
import { logout, impersonatePartner } from "../../actions/index.js";


const bgImage = {
  backgroundColor: 'black'
};

var ps;



function ImpersonateSelector({ partners, impersonated_partner }) {
  const [search, setSearch] = useState('')
  const displayPartner = impersonated_partner ? (impersonated_partner.name || impersonated_partner.default_email) : false;

  if (partners.length ==0) return null;

  const handleUpdatePartner = (p) => {
    store.dispatch(impersonatePartner(p));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const handleSearchPartners = () => {
    return partners.filter((partner) => {
      const term = search.toLowerCase();

      if (term == '') return false;
      
      return (partner.name && partner.name.toLowerCase().includes(term))
        || (partner.default_email && partner.default_email.toLowerCase().includes(term))
        || (partner.default_phone_number && partner.default_phone_number.toLowerCase().includes(term))
    }).slice(0, 10);
  }



  return (
    <div style={{ zIndex: 1000 }}>
      <NavDropdown
        style={{ width: '100%' }}
        title={
            <div style={{ paddingLeft: 12, paddingRight: 12, textAlign: 'center' }}>
              {displayPartner && <span style={{ color: '#00FF00', fontWeight: 900, fontSize: '110%', lineHeight: 1 }}>{displayPartner}</span>}
              {!displayPartner && <span>Choisir un marchand</span>}
              <b className="caret" />
            </div>
        }
        noCaret
        id="basic-nav-dropdown-1"
      >
        {!displayPartner && <>
          <MenuItem>
            <input type="text" onClick={(e) => e.stopPropagation()} onChange={(e) => setSearch(e.target.value)}/>
          </MenuItem>
          {handleSearchPartners().map((partner) => {
            return <MenuItem key={partner.partner_id} onClick={() => handleUpdatePartner(partner)}>
              {partner.name}({partner.default_email})
            </MenuItem>;
          })}
        </>
        }

        {displayPartner && <MenuItem onClick={() => handleUpdatePartner(null)}>Annuler</MenuItem>}
      </NavDropdown>
    </div>
  );
}

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components") !== "" ? true : false,
      openForms: this.activeRoute("/forms") !== "" ? true : false,
      openTables: this.activeRoute("/tables") !== "" ? true : false,
      openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute("/pages") !== "" ? true : false,
      openPayments: this.activeRoute("/payments") !== "" ? true : false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div id="sidebar"
           className="sidebar"
           data-color="black"
           data-image={image}
           style={{overflow: 'hidden'}}>
        <div className="sidebar-background" style={bgImage} />
        <div className="logo" style={{ zIndex: 10 }}>
          <NavLink
            to={"/home"}
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="eva-logo" />
            </div>
          </NavLink>
          <NavLink
            to={"/home"}
            className="simple-text logo-normal"
          >
            Eva <span style={{fontSize: '0.7em'}}><Trans>BUSINESS</Trans></span> 

            {this.props.companyName && <div style={{fontSize: '0.75em', lineHeight: 1, marginBottom: '10px', color: 'rgb(255, 193, 22)', textTransform: 'initial' }}>
              {this.props.companyName}
            </div>}



          </NavLink>

          
          <ImpersonateSelector {...this.props} />
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {/* If we are on responsive, we want both links from navbar and sidebar
                            to appear in sidebar, so we render here HeaderLinks */}
            {this.state.width <= 992 ? <HeaderLinks {...this.props} /> : null}
            {/*
                            here we render the links in the sidebar
                            if the link is simple, we make a simple link, if not,
                            we have to create a collapsible group,
                            with the speciffic parent button and with it's children which are the links
                        */}
            {dashboardRoutes.map((prop, key) => {
              var st = {};
              st[prop["state"]] = !this.state[prop.state];
              if (prop.collapse) {
                return (
                  <li className={this.activeRoute(prop.path)} key={key}>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => this.setState(st)}>
                      <i className={prop.icon} />
                      <p>
                      {I18n.t(prop.name.props.children)}
                        <b
                          className={
                            this.state[prop.state]
                              ? "caret rotate-180"
                              : "caret"
                          }
                        />
                      </p>
                    </a>
                    <Collapse in={this.state[prop.state]}>
                      <ul className="nav">
                        {prop.views.map((prop, key) => {
                          return (
                            <li
                              className={this.activeRoute(prop.path)}
                              key={key}
                            >
                              <NavLink
                                to={prop.path}
                                className="nav-link"
                                activeClassName="active"
                              >
                                <span className="sidebar-mini">
                                  {prop.mini}
                                </span>
                                <span className="sidebar-normal">
                                  {I18n.t(prop.name.props.children)}
                                </span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </li>
                );
              } else if (prop.hideOnSidebar) {
                return null; 
              } else {
                if (prop.redirect) {
                  return null;
                } else {
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <p> {I18n.t(prop.name.props.children)}</p>
                      </NavLink>
                    </li>
                  );
                }
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  try {
    return {
      companyName: state.userData.hasOwnProperty('partnerName') ? state.userData.partnerName : null,
      ...state.partners,
    };
  } catch(error) {
    store.dispatch(logout());
  }
};

export default connect(mapStateToProps)(Sidebar);
