function convertToFiat(
  tokenAmount: number,
  utc_datetime_string: string = new Date().toISOString()
): string {
  const fiatAmount: number = (tokenAmount / 100);
  return fiatAmount.toString();
}

function convertToToken(
    fiatAmount: number,
    utc_datetime_string: string = new Date().toISOString(),
    getAsString: boolean = true
  ): string | number {
    const tokenAmount: string = Math.round((fiatAmount * (1) * 100)).toFixed(4);
    if (getAsString) {
      return tokenAmount + " EVA";
    } else {
      return parseFloat(tokenAmount)
    }
  }

  function formatQuote(
    totalCharge: number,
    currencyCode: string = "CAD"
  ): string {
    return new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: currencyCode,
    }).format(totalCharge / 100);
  }

  function getSymbol(): string {
    return formatQuote(0).replace(/[\d\., ]/g, '');
  }

export { convertToFiat, convertToToken, getSymbol };
