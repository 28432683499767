import "./MenuOption.css";

import React, { Component } from "react";

import { Trans } from "react-i18next";

class MenuOption extends Component {
  render() {
    return this.props.comingSoon ? (
      <div
        className="tracking-link-menuoption-wrapper-inactive"
        style={{borderColor: this.props.style && this.props.style.primaryColor}}
        onClick={this.props.action}
      >
        <div className="tracking-link-menuoption-label">{this.props.label} </div>
        <span style={{ float: "right" }}>
          (<Trans>COMING_SOON</Trans>)
        </span>
      </div>
    ) : (
      <div className="tracking-link-menuoption-wrapper" style={{borderColor: this.props.style && this.props.style.primaryColor}}  onClick={this.props.action}>
        <div className="tracking-link-menuoption-label">{this.props.label}</div>
        <div className="tracking-link-menuoption-arrow" style={{backgroundColor: this.props.style && this.props.style.primaryColor}} />
      </div>
    );
  }
}

export default MenuOption;

