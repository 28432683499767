import Dashboard from "../layouts/Dashboard/Dashboard.jsx";
import Public from "../layouts/Public/Public.jsx";

var indexRoutes = {
  //Protected Mia Routes
  mia: [
  	{ path: "/", component: Dashboard }
  ],

  //Public Routes
  public: [
  	{ path: "/public/", component: Public }
  ]
};

export default indexRoutes;
