import React, { Component } from 'react';
import { getErrorAlert, getLoadingAlert } from "../../helpers/alertHelper";

import OrderRide1 from './OrderRide1';
import OrderRide2 from './OrderRide2';
import OrderRide3 from './OrderRide3';
import { Redirect } from "react-router-dom";
import { callRide, getAccountSettings } from '../../helpers/apiHelper';
import classnames from "classnames";
import { connect } from 'react-redux';
import { convertToToken } from "../../helpers/paymentHelper";
import i18n from "i18next";
import styles from "./OrderRideStyle.module.css";
import { withTranslation } from 'react-i18next';

var moment = require("moment-timezone");

class OrderRideWrapper extends Component {

    constructor(props) {
        super(props);

        const dateNow = moment();
        const hour = dateNow.hours().toFixed().padStart(2, '0');
        const min = dateNow.minutes().toFixed().padStart(2, '0');

        this.state = {
            alert: null,
            currentStep: 0,
            firstStepDone: false,
            secondStepDone: false,
            thirdStepDone: false,
            accountSettings: null,
            firstStepData: {
                isNow: true,
                datePickerActive: false,
                onsiteDatetime: moment(),
                hour, min,
                currentCoordinates: { lat: "45.5196609", lon: "-73.5866621" },
                selectedQuote: null,
            },
            secondStepData: {
                customerPhone: ""
            },
            thirdStepData: {
                pickupCompanyName: this.props.companyName,
                tipTokenAmount: 0,
                disableCallRideButton: false
            }
        }
    }

    async componentDidMount() {
        const impersonate_id = this.props.impersonated_partner ? this.props.impersonated_partner.partner_id : null;
        const accountSettings = await getAccountSettings(impersonate_id);
        this.setState({ accountSettings })

        // default field values from backend
        this.setState({
            secondStepData: {
                ...this.state.secondStepData,
                customerNote: accountSettings.order_customer_note_default || this.state.secondStepData.customerNote,
            },
            thirdStepData: {
                ...this.state.thirdStepData,
                pickupNote: accountSettings.order_pickup_note_default || this.state.thirdStepData.pickupNote,
                pickupCompanyName: accountSettings.company_name || this.state.thirdStepData.pickupCompanyName,
                pickupPhone: accountSettings.default_phone_number || this.state.thirdStepData.pickupPhone,
            }
        })
    }

    async componentDidUpdate(prevProps) {
        // Check if selectedCommunityName has changed
        if (prevProps.selectedCommunityName !== this.props.selectedCommunityName) {
          this.setState({
            currentStep: 0
          })
        }
      }

    goToPreviousPage = (prevStepDone) => {
        this.setState({
            [prevStepDone]: false,
            currentStep: this.state.currentStep - 1
        });
        this.goToTopWindow();
    }

    goToNextPage = (stepDone) => {
        this.setState({
            [stepDone]: true,
            currentStep: this.state.currentStep + 1
        });
        this.goToTopWindow();
    }

    goToTopWindow = () => {
        window.scrollTo(0, 0);
    }

    onChange = (dataObj, inputs, callback) => {
        const data = { ...this.state[dataObj], ...inputs };
        this.setState({
            [dataObj]: data
        }, callback);
    }

    orderRide = async () => {
        window.scrollTo(0, 0);
        const win = window.open('about:blank')
        this.loadingHandlerAlert();
        this.setState({ thirdStepData: { ...this.state.thirdStepData, disableCallRideButton: true } })

        const {
            startLocation,
            startLocationOptionalInfo,
            endLocation,
            endLocationOptionalInfo,
            fromCoords,
            toCoords,
            onsiteDatetime,
            selectedRideServiceTypeId,
            selectedQuote,
            isDeliveryFeePaidInCashAtDoor,
            cashPaymentOrderValue
        } = this.state.firstStepData;

        const {
            customerFirstName,
            customerLastName,
            customerEmail,
            customerPhone,
            customerPhoneExt,
            customerNote,
        } = this.state.secondStepData;

        const {
            orderReferenceNumber,
            pickupCompanyName,
            pickupPhone,
            pickupPhoneExt,
            pickupNote,
            tipTokenAmount
        } = this.state.thirdStepData;


        const addAptNumber = (location, optionalInfo) => {
            if (optionalInfo) {
                const commaIndex = location.indexOf(",");
                return location.substring(0, commaIndex) + " #" + optionalInfo + location.substring(commaIndex);
            }

            return location;
        }

        const startLocationWithOptionalInfo = addAptNumber(startLocation, startLocationOptionalInfo);
        const endLocationWithOptionalInfo = addAptNumber(endLocation, endLocationOptionalInfo);

        let pickupPhoneWithExt = pickupPhone;
        if (pickupPhone) pickupPhoneWithExt += pickupPhoneExt ? " ext." + pickupPhoneExt : "";

        let customerPhoneWithExt = customerPhone;
        if (customerPhone) customerPhoneWithExt += customerPhoneExt ? " ext." + customerPhoneExt : "";

        const impersonate_id = this.props.impersonated_partner ? this.props.impersonated_partner.partner_id : null;

        const response = await callRide(
            startLocationWithOptionalInfo, endLocationWithOptionalInfo, fromCoords, toCoords,
            selectedRideServiceTypeId, customerFirstName, customerLastName, customerEmail, customerPhoneWithExt,
            onsiteDatetime, orderReferenceNumber, pickupCompanyName, pickupPhoneWithExt,
            tipTokenAmount, customerNote, pickupNote, isDeliveryFeePaidInCashAtDoor, cashPaymentOrderValue, null, null, impersonate_id
        )

        if (response.data.tracking_id) {
            const data = response.data;
            const { business_tracking_id } = data;
            this.setState({ tracking_id: business_tracking_id });
            win.location = "/live_tracker?tracking_id=" + this.state.tracking_id
        } else {
            const data = response.data;
            console.log("data error", data);
            this.errorHandlerAlert(i18n.t("NO_DRIVERS"), i18n.t("ORDER_ERROR"));
            this.setState({ thirdStepData: { ...this.state.thirdStepData, disableCallRideButton: false } })
        }
    }

    loadingHandlerAlert = () => {
        this.setState({
            alert: getLoadingAlert(),
        });
    };

    errorHandlerAlert = (error, msg) => {
        this.setState({
            alert: getErrorAlert(() => this.setState({ alert: null }), error, msg),
        });
    };

    render() {
        let currentStepScreen = null;

        if (!this.state.accountSettings) return null;

        if (this.state.currentStep === 0) {
            currentStepScreen = (
                <OrderRide1
                    nextStepClick={() => this.goToNextPage("firstStepDone")}
                    data={this.state.firstStepData}
                    accountSettings={this.state.accountSettings}
                    onChange={(name, callback) =>
                        this.onChange("firstStepData", name, callback)
                    }
                />
            );
        } else if (this.state.currentStep === 1) {
            currentStepScreen = (
                <OrderRide2
                    nextStepClick={() => this.goToNextPage("secondStepDone")}
                    previousStepClick={() => this.goToPreviousPage("firstStepDone")}
                    data={this.state.secondStepData}
                    accountSettings={this.state.accountSettings}
                    onChange={(name, callback) =>
                        this.onChange("secondStepData", name, callback)
                    }
                />
            );
        } else if (this.state.currentStep === 2) {
            currentStepScreen = (
                <OrderRide3
                    nextStepClick={() => {
                        // console.log(this.props.impersonated_partner)
                        if (this.props.impersonated_partner) {
                            const message = `Vous êtes sur le point de faire une commande au nom du marchand ${this.props.impersonated_partner.name || this.props.impersonated_partner.default_email}. Continuer?`;

                            if (window.confirm(message)) {
                                this.orderRide();
                            }
                        }else {
                            this.orderRide();
                        }
                    }}
                    previousStepClick={() => this.goToPreviousPage("secondStepDone")}
                    data={this.state.thirdStepData}
                    accountSettings={this.state.accountSettings}
                    selectedQuote={this.state.firstStepData.selectedQuote}
                    onChange={(name, callback) =>
                        this.onChange("thirdStepData", name, callback)
                    }
                />
            );
        }

        return (
            <div className={styles.orderRideWrapper}>
                {this.state.alert}
                {this.state.tracking_id ? (
                    <Redirect
                        to={{
                            pathname: "/live_tracker_list",
                            state: { tracking_id: this.state.tracking_id, newRide: (this.state.firstStepData.scheduledDatetime) ? false : true },
                        }}
                    />
                ) : (
                    ""
                )}
                <div className={styles.center}>
                    <p className={styles.title}>
                        {i18n.t("ORDER_RIDE")}
                        <div className={styles.orderRideTitleLine} />
                    </p>
                </div>
                <div className={styles.center}>
                    <div className={styles.stepEllipsesWrapper}>
                        <div>
                            <button
                                className={
                                    classnames(
                                        styles.ellipse, this.state.currentStep === 0 ? styles.ellipseFull : "")}
                                disabled={this.state.currentStep === 0}
                                onClick={() => this.setState({ currentStep: 0 })}
                            />
                            <p className={styles.stepNumber}>
                                1
                            </p>
                        </div>
                        <div className={styles.stepEllipsesLine} />
                        <div>
                            <button
                                className={
                                    classnames(
                                        styles.ellipse, this.state.currentStep === 1 ? styles.ellipseFull : "")}
                                disabled={!this.state.firstStepDone}
                                onClick={() => this.setState({ currentStep: 1 })}
                            />
                            <p className={styles.stepNumber}>
                                2
                            </p>
                        </div>
                        <div className={styles.stepEllipsesLine} />
                        <div>
                            <button
                                className={
                                    classnames(
                                        styles.ellipse, this.state.currentStep === 2 ? styles.ellipseFull : "")}
                                disabled={!this.state.firstStepDone}
                                onClick={() => this.setState({ currentStep: 2 })}
                            />
                            <p className={styles.stepNumber}>
                                3
                            </p>
                        </div>
                    </div>
                </div>
                {currentStepScreen}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        companyName: state.userData.companyName,
        selectedCommunityName: state.userData.selectedCommunityName,
        impersonated_partner: state.partners.impersonated_partner,
    };
};

export default connect(mapStateToProps)(OrderRideWrapper);