import React, { Component } from "react";
import { ControlLabel, FormControl, FormGroup, Grid } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton.jsx";
import EvaLogo from '../../assets/img/logo-noborder.png';
import NotificationSystem from 'react-notification-system';
import { Trans } from "react-i18next";
import i18n from '../../i18n';
import { loginSuccess } from '../../actions/index';
import { signin, authenticateUserPartner } from "../../helpers/apiHelper";
import { getMicrosoftAuthUrl } from "../../helpers/apiHelper";
import { store } from "../../store/index";
import { style } from "../../variables/Variables";
import TermsAndConditionsEn from "../../assets/files/mia-terms-conditions-en.pdf";
import TermsAndConditionsFr from "../../assets/files/mia-terms-conditions-fr.pdf";
import Spinner from '../../components/Spinner/Spinner';
import PhoneInput from 'react-phone-input-2';
import './LoginPage.css';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      phone: null,
      email: null,
      terms: false,
      _notificationSystem: null,
      microsoftDynamicUrlAuth: null,
      loadingLogin: false,
      errors: {
        phone: false,
        email: false,
        terms: false,
      },
      twoFactorAuthStep: false,
      twoFactorAuthToken: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeTerms = this.handleChangeTerms.bind(this);
    this.handleMicrosoftLogin = this.handleMicrosoftLogin.bind(this);
    this.handleChangeTwoFactorAuthToken = this.handleChangeTwoFactorAuthToken.bind(this);

    // This will be true if coming from SAML or other external login mechanism
    if (props.token) {
      store.dispatch(loginSuccess({
        partnerName: props.partner_name,
        userAuthKey: props.token
      })); 
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState({ cardHidden: false });
    }, 700);
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    let url = await getMicrosoftAuthUrl();
    this.setState({ microsoftDynamicUrlAuth: url.data.sso_url });

    if (this.props.error) {
      this.handleError(this.props.error);
    }
  }

  handleChangePhone(phone) {
    this.setState({ phone });
  }

  handleChangeEmail(e) {
    console.log('Event change email' , e.target.value);
    this.setState({ email: e.target.value });
  }

  handleChangeTerms(e) {
    let terms = e.target.checked;
    this.setState({ terms });
  }

  handleChangeTwoFactorAuthToken(e) {
    let twoFactorAuthToken = e.target.value;
    this.setState({ twoFactorAuthToken });
  }

  handleError = (errorMsg) => {
    this.state._notificationSystem.addNotification({
      message: <div>{i18n.t(errorMsg)}</div>,
      level: 'error',
      position: 'tc',
      autoDismiss: 3,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ errors: { phone: false, email: false, terms: false } });

    const { phone, email, terms, twoFactorAuthStep, twoFactorAuthToken } = this.state;

    if (!this.verifyForm(phone, email, terms)) {
      return;
    }
    let trimmedPhone = phone ? phone.trim() : null;
    const trimmedEmail = email.trim();

    this.setState({ loadingLogin: true });

    if (!twoFactorAuthStep) {
      const signInResponse = await signin(trimmedPhone, trimmedEmail);

      if (signInResponse.success) {
        console.log('Sign In Data', signInResponse.data);
        this.setState({ twoFactorAuthStep: true, loadingLogin: false });
      } else {
        this.handleError("LOGIN_ERROR");
        this.setState({ loadingLogin: false });
      }
    } else {
      const authResponse = await authenticateUserPartner(trimmedPhone, trimmedEmail, twoFactorAuthToken);
      console.log('authResponse', authResponse);
      if (authResponse.status === "success") {
        console.log('Authentication Data', authResponse);
        const data = {
          partnerName: authResponse.partner_name,
          userAuthKey: authResponse.bearer_token
        };
        console.log('login Success', data);
        store.dispatch(loginSuccess(data));
      } else {
        this.handleError("TWO_FACTOR_AUTH_ERROR");
      }

      this.setState({ loadingLogin: false, twoFactorAuthStep: false, twoFactorAuthToken: null });
    }
  }

  verifyForm(phone, email, terms) {
    if (!phone && !email) {
      this.handleError("NO_PHONE_EMAIL_LOGIN_ERROR");
      this.setState({ errors: { phone: true, email: true, terms: false } });
      return false;
    }

    if (phone) {
      phone = phone.trim();
      // Add phone number validation logic here
    }

    if (email) {
      email = email.trim();
      // Add email validation logic here
    }

    if (!terms) {
      this.handleError("ACCEPT_TERMS_ERROR");
      this.setState({ errors: { phone: false, email: false, terms: true } });
      return false;
    }

    return true;
  }

  handleMicrosoftLogin(terms, microsoftDynamicUrlAuth) {
    if (!this.state.terms) {
      this.handleError("ACCEPT_TERMS_ERROR");
      this.setState({ errors: { email: false, password: false, terms: true } });
      return false;
    } else {
      var win = window.open(microsoftDynamicUrlAuth, "_self");
      win.focus();
    }
  }
  handleEmailFocus = () => {
    if (this.state.phone !== null) {
      this.setState({ phoneTemp: this.state.phone, phone: null });
    }
  }

  handleEmailBlur = () => {
    if (this.state.email === '') {
      this.setState({ phone: this.state.phoneTemp, phoneTemp: null });
    }
  }

  handlePhoneFocus = () => {
    if (this.state.email !== '') {
      this.setState({ emailTemp: this.state.email, email: '' });
    }
  }

  handlePhoneBlur = () => {
    if (this.state.phone === null) {
      this.setState({ email: this.state.emailTemp, emailTemp: '' });
    }
  }
  render() {
    let pdfFile = TermsAndConditionsEn;
    if (i18n.language.startsWith('fr')) {
      pdfFile = TermsAndConditionsFr;
    }

    let microsoftAuthLink = null;
    if (this.state.microsoftDynamicUrlAuth != null) {
      microsoftAuthLink = (
        <a href="#" onClick={() => this.handleMicrosoftLogin(this.state.terms, this.state.microsoftDynamicUrlAuth)} style={{
          background: '#0078D4',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textDecoration: 'none',
          fontWeight: 'bold'
        }}>
          <Trans>MICROSOFT_LOG_IN</Trans>
        </a>
      );
    }

    return (
      <Grid>
        <NotificationSystem ref="notificationSystem" style={style} />
        <div className="center-screen">
          <form onSubmit={this.handleSubmit}>
            <Card
              hidden={this.state.cardHidden}
              textCenter
              title={
                <div>
                  <img src={EvaLogo} alt={"eva-logo"} className="logo-img" />
                  <br />
                  Eva
                  <div className="yellow-line" />
                </div>
              }
              content={
                <div className="login-form">
                  {!this.state.twoFactorAuthStep ? (
                    <>
                      <FormGroup>
                        <ControlLabel>
                          <Trans>Phone</Trans>
                        </ControlLabel>
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <PhoneInput
                          country={'ca'}
                          masks={{"ci": '.. .. .. .. ..'}}
                          value={this.state.phone}
                          onChange={this.handleChangePhone}
                          onFocus={this.handlePhoneFocus}
                          onBlur={this.handlePhoneBlur}
                          disabled={this.state.email}
                          className={this.state.errors.phone ? "error-field" : ""}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel><Trans>Email</Trans></ControlLabel>
                        <FormControl
                          type="email"
                          value={this.state.email || ''}
                          onChange={this.handleChangeEmail}
                          onFocus={this.handleEmailFocus}
                          onBlur={this.handleEmailBlur}
                          disabled={this.state.phone !== null}
                          className={this.state.errors.email ? "error-field" : ""}
                        />
                      </FormGroup>
                      <div width="100%" className="terms-parent">
                        <a href={pdfFile} target="_blank" style={{ cursor: "pointer" }}>
                          <ControlLabel className="terms-label">
                            <Trans>ACCEPT_TERMS</Trans>
                          </ControlLabel>
                        </a>
                        <div className="terms-control">
                          <input
                            type="checkbox"
                            onChange={this.handleChangeTerms}
                            style={{ margin: 'auto', verticalAlign: 'middle', position: 'relative', top: "13px" }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <FormGroup>
                      <ControlLabel><Trans>TWO_FACTOR_AUTH_TOKEN_LABEL</Trans></ControlLabel>
                      <FormControl
                        type="text"
                        value={this.state.twoFactorAuthToken}
                        onChange={this.handleChangeTwoFactorAuthToken}
                        placeholder={i18n.t("TWO_FACTOR_AUTH_TOKEN_PLACEHOLDER")}
                      />
                    </FormGroup>
                  )}
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CustomButton
                      type="submit"
                      bsStyle="warning"
                      style={{ width: 100, display: "flex", justifyContent: "space-evenly" }}
                      fill
                      wd
                    >
                      {this.state.twoFactorAuthStep ? (
                        <Trans>TWO_FACTOR_AUTH_BUTTON_LABEL</Trans>
                      ) : (
                        <Trans>LOGIN_BUTTON_LABEL</Trans>
                      )}
                      {this.state.loadingLogin && <Spinner />}
                    </CustomButton>
                  </div>
                  <br /> <br />
                  <Trans>OR_LOGIN</Trans>
                  <br /><br />
                  {microsoftAuthLink}
                </div>
              }
              ftTextCenter
            />
          </form>
        </div>
      </Grid>
    );
  }
}

export default LoginPage;