// language ressources 
export const resources = {
  en: {
    translation: {
      //LoginPage
      "LOGIN_EMAIL_LABEL": "Email",
      "TWO_FACTOR_AUTH_TOKEN_PLACEHOLDER": "#######",
      "TWO_FACTOR_AUTH_BUTTON_LABEL": "Enter",
      "LOGIN_PHONE_LABEL": "Phone",
      "TWO_FACTOR_AUTH_TOKEN_LABEL": "Verification Code",
      "PASSWORD": "Password",
      "LOGIN_BUTTON_LABEL": "Log In",
      "LOGIN_ERROR": "Error while logging in. Please verify your login information.",
      "EMAIL_LOGIN_ERROR": "Enter a valid email.",
      "NO_EMAIL_LOGIN_ERROR": "Please enter your email.",
      "NO_PASSWORD_LOGIN_ERROR": "Please enter your password.",
      "LOGOUT": "Log out",
      "ACCEPT_TERMS": "Agree to terms and conditions",
      "ACCEPT_TERMS_ERROR": "You must agree to the Eva terms & conditions before submitting.",

      //Side Bar
      "WELCOME_SIDEBAR": "Welcome, ",
      "HOME": "Home",
      "DASHBOARD": "Dashboard",
      "LIVETRACKER": "Live Tracker",
      "BUSINESS": "Business",
      "ATTENTION": "Attention!",

      //Home
      "TRACK_YOUR_ORDER": "TRACK YOUR ORDERS",
      "HOME_HEADER_1": "REAL TIME",
      "HOME_HEADER_2": " ORDERING & TRACKING",
      "HOME_JOIN_COOP": "JOIN THE EVA REVOLUTION!",

      //Live Tracker
      "DRIVER": "Driver",
      "DESTINATION": "Destination",
      "PICKUP": "Pickup",
      "CLOSE": "Close",
      "ON_WAY_TOWARDS_RIDER_DESTINATION": "On the way to rider member's destination",
      "ON_WAY_TOWARDS_PACKAGE_DESTINATION": "On the way to the delivery address",
      "OUT_FOR_DELIVERY": "Out for delivery",
      "ON_WAY_TOWARDS_PICKUP": "On the way to the pickup location",
      "ON_WAY_TOWARDS_RIDER": "On the way to pickup the rider member",
      "PICKING_UP_PACKAGE": "Waiting for the order",
      "PICKING_UP_RIDER": "Arrived at rider member's location",
      "ARRIVED_RIDER_DESTINATION": "Arrived at rider member's destination",
      "ARRIVED_PACKAGE_DESTINATION": "Delivering the order",
      "DELIVERY_ESTIMATION": "We estimate that your order will be delivered <0>around <0/>",
      "RIDE_ESTIMATION": "We estimate that the rider member will arrive at their destination at <0>around <0/>",
      "NO_LANDSCAPE_1": "This site works best in ",
      "PORTRAIT": "portrait",
      "NO_LANDSCAPE_2": " mode",
      "ETA": "ETA",
      "MINS": "mins",
      "YES": "Yes",
      "NO": "No",
      "CANCEL_RIDE": "Cancel",
      "CANCEL_SUCCESS": "The ride has successfully been cancelled! Please allow up to a few minutes for the changes to be reflected in the app.",
      "CANCEL_ERROR": "There was an error processing the cancel request. Please verify that the ride is live!",
      "EVA_RIDESHARE_APP": "Eva is also a ride-share app to get from point A to point B.",
      "JOIN_THE_MOVEMENT": "Download it and join the movement!",

      //Error Page
      "ERROR": "Error",
      "WRITE_TO": "Join Us : ",
      "OPS": "Oops! Looking for a driver member.",
      "FOUND_PROBLEM": "The driver member canceled your ride. We're looking for another one.",
      "ENSURANCE_PROBLEM": "Rest assured that we are working hard to remediate the situation!",
      "SPEAK_TO_REPRESENTATIVE": "To speak to a representative, please contact us at ",

      //Rider Cancel Page
      "RIDER_CANCEL_TITLE": "Ride cancelled.",
      "RIDER_CANCEL_BODY": "The selected ride was cancelled by you!",
      "CAN_ORDER_RIDE": "Click on the button below to order a new ride.",
      "NO_DRIVER_FOUND": "There was no driver available to get this call.",

      //Coop cancel page
      "COOP_CANCEL_TITLE": "Ride Cancelled.",
      "COOP_CANCEL_BODY": "The selected ride was cancelled by Eva!",

      //Looking for driver
      "LOOKING_FOR_DRIVER": "We are looking for a driver for your ride.",
      "CONTACTING_DRIVERS": "We are contacting drivers close to you.",
      "STILL_CONTACTING_DRIVERS": "Hang tight! We are still looking for a driver close to you.",
      "NOT_LONG": "This should not take long.",
      "STILL_NOT_LONG": "Please allow us some more time to find the nearest driver available.",
      "FOR_INQUIRIES": "For any inquiry, please contact a representative at ",
      "REQUEST_START": "We're taking care of your transportation request.",

      // expected times
      "EXPECTED_DELIVERY_TIME": "{{date}} at {{time}}",
      "EXPECTED_DELIVERY_TIME_MINUTES": "Your driver is expected to arrive in <0>{{minutes}} minutes</0>.",
      "EXPECTED_DELIVERY_TIME_MINUTES_DELIVERY": "Your delivery is expected in <0>{{minutes}} minutes</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS": "Your driver is expected to arrive in <0>{{hours}} hours</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS_DELIVERY": "Your delivery is expected in <0>{{hours}} hours</0>.",
      "EXPECTED_DELIVERY_TIME_DAYS": "Your driver is expected to arrive in <0>{{days}} days</0>.",
      "EXPECTED_DELIVERY_TIME_DAYS_DELIVERY": "Your delivery is expected in <0>{{days}} days</0>.",
      "EXPECTED_DELIVERY_TIME_OVER": "Your driver is on the way!",
      "EXPECTED_DELIVERY_TIME_OVER_DELIVERY": "Your delivery is on the way!",

      //Invalid Link
      "INVALID_LINK": "The link provided is not valid",
      "VALID_URL_CHECK": "Make sure the URL is correct.",
      "FOR_MORE_INFO": "For more information, please contact us at ",

      //Completed
      "COMPLETED": "Ride Completed",
      "STATUS_INFO": "The ride has successfully been completed!",

      //Order ride
      "ORDER_RIDE": "Order a ride",
      "DELIVERY": "Delivery",
      "BULK": "Bulk Delivery",
      "RIDESHARING": "Ridesharing",
      "CONTINUE": "Continue",
      "RIDE_DETAILS": "Ride Details",
      "OPTIONAL": "Apt. number, floor, etc. (optional)",
      "OPTIONAL_RIGHT_NOW": "Right Now",
      "SHOW_BY_DRIVER_ROUTE": "Show by driver route",
      "SHOW_BY_CUSTOMER_RIDE": "Show by customer orders",
      "ARE_YOU_SURE_CANCEL": "Are you sure you want to cancel the ride?",
      "ORDER_NUMBER_REFERENCE": "Order reference number",
      "PICKUP_PHONE": "Pick Up Location Phone Number",
      "COMPANY_NAME": "Pick Up Company Name",
      "TIP": "Tip",
      "APARTMENT_NUMBER": "Apt. Number",
      "ADDRESS_PLACEHOLDER": "e.g.: 24 Mont-Royal Street",
      "FIRST_NAME_PLACEHOLDER": "e.g.: Sally",
      "LAST_NAME_PLACEHOLDER": "e.g.: Tremblay",
      "EMAIL_PLACEHOLDER": "e.g: sallytremblay@eva.coop",
      "TELEPHONE_PLACEHOLDER": "+1 (514) 123-4567",
      "TEL_EXTENSION_PLACEHOLDER": "Ext. (optional)",
      "PLANNED_DATE": "Schedule your ride",
      "DRIVER_WILL_ARRIVE_THAT_TIME": "The driver will arive at the start location around this time",
      "CLIENT_TITLE": "CLIENT",
      "COMPANY_STEP_TITLE": "COMPANY (optional)",
      "FIRST_NAME": "First name",
      "LAST_NAME": "Last name",
      "EMAIL": "Email",
      "TELEPHONE": "Telephone",
      "SENDING_EMAIL_SMS_MESSAGE": "An email and an SMS will be sent to the client to follow the ride.",
      "SENDING_COMPANY_INFO_MESSAGE": "This information will be sent to the driver in order to locate the place for pickup.",
      "NEXT": "Next",
      "PREVIOUS": "Previous",
      "LOADING": "Loading",
      "TIP_PLACEHOLDER": "Tip",
      "ORDER_RIDE_CONFIRM": "Order ride",
      "ELECTRIC_PLACEHOLDER": "Electric",
      "NOTE": "Note",
      "CUSTOMER_NOTE_PLACEHOLDER": "Instructions for delivery at the customer. (optional)",
      "PICKUP_NOTE_PLACEHOLDER": "Instructions for pickup at the store. (optionnel)",
      "CANNABIS": "Cannabis",

      //Ride Preview
      "FROM_ADDRESS": "From Address",
      "TO_ADDRESS": "To Address",
      "SENDING_REQUEST": "Sending request...",
      "BRAVO": "Bravo!",
      "CONFIRM": "Confirm",
      "CANCEL": "Cancel",
      "TRACKING_ID_CONFIRMATION": "Ride has been called! Please allow a few minutes for the tracking page to update. Tracking ID:",
      "START_LOCATION": "Start location",
      "RIDE_SHARING": "Ride Sharing",
      "CONFIRM_ORDER": "Confirm order",
      "SELECT_RIDE_TYPE": "Select your ride type",
      "NO_DRIVERS": "Unavailable service.",
      "TRY_AGAIN_LATER": "We are deeply sorry for this, we are lacking drivers right now. Please try again later.",
      "ORDER_ERROR": "An error has occured when ordering the ride.",

      //Mia Rides List
      "DATE": "Date",
      "STATUS": "Status",
      "CUSTOMER_FIRST_NAME": "Customer First Name",
      "CUSTOMER_LAST_NAME": "Customer Last Name",
      "CUSTOMER_PHONE": "Customer Phone",
      "CUSTOMER_EMAIL": "Customer Email",
      "RIDE_TYPE": "Ride Type",
      "NO_LIVE_RIDES": "No live rides yet!",
      "SCHEDULED_DATE": "Dispatch Time",
      "ONSITE_DATE": "Onsite Datetime",
      "DELIVERED_BY_DATE": "Delivered By",
      "ORDER_NUMBER": "Order Number",
      "IS_STARTED": "Is started ?",
      "IS_EN_ROUTE": "Driver Assigned ?",
      "NUMBER_CUSTOMER_RIDES": "Number of associated orders",
      "STEP_ORDER": "Ordre dans la route",

      //Ride request status
      "SUCCESS": "Success",
      "NO_DRIVER": "No Driver",
      "CANCELLED_BY_COMMUNITY": "Cancelled by Eva",
      "CANCELLED_BY_RIDER": "Cancelled by rider",
      "CANCELLED_BY_DRIVER": "Cancelled by driver",
      "NOT_YET_ACCEPTED": "Looking for driver",
      "LIVE": "In process",
      "LIVE_OTHER_STEP": "In process",

      //Ride History
      "RIDE_HISTORY": "Ride History",
      "RIDE_SERVICE_NAME": "Ride service type",
      "UNKNOWN_CANCELLATION": "Cancelled",
      "RIDE_SHARING_SERVICE": "Ride Sharing",
      "DELIVERY_SERVICE": "Delivery",
      "CANNABIS_SERVICE": "Cannabis",
      "BULK_SERVICE": "Bulk delivery",
      "ERROR_ALERT": "An error occured",
      "TABLE_API_ERROR_1": "There was a problem getting the data.",
      "TABLE_API_ERROR_2": "Please try again later.",
      "FAILED": "Failed",

      "ALL": "All",
      "IN_RIDE_STATUS": "In Ride",
      "SCHEDULED_STATUS": "Scheduled",
      "DISPATCHING_STATUS": "Dispatching",
      "TRANSIT_PICKUP": "In Transit Pickup",
      "ARRIVED_PICKUP": "Arrived Pickup",
      "TRANSIT_DESTINATION": "In Transit Destination",
      "ARRIVED_DESTINATION": "Arrived Destination",
      "RETURNING": "Returning",
      "CANCELLED": "Cancelled",

      //Account Settings
      "ACCOUNT_SETTINGS": "Account Settings",
      "SEND_SMS_NOTI_TITLE": "Send SMS Notifications?",
      "SEND_EMAIL_NOTI_TITLE": "Send E-mail Notifications?",
      "SEND_SMS_NOTI_SUBTITLE": "Send tracking id to clients via SMS",
      "SEND_EMAIL_NOTI_SUBTITLE": "Send tracking id to clients via E-mail",
      "EMAIL_TITLE": "Company E-mail Address:",
      "EMAIL_SUBTITLE": "New E-mail",
      "ADDRESS_TITLE": "Company Address:",
      "ADDRESS_SUBTITLE": "Address",
      "SEND_SMS_TRACKING": "Send Tracking by SMS",
      "SEND_EMAIL_TRACKING": "Send Tracking by Email",
      "COMPANY_APARTMENT_NUMBER": "Company Apartment Number",
      "SAVE": "Save",
      "RESET": "Reset",

      //Scheduled Ride View
      "SCHEDULED_RIDE": "Scheduled Ride",
      "SCHEDULE_RIDE": "Ride Time",
      "SCHEDULED_FOR_LATER": "Scheduled Ride",
      "SCHEDULED_RIDE_BODY": "This ride is scheduled to happen in:",
      // Table
      "ROW_PER_PAGE": "ROWS PER PAGE",

      //Countdown
      "DAYS": "Days",
      "HOURS": "Hours",
      "MINUTES": "Minutes",
      "SECONDS": "Seconds",

      "TOTAL_COST": "Total Ride Charge",
      // Customize Email Default
      'EMAIL_TRACKING_LINK': 'Tracking Link: ',
      'EMAIL_THANK_YOU': 'We appreciate you being part of Eva members!',
      'EMAIL_DATE_LINE': "Date:",
      'EMAIL_TRACK_NOW': "Track!",
      'PREVIEW': 'Preview',
      'SUBJECT': 'Subject',
      'LOGO_URL': 'Logo URL',
      'MESSAGE_BODY': 'Message Body',
      'CONTACT_INFO': 'Contact Info',

      // Successful ride tracking email
      'EMAIL_TITLE_SUCCESS': 'Live Ride Tracking',
      "EMAIL_MESSAGE_SUCCESS": 'Dear member,\nThank you for ordering a trip with Eva!\nThanks for using the Eva app! Please click on the link to track your order in real time.',
      'EMAIL_MESSAGE1_SUCCESS': 'Dear member,',
      'EMAIL_MESSAGE2_SUCCESS': 'Thank you for ordering a trip with Eva!',
      'EMAIL_MESSAGE3_SUCCESS': 'Thanks for using the Eva app! Please click on the link to track your order in real time.',
      'EMAIL_SUBJECT_SUCCESS': 'Live Ride Tracking',

      // Failed ride tracking email
      'EMAIL_MESSAGE1_FAILED': 'Dear member,',
      'EMAIL_MESSAGE2_FAILED': 'Thank you for ordering a trip with Eva!',
      'EMAIL_MESSAGE3_FAILED': 'Our service is currently unavailable. You will be notified as soon as your order is sent!',
      'EMAIL_SUBJECT_FAILED': 'Unavailable Service',

      //404
      "404_TITLE": "404 - Page Not Found",
      "404_CLICK": "Click ",
      "404_HERE": "here",
      "404_RETURN": " to return to the home page.",

      // Terms and conditions
      'TERMS_AND_CONDITIONS': 'Terms and Conditions',

      //New Tracker 
      "FR": "Français",
      "EN": "English",
      "MORE_INFO_AT": "More information at ",
      "FOLLOW_THE_RIDE": "Your transport",
      "GET_APP": "Download App!",
      "COMING_SOON": "Coming Soon",
      "SEND": "Send",
      "BUSINESS_NAME": "Business Name",
      "TWO_FACTOR_AUTH_ERROR": "There was an error with the two-factor authentication. Please try again.",
      "ORDER_LATE": "Order is late",
      "Phone": "Phone",
      "ORDER_DAMAGED": "Order is damaged",
      "ORDER_NOT_DELIVERED": "Order was not delivered",
      "ORDER_LATE_MSG": "We are sorry to hear about the late order.",
      "ORDER_DAMAGED_MSG": "We are sorry to hear about the damaged order.",
      "ORDER_NOT_DELIVERED_MSG": "We are sorry to hear that your order was not delivered.",
      "DRIVER_HASNT_ARRIVED": "The driver has not arrived",
      "DRIVER_HASNT_ARRIVED_MSG": "We are sorry to hear that the driver has not arrived.",
      "DRIVER_ISSUES": "Report Driver",
      "DRIVER_ISSUES_MSG": "We are sorry that there has been a problem with your driver. In order for you to provide a more detailed message, please click on the \"Other\" option.",
      "CLICK_FOR_CONTACT": "Please click on the following button to alert Eva, and one of our representatives will contact you shortly!",
      "ALERT_COOP": "Alert Eva",
      "CHANGE_DESTINATION": "Change the destination",
      "REQUEST_REFUND": "Request a refund",
      "HELP": "Help",
      "OTHER": "Other",
      "THANK_YOU": "Thank you!",
      "MESSAGE_SENT": "Your message has been sent.",
      "MESSAGE_ERROR": "There was a problem sending your message.",
      "TRY_AGAIN_LATER_2": "Please try again later!",
      "ITINERARY": "Itinerary",
      "DOWNLOAD_OUR_APPS": "Download our app!",
      "DRIVER_FOUND": "We have found a driver for you!",
      "STORE_CANCELLED": "The store cancelled the ride.",
      "DRIVER_CANCELLED": "The driver member cancelled the ride. We are looking for a new driver for your order.",
      "DRIVER_CANCELLED_NO_RE_DISPATCH": "The driver member cancelled the ride.",
      "COOP_CANCELLED": "An operator has cancelled the ride.",
      "PACKAGE_DELIVERED": "The ride was completed the ",
      "AT": "at",
      "ORDER_STATUS": "Track Your Order",
      "RIDE_STATUS": "Follow Your Ride",
      "SCHEDULED": "Scheduled",
      "RIDE": "Ride",
      "SCHEDULED_IN": "The ride is scheduled to happen in",
      "SCHEDULED_ON": "The ride is scheduled to happen on",
      "SCHEDULED_ON_DELIVER_BY": "This scheduled delivery will arrive by",
      "SCHEDULED_BETWEEN": "between",
      "SCHEDULED_AND": "and",
      "JOIN_US": "Contact us: ",
      "SUCCESS_RIDE_POPUP": "Thanks for supporting Eva! Download our app for ride-sharing and become part of the solution alongside 50,000 members!",
      "LINK_PROVIDED": "The link provided is",
      "NOT_VALID": "invalid",
      "URL_CORRECT": "Make sure the URL is correct.",
      "ARE_YOU": "Are you ",
      "SURE": "sure",
      "WANT_TO_CANCEL": "want to cancel?",
      "MIA_PRICE_DISCLAIMER": "The quoted price includes government subsidies, airport fees and other charges. Taxes are not included.",
      "POWERED_BY_EVA": "Powered by Eva",
      "CONTENT_DISCLAIMER": "*Please note that, as a delivery carrier, we are not liable for erroneous orders.",

      //Colours
      "GREEN": "Green",
      "RED": "Red",
      "BLUE": "Blue",
      "GREY": "Grey",
      "GRAY": "Gray",
      "BLACK": "Black",
      "INDIGO": "Indigo",
      "ORANGE": "Orange",
      "YELLOW": "Yellow",
      "PURPLE": "Purple",
      "WHITE": "White",
      "BROWN": "Brown",
      "PINK": "Pink",
      "CITY": "City",
      "PROOF_OF_CUSTOMER_FAIL": "Customer verification failed",
      "PROOF_OF_ADDRESS_FAIL": "Address verification failed",
      "BILLED_MINUTES": "Duration",
      "BILLED_DISTANCE": "Distance",
      "IMPORT_ORDERS": "Import Orders",
      "DRAG_DROP_FILE": "Drag and drop",
      "CLICK_TO_UPLOAD_FILE": "or click to upload a file",
      "FILE_UPLOAD_DISCLAIMER": "Up to {{sizeInMB}}MB in size, accepted formats: {{acceptedFormats}}",
      "FILE_TOO_BIG": "The file is too big.",
      "NO_PHONE_EMAIL_LOGIN_ERROR": "Please enter your email or phone number.",
      "SELECTED_FILE": "Selected file: ",
      "ROUTE_FILE": "Route file",
      "IMPORT_ORDERS_SUCCESS_TITLE": "Orders imported successfully!",
      "WHITE_LABEL": "Personalization",
      "WHITE_LABEL_DELETE_TITLE": "Delete style",
      "WHITE_LABEL_DELETE_BODY": "Are you sure you want to delete this style? This action cannot be undone.",
      "WHITE_LABEL_DELETE_SUCCESS_TITLE": "Style deleted!",
      "WHITE_LABEL_ACTIVATE_TITLE": "Activate style",
      "WHITE_LABEL_ACTIVATE_BODY": "Are you sure you want to activate this style? All your customers will see this on their tracking links.",
      "WHITE_LABEL_ACTIVATE_SUCCESS_TITLE": "Style activated!",
      "WHITE_LABEL_DEACTIVATE_TITLE": "De-activate style",
      "WHITE_LABEL_DEACTIVATE_BODY": "Are you sure you want to de-activate this style? All your customers will see the original Eva tracking link.",
      "WHITE_LABEL_DEACTIVATE_SUCCESS_TITLE": "Style de-activated!",
      "WHITE_LABEL_NO_TRACKING_STYLES": "No style yet.",
      "WHITE_LABEL_CREATE_NEW_STYLE": "Create new style",
      "WHITE_LABEL_NO_STLYE_FOUND_TITLE": "No style found",
      "WHITE_LABEL_NO_STLYE_FOUND_BODY": "There is no style that matches the given ID.",
      "WHITE_LABEL_RESET_TITLE": "Reset form",
      "WHITE_LABEL_RESET_BODY": "Are you sure you want to reset the current style to default?",
      "WHITE_LABEL_SAVE_SUCCESS_TITLE": "Style saved!",
      "WHITE_LABEL_SAVE_VALIDATION_TITLE": "Error",
      "WHITE_LABEL_SAVE_VALIDATION_BODY": "An alias and a description are required to save a style.",
      "NO_INVOICES": "No invoices",
      "ALIAS": "Alias",
      "DESCRIPTION": "Description",
      "CREATED_AT": "Created at",
      "UPDATED_AT": "Updated at",
      "ACTIONS": "Actions",
      "ACTIVATE": "Activate",
      "DEACTIVATE": "De-activate",
      "DELETE": "Delete",
      "EDIT": "Edit",
      "INFORMATION": "Information",
      "OPTIONS": "Options",
      "PRIMARY_COLOR": "Primary Color",
      "SECONDARY_COLOR": "Secondary Color",
      "BACKGROUND_COLOR": "Background Color",
      "TEXT_COLOR": "Text Color",
      "TITLE_COLOR": "Title Color",
      "HEADER_COLOR": "Header Color",
      "LINE_1_COLOR": "Line 1 Color",
      "LINE_2_COLOR": "Line 2 Color",
      "LOGO_REDIRECTION": "Logo redirection",
      "LOGO_ICON": "Logo icon",
      "CAR_ICON": "Car icon",
      "CAR_ICON_NIGHT": "Car icon night",
      "CAR_ICON_WIDTH": "Car icon width",
      "CAR_ICON_HEIGHT": "Car icon height",
      "PICKUP_ICON": "Pickup icon",
      "PICKUP_ICON_NIGHT": "Pickup icon night",
      "PICKUP_ICON_WIDTH": "Pickup icon width",
      "PICKUP_ICON_HEIGHT": "Pickup icon height",
      "DESTINATION_ICON": "Destination icon",
      "DESTINATION_ICON_NIGHT": "Destination icon night",
      "DESTINATION_ICON_WIDTH": "Destination icon width",
      "DESTINATION_ICON_HEIGHT": "Destination icon height",
      "TIME_OF_DAY": "Time of day",
      "VIEW": "View",
      "DAY": "Day",
      "NIGHT": "Night",
      "WHITE_LABEL_FILE_TOO_BIG": "File too big - max is 500kb",
      "GET_DEMO_LINK": "Get sharable demo link",
      "DRIVER_INFO_UNAVAILABLE": "The driver information is unavailable",
      "DELIVERY_SERVICE_BIKE": "Bike delivery",
      "DELIVERY_SERVICE_MOTORCYCLE": "Moto delivery",
      "DELIVERY_SERVICE_AMBULANCE": "Ambulance",
      "RIDE_SHARING_MOTO": "Moto Ridesharing",
      "DELIVERY_TRICYCLE": "Moto Tricycle Delivery",
      "RIDE_SHARING_TRICYCLE": "Moto Tricycle Ridesharing",
      "SHUTTLE": "Shuttle",
      "RIDE_SHARING_SERVICE_LUX": "Lux Ride Sharing",
      "BLOCKCHAIN_ID": "Client ID",
      "BLOCKCHAIN_ID_SUBTITLE": "Your unique identifier on the Eva blockchain",
      "ACCESS_TOKEN": "Access Token",
      "ACCESS_TOKEN_SUBTITLE": "Your token for third party integrations",
      "PROXY_ERROR": "We were unable to retrieve the phone number. Please try again.",
      "CALL": "Call",
      "PROOF": "Proof",
      "BEFORE_WE_CONTINUE": "Before we continue...",
      "PROXY_PHONE_DISCLAIMER": "This is a single use phone number, callable from ",
      "NO_ACCOUNT_MICROSOFT": "There is no Eva Business registered with this Office 365 account",
      "MICROSOFT_LOG_IN": "Log in with Microsoft",
      "OR_LOGIN": "Or",
      "CASH_PAYMENT_ORDER_AMOUNT": "Amount in cash to pay at the door for goods delivered",
      "MIA_CASH_AMOUNT_DISCLAIMER": "This is the amount in cash for the goods being transported to ask at the door by the driver.Leave nothing or 0 if you don't want to ask for cash at the door.",
      "ASK_FOR_DELIVERY_FEE": "Is the customer paying delivery fee at the door?",
      "MIA_CASH_AMOUNT_VALUE_DELIVERED_DISCLAIMER": "Check if you desire the customer to pay for the delivery fees at the door in cash.",

      // invoices
      "PAID": "Paid",
      "PAYMENT": "Payment",
      "CREDIT_CARD": "Credit Card",
      "MANUAL": "Manual payment",
      "FROM_INVOICE": "From",
      "TO_INVOICE": "To",
      "INVOICE_NUMBER": "Invoice Number",
      "INVOICE_FILES": "Files",


      "SAVE_CARD": "Save Card",
      "CARD_IS_SET": "Your card is set. No need of any other action unless you received a failed error Email, where you should input another card.",
      "SET_CARD": "Set or modify credit card",
      "SAVE_CARD_SUCCESS": "Your card has been successfully saved, payment will be attempted the next day if you had a pending invoice.",
      "SAVE_CARD_FAILURE": "Your card was denied or needs further authentication. Try another card and/or complete the authentication flow properly.",
      "PAYMENT_FOOTNOTE_1": "By using our payment initiation services, you agree that you give us the necessary permission for us to process payments on your behalf.",
      "PAYMENT_FOOTNOTE_2": "By using our services, you agree to a monthly frequency of payments, unless communicated otherwise.",
      "PAYMENT_FOOTNOTE_3": "By using our services, you agree that the payment amount will be determined based on the usage of our services, terms agreed upon and communicated to you prior to processing via Email."
    },
  },
  fr: {
    translation: {

      //LoginPage
      "LOGIN_EMAIL_LABEL": "Courriel",
      "LOGIN_PHONE_LABEL": "Téléphone",
      "TWO_FACTOR_AUTH_TOKEN_PLACEHOLDER": "#######",
      "TWO_FACTOR_AUTH_BUTTON_LABEL": "Entrer",
      "TWO_FACTOR_AUTH_TOKEN_LABEL": "Code de vérification",
      "PASSWORD": "Mot de passe",
      "LOGIN_BUTTON_LABEL": "Se connecter",
      "LOGIN_ERROR": "Erreur lors de la connexion. Vérifier votre addresse courriel et mot de passe.",
      "EMAIL_LOGIN_ERROR": "Entrer une addresse courriel valide.",
      "NO_EMAIL_LOGIN_ERROR": "Veuiller entrer votre addresse courriel.",
      "NO_PASSWORD_LOGIN_ERROR": "Veuiller entrer votre mot de passe.",
      "LOGOUT": "Déconnexion",
      "ACCEPT_TERMS": "Accepter les termes et conditions",
      "ACCEPT_TERMS_ERROR": "Vous devez accepter les conditions d'utilisation d'Eva avant d'envoyer la requête.",

      //Side Bare
      "WELCOME_SIDEBAR": "Bienvenue, ",
      "HOME": "Accueil",
      "DASHBOARD": "Tableau de bord",
      "LIVETRACKER": "Suivi De Commande",
      "BUSINESS": "Entreprise",
      "ATTENTION": "Attention!",

      //Home
      "TRACK_YOUR_ORDER": "SUIVRE VOS COMMANDES",
      "HOME_HEADER_1": "SUIVI ET COMMANDE",
      "HOME_HEADER_2": " DE TRAJET EN TEMPS RÉEL",
      "HOME_JOIN_COOP": "EMBARQUEZ DANS LA RÉVOLUTION EVA AVEC NOUS!",

      //Live Tracker
      "DRIVER": "Conducteur",
      "DESTINATION": "Destination",
      "PICKUP": "Lieu de ramassage",
      "CLOSE": "Fermer",
      "ON_WAY_TOWARDS_RIDER_DESTINATION": "En route vers la destination du membre passager",
      "ON_WAY_TOWARDS_PACKAGE_DESTINATION": "En route vers l'adresse de livraison",
      "OUT_FOR_DELIVERY": "Sortie pour la livraison",
      "ON_WAY_TOWARDS_PICKUP": "En route pour ramasser la commande",
      "ON_WAY_TOWARDS_RIDER": "En route vers l'emplacement du membre passager",
      "PICKING_UP_PACKAGE": "En attente de la commande",
      "PICKING_UP_RIDER": "Arrivé à l'emplacement du membre passager",
      "ARRIVED_RIDER_DESTINATION": "Arrivé à destination",
      "ARRIVED_PACKAGE_DESTINATION": "En cours de livraison",
      "DELIVERY_ESTIMATION": "Nous estimons que la commande sera livrée à <0>environ <0/>",
      "RIDE_ESTIMATION": "Nous estimons que le membre passager arrivera à destination à <0>environ <0/>",
      "NO_LANDSCAPE_1": "This site works best on ",
      "PORTRAIT": "portrait",
      "NO_LANDSCAPE_2": " mode",
      "ETA": "Estimation",
      "MINS": "mins",
      "YES": "Oui",
      "NO": "Non",
      "CANCEL_RIDE": "Annuler",
      "CANCEL_SUCCESS": "Le trajet a été annulé avec succès! Veuillez accorder quelques minutes pour que les modifications soient reflétées dans l'application.",
      "CANCEL_ERROR": "Une erreur s'est produite lors de l'annulation de la commande.",
      "EVA_RIDESHARE_APP": "Eva est aussi une application de covoiturage pour se déplacer d'un point A à un point B.",
      "JOIN_THE_MOVEMENT": "Téléchargez-là et embarquez avez nous !",

      //Error Page
      "ERROR": "Erreur",
      "WRITE_TO": "Nous joindre : ",
      "OPS": "Le membre conducteur a annulé. En recherche de conducteur ...",
      "FOUND_PROBLEM": "Le membre conducteur a annulé le transport. Nous en recherchons présentement un autre.",
      "ENSURANCE_PROBLEM": "Soyez assurés que nous travaillons dur pour remédier à la situation!",
      "SPEAK_TO_REPRESENTATIVE": "Pour parler à un représentant, veuillez nous contacter au ",

      //Rider cancel page
      "RIDER_CANCEL_TITLE": "Course annulée.",
      "RIDER_CANCEL_BODY": "Le trajet sélectionné a été annulé par vous!",
      "CAN_ORDER_RIDE": "Cliquez sur le bouton ci-dessous pour commander un nouveau trajet.",
      "NO_DRIVER_FOUND": "Il n'y avait pas de conducteur disponible pour recevoir cet appel.",

      //Coop cancel page
      "COOP_CANCEL_TITLE": "Course annulée.",
      "COOP_CANCEL_BODY": "Le trajet sélectionné a été annulé par votre co-op!",

      //Looking for driver
      "LOOKING_FOR_DRIVER": "Nous recherchons un conducteur pour votre course.",
      "CONTACTING_DRIVERS": "Nous contactons des conducteurs proches de vous.",
      "NOT_LONG": "Cela ne devrait pas tarder!",
      "FOR_INQUIRIES": "Pour toute demande, veuillez contacter un représentant à ",
      "STILL_CONTACTING_DRIVERS": "Nous recherchons toujours un conducteur proche de vous.",
      "STILL_NOT_LONG": "Veuillez nous laisser un peu plus de temps pour trouver le conducteur le plus proche disponible.",
      "REQUEST_START": "Nous traitons votre demande de transport.",

      // expected times
      "EXPECTED_DELIVERY_TIME": "Le {{date}} à {{time}}",
      "EXPECTED_DELIVERY_TIME_MINUTES": "Votre conducteur est prévu d'arriver d'ici <0>{{minutes}} minutes</0>.",
      "EXPECTED_DELIVERY_TIME_MINUTES_DELIVERY": "Votre livraison est prévue d'ici <0>{{minutes}} minutes</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS": "Votre conducteur est prévu d'arriver d'ici <0>{{hours}} heures</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS_DELIVERY": "Votre livraison est prévue d'ici <0>{{hours}} heures</0>.",
      "EXPECTED_DELIVERY_TIME_DAYS": "Votre conducteur est prévu d'arriver d'ici <0>{{days}} jours.</0>",
      "EXPECTED_DELIVERY_TIME_DAYS_DELIVERY": "Votre livraison est prévue d'ici <0>{{days}} jours.</0>",
      "EXPECTED_DELIVERY_TIME_OVER": "Votre conducteur est en route !",
      "EXPECTED_DELIVERY_TIME_OVER_DELIVERY": "Votre livraison est en route !",

      //Invalid Link
      "INVALID_LINK": "Le lien fourni n'est pas valide",
      "VALID_URL_CHECK": "Assurez-vous que l'URL est correcte.",
      "FOR_MORE_INFO": "Pour plus d'informations, veuillez nous contacter ",

      //Completed
      "COMPLETED": "Terminé",
      "STATUS_INFO": "Le trajet est terminé avec succès!",

      // Order ride
      "ORDER_RIDE": "Commander un transport",
      "START_LOCATION": "Point de départ",
      "RIDE_SHARING": "Co-voiturage",
      "DELIVERY": "Livraison",
      "BULK": "Livraison par fourgonnette",
      "RIDESHARING": "Transport de personne",
      "CONTINUE": "Continuer",
      "SELECT_RIDE_TYPE": "Quel type de transport avez vous besoin?",
      "CONFIRM_ORDER": "Confirmer la commande",
      "RIDE_DETAILS": "Détails du trajet",
      "OPTIONAL": "Numéro d'appartment, étage, etc. (optionnel)",
      "OPTIONAL_RIGHT_NOW": "Maintenant",
      "SHOW_BY_DRIVER_ROUTE": "Suivre par route conducteur",
      "SHOW_BY_CUSTOMER_RIDE": "Suivre par commande client",
      "ARE_YOU_SURE_CANCEL": "Voulez-vous vraiment annuler le trajet?",
      "ORDER_NUMBER_REFERENCE": "Numéro de la commande",
      "PICKUP_PHONE": "Téléphone du commerce",
      "Phone": "Téléphone",
      "COMPANY_NAME": "Nom du commerce",
      "TIP": "Pourboire",
      "APARTMENT_NUMBER": "Numéro d'appart.",
      "ADDRESS_PLACEHOLDER": "ex. 24 Rue Mont-Royal",
      "FIRST_NAME_PLACEHOLDER": "ex. Sally",
      "LAST_NAME_PLACEHOLDER": "ex. Tremblay",
      "EMAIL_PLACEHOLDER": "ex. sallytremblay@eva.coop",
      "TELEPHONE_PLACEHOLDER": "+1 514 xxx xxxx",
      "TEL_EXTENSION_PLACEHOLDER": "Ext. (optionnel)",
      "PLANNED_DATE": "Moment planifié du trajet",
      "DRIVER_WILL_ARRIVE_THAT_TIME": "Le conducteur va arriver au point de départ au moment sélectionné.",
      "CLIENT_TITLE": "CLIENT",
      "COMPANY_STEP_TITLE": "COMMERCE (optionnel)",
      "FIRST_NAME": "Prénom",
      "LAST_NAME": "Nom",
      "EMAIL": "Courriel",
      "TELEPHONE": "Téléphone",
      "SENDING_EMAIL_SMS_MESSAGE": "Un courriel et un SMS vous seront envoyés pour suivre votre course.",
      "SENDING_COMPANY_INFO_MESSAGE": "Ces informations serviront aux conducteurs pour vous localiser lors du ramassage.",
      "NEXT": "Suivant",
      "PREVIOUS": "Précédent",
      "LOADING": "Chargement",
      "TIP_PLACEHOLDER": "Pourboir",
      "ORDER_RIDE_CONFIRM": "Commander",
      "ELECTRIC_PLACEHOLDER": "Électrique",
      "NOTE": "Note",
      "CUSTOMER_NOTE_PLACEHOLDER": "Instructions pour la livraison chez le client. (optionnel)",
      "PICKUP_NOTE_PLACEHOLDER": "Instructions pour le ramassage en magasin. (optionnel)",
      "CANNABIS": "Cannabis",

      //Ride Preview Table
      "SENDING_REQUEST": "Envoi en cours...",
      "BRAVO": "Bravo!",
      "CONFIRM": "Confirmer",
      "CANCEL": "Annuler",
      "TRACKING_ID_CONFIRMATION": "Course a été appelé! Veuillez prévoir quelques minutes pour la mise à jour de la page de suivi. Numéro de suivi:",
      "NO_DRIVERS": "Service indisponible.",
      "TRY_AGAIN_LATER": "Nous sommes vraiment désolés des inconvénients. Nous manquons présentement de conducteurs. Vous pouvez réssayer plus tard.",
      "ORDER_ERROR": "Une erreur s'est produite lors de la commande.",

      //Mia Rides List
      "DATE": "Date",
      "STATUS": "Statut",
      "CUSTOMER_FIRST_NAME": "Prénom client",
      "CUSTOMER_LAST_NAME": "Nom client",
      "CUSTOMER_EMAIL": "E-mail client",
      "CUSTOMER_PHONE": "Téléphone client",
      "FROM_ADDRESS": "Addresse de départ",
      "TO_ADDRESS": "Addresse de destination",
      "RIDE_TYPE": "Type de trajet",
      "NO_LIVE_RIDES": "Pas de course présentement",
      "SCHEDULED_DATE": "Temps de répartition",
      "ONSITE_DATE": "Temps sur site",
      "DELIVERED_BY_DATE": "Temps livré",
      "ORDER_NUMBER": "Numéro de commande",
      "IS_STARTED": "Route Démarrée ?",
      "IS_EN_ROUTE": "Conducteur assigné?",
      "NUMBER_CUSTOMER_RIDES": "Nombre de commandes associées",
      "STEP_ORDER": "Order in Route",

      //Ride Request Status
      "SUCCESS": "Succès",
      "NO_DRIVER": "Aucun conducteur",
      "CANCELLED_BY_COMMUNITY": "Annulé par Eva",
      "CANCELLED_BY_RIDER": "Annulé par passager",
      "CANCELLED_BY_DRIVER": "Annulé par conducteur",
      "NOT_YET_ACCEPTED": "Recherche de conducteur",
      "LIVE_OTHER_STEP": "En cours",
      "LIVE": "En cours",

      //Ride History
      "RIDE_HISTORY": "Historique des courses",
      "RIDE_SERVICE_NAME": "Type de trajet",
      "UNKNOWN_CANCELLATION": "Annulation",
      "RIDE_SHARING_SERVICE": "Co-voiturage",
      "DELIVERY_SERVICE": "Livraison",
      "CANNABIS_SERVICE": "Cannabis",
      "BULK_SERVICE": "Livraison par camion",
      "ERROR_ALERT": "Une erreur s'est produite",
      "TABLE_API_ERROR_1": "Un problème est survenu lors de l'obtention des données.",
      "TABLE_API_ERROR_2": "Veuillez réessayer plus tard.",
      "FAILED": "Échoué",

      "ALL": "Tous",
      "IN_RIDE_STATUS": "En route vers ramassage",
      "SCHEDULED_STATUS": "Cédulé",
      "DISPATCHING_STATUS": "En envoi",
      "TRANSIT_PICKUP": "En route ramassage",
      "ARRIVED_PICKUP": "Arrivé ramassage",
      "TRANSIT_DESTINATION": "En route destination",
      "ARRIVED_DESTINATION": "Arrivé destination",
      "RETURNING": "En cours de retour",
      "CANCELLED": "Annulé",

      //Account Settings
      "ACCOUNT_SETTINGS": "Paramètres du compte",
      "SEND_SMS_NOTI_TITLE": "Envoyer des notifications par SMS?",
      "SEND_EMAIL_NOTI_TITLE": "Envoyer des notifications par e-mail?",
      "SEND_SMS_NOTI_SUBTITLE": "Envoyer un identifiant de suivi aux clients par SMS",
      "SEND_EMAIL_NOTI_SUBTITLE": "Envoyer un identifiant de suivi aux clients par e-mail",
      "EMAIL_TITLE": "Adresse e-mail de l'entreprise:",
      "EMAIL_SUBTITLE": "Nouveau E-mail",
      "ADDRESS_TITLE": "Adresse de l'entreprise:",
      "ADDRESS_SUBTITLE": "Adresse",
      "SEND_SMS_TRACKING": "Envoyer lien de suivi par SMS",
      "SEND_EMAIL_TRACKING": "Envoyer lien par courriel",
      "COMPANY_APARTMENT_NUMBER": "Numéro d'appartement de la société",
      "SAVE": "Sauvgarder",
      "RESET": "Réinitialiser",

      //Scheduled Ride View
      "SCHEDULED_RIDE": "Course planifiée",
      "SCHEDULE_RIDE": "Moment planifié du trajet",
      "SCHEDULED_FOR_LATER": "Course planifiée",
      "SCHEDULED_RIDE_BODY": "La course est prévue pour:",
      // Table
      "ROW_PER_PAGE": "LIGNES PAR PAGE",

      //Countdown
      "DAYS": "Jours",
      "HOURS": "Heures",
      "MINUTES": "Minutes",
      "SECONDS": "Secondes",

      "TOTAL_COST": "Coût total",
      // Customize Email Default
      'EMAIL_THANK_YOU': 'Nous apprécions votre participation à Eva!',
      "TWO_FACTOR_AUTH_ERROR": "Il y a eu une erreur avec l'authentification à deux facteurs. Veuillez réessayer.",
      "NO_PHONE_EMAIL_LOGIN_ERROR": "Il y a eu erreur!",
      'EMAIL_TRACKING_LINK': 'Lien de suivi:',
      'EMAIL_DATE_LINE': "Date:",
      'EMAIL_TRACK_NOW': "Suivez!",
      'PREVIEW': 'Rendu',
      'SUBJECT': 'Sujet',
      'LOGO_URL': 'Addresse URL au logo',
      'MESSAGE_BODY': 'Message',
      'CONTACT_INFO': 'Coordonnées',

      // Successful ride tracking email
      'EMAIL_TITLE_SUCCESS': 'Suivi des courses en direct',
      'EMAIL_SUBJECT_SUCCESS': 'Suivi des courses en direct',
      "EMAIL_MESSAGE_SUCCESS": 'Cher membre,\nMerci d\'avoir commandé une course avec Eva!\nMerci d’utiliser l’appli Eva ! Veuillez cliquer sur ce lien pour suivre votre commande en temps réel.',
      'EMAIL_MESSAGE1_SUCCESS': 'Cher membre,',
      'EMAIL_MESSAGE2_SUCCESS': 'Merci d\'avoir commandé une course avec Eva!',
      'EMAIL_MESSAGE3_SUCCESS': 'Merci d’utiliser l’appli Eva ! Veuillez cliquer sur ce lien pour suivre votre commande en temps réel.',

      // Failed ride tracking email
      'EMAIL_MESSAGE1_FAILED': 'Cher membre,',
      'EMAIL_MESSAGE2_FAILED': 'Merci d\'avoir commandé une course avec Eva!',
      'EMAIL_MESSAGE3_FAILED': 'Notre service est actuellement indisponible. Vous serez averti dès que votre commande sera envoyée!',
      'EMAIL_SUBJECT_FAILED': 'Service indisponible',

      //404
      "404_TITLE": "404 - Page Non Trouvée",
      "404_CLICK": "Cliquez ",
      "404_HERE": "ici",
      "404_RETURN": " pour revenir à la page d'accueil.",

      // Terms and conditions
      'TERMS_AND_CONDITIONS': 'Termes et conditions',

      //New Tracker 
      "FR": "Français",
      "EN": "English",
      "MORE_INFO_AT": "Plus d'informations à ",
      "FOLLOW_THE_RIDE": "Votre transport",
      "COMING_SOON": "Bientôt disponible",
      "SEND": "Envoyer",
      "BUSINESS_NAME": "Nom de l'entreprise",
      "ORDER_LATE": "La commande est en retard",
      "ORDER_DAMAGED": "La commande est endommagée",
      "ORDER_NOT_DELIVERED": "La commande n'a pas été livrée",
      "ORDER_LATE_MSG": "Nous sommes désolés pour la commande en retard",
      "ORDER_DAMAGED_MSG": "Nous sommes désolés pour la commande endommagée",
      "ORDER_NOT_DELIVERED_MSG": "Nous sommes désolés d'apprendre que votre commande n'a pas été livrée.",
      "DRIVER_HASNT_ARRIVED": "Le conducteur n'est pas arrivé",
      "DRIVER_HASNT_ARRIVED_MSG": "Nous sommes désolés d'apprendre que le conducteur n'est pas arrivé.",
      "DRIVER_ISSUES": "Signaler le conducteur",
      "DRIVER_ISSUES_MSG": "Nous sommes désolés qu'il y ait eu un problème avec votre pilote. Afin de pouvoir fournir un message plus détaillé, veuillez cliquer sur l'option \"Autre\".",
      "CLICK_FOR_CONTACT": "Veuillez cliquer sur le bouton suivant pour alerter Eva, et l'un de nos représentants vous contactera sous peu !",
      "ALERT_COOP": "Alerte à Eva",
      "CHANGE_DESTINATION": "Changez la destination",
      "REQUEST_REFUND": "Demander un remboursement",
      "HELP": "Aide",
      "OTHER": "Autre",
      "THANK_YOU": "Merci !",
      "MESSAGE_SENT": "Votre message a été envoyé.",
      "MESSAGE_ERROR": "Il y a eu un problème pour envoyer votre message.",
      "TRY_AGAIN_LATER_2": "Veuillez réessayer plus tard !",
      "ITINERARY": "Itinéraire",
      "DOWNLOAD_OUR_APPS": "Téléchargez l'application!",
      "DRIVER_FOUND": "Nous avons trouvé un conducteur pour vous!",
      "STORE_CANCELLED": "Le commerce a annulé la course.",
      "DRIVER_CANCELLED": "Le membre conducteur a annulé la course. Nous en recherchons un autre pour vous.",
      "DRIVER_CANCELLED_NO_RE_DISPATCH": "Le membre conducteur a annulé la course.",
      "COOP_CANCELLED": "Un opérateur a annulé la course",
      "PACKAGE_DELIVERED": "Course complétée le ",
      "AT": "à",
      "ORDER_STATUS": "Repérer votre commande",
      "RIDE_STATUS": "Suivre votre course",
      "SCHEDULED": "Course",
      "RIDE": "programmée",
      "SCHEDULED_IN": "La course est programmée pour avoir lieu dans",
      "SCHEDULED_ON": "La course est programmée pour avoir lieu le",
      "SCHEDULED_ON_DELIVER_BY": "L'arrivée estimée de la course est le",
      "SCHEDULED_BETWEEN": "entre",
      "SCHEDULED_AND": "et",
      "JOIN_US": "Contactez-nous: ",
      "SUCCESS_RIDE_POPUP": "Merci de soutenir Eva ! Téléchargez notre application de covoiturage et faites partie de la solution aux côtés de 50 000 membres !",
      "LINK_PROVIDED": "Le lien fourni est",
      "NOT_VALID": "invalide",
      "URL_CORRECT": "Assurez-vous que l'URL est correcte.",
      "ARE_YOU": "Vous êtes",
      "SURE": "sûr",
      "WANT_TO_CANCEL": "de vouloir annuler ?",
      "MIA_PRICE_DISCLAIMER": "Le prix affiché inclus les redevances gouvernementales, les frais aéroportuaires et les autres frais. Les taxes ne sont pas incluses.",
      "POWERED_BY_EVA": "Propulsé par Eva",
      "CONTENT_DISCLAIMER": "*Veuillez noter que, en tant que transporteur, nous ne sommes pas responsables du contenu des commandes.",

      // Colours
      "GREEN": "Vert",
      "RED": "Rouge",
      "BLUE": "Bleu",
      "GREY": "Gris",
      "GRAY": "Gris",
      "BLACK": "Noir",
      "INDIGO": "Indigo",
      "ORANGE": "Orange",
      "YELLOW": "Jaune",
      "PURPLE": "Mauve",
      "WHITE": "Blanc",
      "BROWN": "Brun",
      "PINK": "Rose",
      "CITY": "Ville",
      "PROOF_OF_CUSTOMER_FAIL": "Échec vérification du client",
      "PROOF_OF_ADDRESS_FAIL": "Échec vérification de l'adresse",
      "BILLED_MINUTES": "Durée",
      "BILLED_DISTANCE": "Distance",
      "IMPORT_ORDERS": "Importer Commandes",
      "DRAG_DROP_FILE": "Glisser et déposer",
      "CLICK_TO_UPLOAD_FILE": "ou cliquez pour transmettre un fichier",
      "FILE_UPLOAD_DISCLAIMER": "Jusqu'à {{sizeInMB}}MB de taille,  formats acceptés : {{acceptedFormats}}",
      "FILE_TOO_BIG": "Le fichier est trop gros.",
      "SELECTED_FILE": "Fichier sélectionné :",
      "ROUTE_FILE": "Fichier d'itinéraire",
      "IMPORT_ORDERS_SUCCESS_TITLE": "Les commandes ont été importées avec succès !",
      "WHITE_LABEL": "Personalisation",
      "WHITE_LABEL_DELETE_TITLE": "Supprimer le style",
      "WHITE_LABEL_DELETE_BODY": "Êtes-vous sûr de vouloir supprimer ce style ? Cette action ne peut être annulée.",
      "WHITE_LABEL_DELETE_SUCCESS_TITLE": "Style supprimé !",
      "WHITE_LABEL_ACTIVATE_TITLE": "Activer le style",
      "WHITE_LABEL_ACTIVATE_BODY": "Êtes-vous sûr de vouloir activer ce style ? Tous vos clients le verront sur leurs liens de suivi.",
      "WHITE_LABEL_ACTIVATE_SUCCESS_TITLE": "Style activé !",
      "WHITE_LABEL_DEACTIVATE_TITLE": "Désactiver le style",
      "WHITE_LABEL_DEACTIVATE_BODY": "Êtes-vous sûr de vouloir désactiver ce style ? Tous vos clients verront le lien de suivi Eva.",
      "WHITE_LABEL_DEACTIVATE_SUCCESS_TITLE": "Style désactivé !",
      "WHITE_LABEL_NO_TRACKING_STYLES": "Pas encore de style",
      "WHITE_LABEL_CREATE_NEW_STYLE": "Créer un nouveau style",
      "WHITE_LABEL_NO_STLYE_FOUND_TITLE": "Aucun style trouvé",
      "WHITE_LABEL_NO_STLYE_FOUND_BODY": "Il n'y a pas de style correspondant à l'ID donné",
      "WHITE_LABEL_RESET_TITLE": "Réinitialiser le formulaire",
      "WHITE_LABEL_RESET_BODY": "Êtes-vous sûr de vouloir réinitialiser le style ?",
      "WHITE_LABEL_SAVE_SUCCESS_TITLE": "Style sauvegardé !",
      "WHITE_LABEL_SAVE_VALIDATION_TITLE": "Erreur",
      "WHITE_LABEL_SAVE_VALIDATION_BODY": "Un alias et une description sont nécessaires pour enregistrer un style.",
      "NO_INVOICES": "Pas de factures",
      "ALIAS": "Alias",
      "DESCRIPTION": "Description",
      "CREATED_AT": "Créé le",
      "UPDATED_AT": "Mis à jour le",
      "ACTIONS": "Actions",
      "ACTIVATE": "Activer",
      "DEACTIVATE": "Désactiver",
      "DELETE": "Supprimer",
      "EDIT": "Modifier",
      "INFORMATION": "Information",
      "OPTIONS": "Options",
      "PRIMARY_COLOR": "Primaire",
      "SECONDARY_COLOR": "Secondaire",
      "TEXT_COLOR": "Texte",
      "TITLE_COLOR": "Titre",
      "HEADER_COLOR": "Entête",
      "BACKGROUND_COLOR": "Fond",
      "LINE_1_COLOR": "Ligne 1",
      "LINE_2_COLOR": "Ligne 2",
      "LOGO_REDIRECTION": "Redirection du logo",
      "LOGO_ICON": "Icône du logo",
      "CAR_ICON": "Icône voiture",
      "CAR_ICON_NIGHT": "Icône voiture nuit",
      "CAR_ICON_WIDTH": "Largeur",
      "CAR_ICON_HEIGHT": "Hauteur",
      "PROOF": "Preuve",
      "PICKUP_ICON": "Icône de ramassage",
      "PICKUP_ICON_NIGHT": "Icône de ramassage nuit",
      "PICKUP_ICON_WIDTH": "Laregeur",
      "PICKUP_ICON_HEIGHT": "Hauteur",
      "DESTINATION_ICON": "Icône de destination",
      "DESTINATION_ICON_NIGHT": "Icône de destination nuit",
      "DESTINATION_ICON_WIDTH": "Largeur",
      "DESTINATION_ICON_HEIGHT": "Hauteur",
      "TIME_OF_DAY": "Heure du jour",
      "VIEW": "Vue",
      "DAY": "Jour",
      "NIGHT": "Nuit",
      "WHITE_LABEL_FILE_TOO_BIG": "Le fichier est trop gros - le maximum est de 500kb",
      "GET_DEMO_LINK": "Obtenir un lien de démonstration partageable",
      "DRIVER_INFO_UNAVAILABLE": "Information indisponible",
      "DELIVERY_SERVICE_BIKE": "Livraison vélo",
      "DELIVERY_SERVICE_MOTORCYCLE": "Livraison Moto",
      "DELIVERY_SERVICE_AMBULANCE": "Ambulance",
      "RIDE_SHARING_MOTO": "Taxi Moto",
      "DELIVERY_TRICYCLE": "Livraisons Tricycle",
      "RIDE_SHARING_TRICYCLE": "Taxi Tricycle",
      "RIDE_SHARING_SERVICE_LUX": "Co-voiturage luxe",
      "SHUTTLE": "Navette",
      "BLOCKCHAIN_ID": "Identifiant client",
      "BLOCKCHAIN_ID_SUBTITLE": "Votre identifiant sur la blockchain Eva",
      "ACCESS_TOKEN": "Clé accès API",
      "ACCESS_TOKEN_SUBTITLE": 'Votre clé pour les intégrations tierces',
      "PROXY_ERROR": "Nous n'avons pas pu récupérer le numéro de téléphone. Veuillez réessayer.",
      "CALL": "Appeler",
      "BEFORE_WE_CONTINUE": "Avant de continuer...",
      "PROXY_PHONE_DISCLAIMER": "Il s'agit d'un numéro de téléphone à usage unique, accessible depuis le ",
      "NO_ACCOUNT_MICROSOFT": "Il n'y a pas de compte Eva Affaires associés avec ce compte Microsoft",
      "MICROSOFT_LOG_IN": "Se connecter avec Microsoft",
      "OR_LOGIN": "Ou",
      "CASH_PAYMENT_ORDER_AMOUNT": "Valeur de la commande à réclamer à la porte",
      "MIA_CASH_AMOUNT_DISCLAIMER": "Ceci est le montant qui sera réclamé à la porte pour les biens transportés et livrés de votre entreprise. Si le tout est déja payé par votre client, simplement laissez le champs vide.",
      "ASK_FOR_DELIVERY_FEE": "Réclamer les frais de livraison à la porte",
      "MIA_CASH_AMOUNT_VALUE_DELIVERED_DISCLAIMER": "Cochez si vous voulez que le conducteur réclame les frais de livraisons en espèce à la porte.",

      //Invoices
      "Paid": "Payé",
      "Payment Type": "Mode de paiement",
      "Files": "Fichiers",
      "Total": "Total",
      "PAID_DATETIME": "Date de paiement",
      "PAID": "Payé",
      "PAYMENT": "Paiement",
      "CREDIT_CARD": "Carte de Crédit",
      "MANUAL": "Paiement manuel",
      "FROM_INVOICE": "Du",
      "TO_INVOICE": "Au",
      "INVOICE_NUMBER": "Numéro de facture",
      "INVOICE_FILES": "Fichiers",

      "CARD_IS_SET": "Votre carte est enregistrée. Vous n'avez pas d'autre action à prendre, à moins que vous ayez reçu un e-mail d'échec de paiement.",
      "SAVE_CARD": "Enregistrer la carte",
      "SET_CARD": "Carte de crédit",
      "SAVE_CARD_SUCCESS": "L'enregistrement de votre carte a fonctionné. Le paiement sera réessayé demain si vous aviez un paiement en attente.",
      "SAVE_CARD_FAILURE": "Votre carte a été refusée, réessayez avec une autre carte et/ou complétez l'authentification au complet.",
      "PAYMENT_FOOTNOTE_1": "En utilisant nos services d'initiation de paiement, vous acceptez nous donner l'autorisation nécessaire pour que nous traitions les paiements en votre nom.",
      "PAYMENT_FOOTNOTE_2": "En utilisant nos services, vous acceptez la fréquence des paiements étant mensuelle, sauf si autrement stipulé.",
      "PAYMENT_FOOTNOTE_3": "En utilisant nos services, vous acceptez que le montant des paiements sera déterminé en fonction de l'utilisation de nos services et communiquées à vous avant leur traitement via e-mail."
    },
  },
  es: {
    translation: {
      "ES": "ES",

      //LoginPage
      "LOGIN_EMAIL_LABEL": "Correo electrónico",
      "TWO_FACTOR_AUTH_TOKEN_PLACEHOLDER": "#######",
      "TWO_FACTOR_AUTH_BUTTON_LABEL": "Ingresar",
      "LOGIN_PHONE_LABEL": "Teléfono",
      "TWO_FACTOR_AUTH_TOKEN_LABEL": "Código de verificación",
      "PASSWORD": "Contraseña",
      "LOGIN_BUTTON_LABEL": "Iniciar sesión",
      "LOGIN_ERROR": "Error al iniciar sesión. Por favor verifica tus datos de acceso.",
      "EMAIL_LOGIN_ERROR": "Ingresa un correo electrónico válido.",
      "NO_EMAIL_LOGIN_ERROR": "Por favor ingresa tu correo electrónico.",
      "NO_PASSWORD_LOGIN_ERROR": "Por favor ingresa tu contraseña.",
      "LOGOUT": "Cerrar sesión",
      "ACCEPT_TERMS": "Aceptar términos y condiciones",
      "ACCEPT_TERMS_ERROR": "Debes aceptar los términos y condiciones de Eva antes de continuar.",

      //Side Bar
      "WELCOME_SIDEBAR": "Bienvenido, ",
      "HOME": "Inicio",
      "DASHBOARD": "Panel principal",
      "LIVETRACKER": "Seguimiento en vivo",
      "BUSINESS": "Empresas",
      "ATTENTION": "¡Atención!",

      //Home
      "TRACK_YOUR_ORDER": "SIGUE TUS PEDIDOS",
      "HOME_HEADER_1": "TIEMPO REAL",
      "HOME_HEADER_2": " PEDIDOS Y SEGUIMIENTO",
      "HOME_JOIN_COOP": "¡ÚNETE A LA REVOLUCIÓN EVA!",

      //Live Tracker
      "DRIVER": "Conductor",
      "DESTINATION": "Destino",
      "PICKUP": "Recogida",
      "CLOSE": "Cerrar",
      "ON_WAY_TOWARDS_RIDER_DESTINATION": "En camino al destino del pasajero",
      "ON_WAY_TOWARDS_PACKAGE_DESTINATION": "En camino a la dirección de entrega",
      "OUT_FOR_DELIVERY": "En ruta de entrega",
      "ON_WAY_TOWARDS_PICKUP": "En camino al punto de recogida",
      "ON_WAY_TOWARDS_RIDER": "En camino a recoger al pasajero",
      "PICKING_UP_PACKAGE": "Esperando el pedido",
      "PICKING_UP_RIDER": "Llegó al punto de encuentro con el pasajero",
      "ARRIVED_RIDER_DESTINATION": "Llegó al destino del pasajero",
      "ARRIVED_PACKAGE_DESTINATION": "Entregando el pedido",
      "DELIVERY_ESTIMATION": "Estimamos que tu pedido será entregado <0>alrededor de las <0/>",
      "RIDE_ESTIMATION": "Estimamos que el pasajero llegará a su destino <0>alrededor de las <0/>",
      "NO_LANDSCAPE_1": "Este sitio funciona mejor en modo ",
      "PORTRAIT": "vertical",
      "NO_LANDSCAPE_2": "",
      "ETA": "Tiempo estimado",
      "MINS": "min",
      "YES": "Sí",
      "NO": "No",
      "CANCEL_RIDE": "Cancelar",
      "CANCEL_SUCCESS": "¡El viaje ha sido cancelado con éxito! Por favor, espera unos minutos para que los cambios se reflejen en la aplicación.",
      "CANCEL_ERROR": "Hubo un error al procesar la solicitud de cancelación. ¡Por favor verifica que el viaje esté activo!",
      "EVA_RIDESHARE_APP": "Eva también es una aplicación de transporte compartido para ir del punto A al punto B.",
      "JOIN_THE_MOVEMENT": "¡Descárgala y únete al movimiento!",

      //Error Page
      "ERROR": "Error",
      "WRITE_TO": "Únete a nosotros: ",
      "OPS": "¡Ups! Buscando un conductor.",
      "FOUND_PROBLEM": "El conductor canceló tu viaje. Estamos buscando otro.",
      "ENSURANCE_PROBLEM": "¡Ten la seguridad de que estamos trabajando arduamente para solucionar la situación!",
      "SPEAK_TO_REPRESENTATIVE": "Para hablar con un representante, contáctanos al ",

      //Rider Cancel Page
      "RIDER_CANCEL_TITLE": "Viaje cancelado.",
      "RIDER_CANCEL_BODY": "¡El viaje seleccionado fue cancelado por ti!",
      "CAN_ORDER_RIDE": "Haz clic en el botón de abajo para solicitar un nuevo viaje.",
      "NO_DRIVER_FOUND": "No había conductor disponible para esta solicitud.",

      //Coop cancel page
      "COOP_CANCEL_TITLE": "Viaje Cancelado.",
      "COOP_CANCEL_BODY": "¡El viaje seleccionado fue cancelado por Eva!",

      //Looking for driver
      "LOOKING_FOR_DRIVER": "Estamos buscando un conductor para tu viaje.",
      "CONTACTING_DRIVERS": "Estamos contactando conductores cercanos a ti.",
      "STILL_CONTACTING_DRIVERS": "¡Un momento! Seguimos buscando un conductor cercano a ti.",
      "NOT_LONG": "Esto no debería tardar mucho.",
      "STILL_NOT_LONG": "Por favor, danos un poco más de tiempo para encontrar al conductor más cercano disponible.",
      "FOR_INQUIRIES": "Para cualquier consulta, contacta a un representante al ",
      "REQUEST_START": "Estamos atendiendo tu solicitud de transporte.",

      // expected times
      "EXPECTED_DELIVERY_TIME": "{{date}} a las {{time}}",
      "EXPECTED_DELIVERY_TIME_MINUTES": "Tu conductor llegará en <0>{{minutes}} minutos</0>.",
      "EXPECTED_DELIVERY_TIME_MINUTES_DELIVERY": "Tu entrega llegará en <0>{{minutes}} minutos</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS": "Tu conductor llegará en <0>{{hours}} horas</0>.",
      "EXPECTED_DELIVERY_TIME_HOURS_DELIVERY": "Tu entrega llegará en <0>{{hours}} horas</0>.",
      "EXPECTED_DELIVERY_TIME_DAYS": "Tu conductor llegará en <0>{{days}} días</0>.",
      "EXPECTED_DELIVERY_TIME_DAYS_DELIVERY": "Tu entrega llegará en <0>{{days}} días</0>.",
      "EXPECTED_DELIVERY_TIME_OVER": "¡Tu conductor está en camino!",
      "EXPECTED_DELIVERY_TIME_OVER_DELIVERY": "¡Tu entrega está en camino!",

      //Invalid Link
      "INVALID_LINK": "El enlace proporcionado no es válido",
      "VALID_URL_CHECK": "Asegúrate de que la URL sea correcta.",
      "FOR_MORE_INFO": "Para más información, contáctanos al ",

      //Completed
      "COMPLETED": "Viaje Completado",
      "STATUS_INFO": "¡El viaje se ha completado con éxito!",

      //Order ride
      "ORDER_RIDE": "Solicitar un viaje",
      "DELIVERY": "Entrega",
      "BULK": "Entrega Masiva",
      "RIDESHARING": "Viaje Compartido",
      "CONTINUE": "Continuar",
      "RIDE_DETAILS": "Detalles del Viaje",
      "OPTIONAL": "N° depto., piso, etc. (opcional)",
      "OPTIONAL_RIGHT_NOW": "Ahora mismo",
      "SHOW_BY_DRIVER_ROUTE": "Mostrar por ruta del conductor",
      "SHOW_BY_CUSTOMER_RIDE": "Mostrar por pedidos del cliente",
      "ARE_YOU_SURE_CANCEL": "¿Estás seguro de que quieres cancelar el viaje?",
      "ORDER_NUMBER_REFERENCE": "Número de referencia del pedido",
      "PICKUP_PHONE": "Teléfono del punto de recogida",
      "COMPANY_NAME": "Nombre de la empresa de recogida",
      "TIP": "Propina",
      "APARTMENT_NUMBER": "N° Depto.",
      "ADDRESS_PLACEHOLDER": "ej.: Av. Providencia 1234",
      "FIRST_NAME_PLACEHOLDER": "ej.: Juan",
      "LAST_NAME_PLACEHOLDER": "ej.: González",
      "EMAIL_PLACEHOLDER": "ej: juangonzalez@eva.coop",
      "TELEPHONE_PLACEHOLDER": "+56 9 1234 5678",
      "TEL_EXTENSION_PLACEHOLDER": "Anexo (opcional)",
      "PLANNED_DATE": "Programa tu viaje",
      "DRIVER_WILL_ARRIVE_THAT_TIME": "El conductor llegará al punto de inicio alrededor de esta hora",
      "CLIENT_TITLE": "CLIENTE",
      "COMPANY_STEP_TITLE": "EMPRESA (opcional)",
      "FIRST_NAME": "Nombre",
      "LAST_NAME": "Apellido",
      "EMAIL": "Correo electrónico",
      "TELEPHONE": "Teléfono",
      "SENDING_EMAIL_SMS_MESSAGE": "Se enviará un correo electrónico y un SMS al cliente para seguir el viaje.",
      "SENDING_COMPANY_INFO_MESSAGE": "Esta información será enviada al conductor para ubicar el lugar de recogida.",
      "NEXT": "Siguiente",
      "PREVIOUS": "Anterior",
      "LOADING": "Cargando",
      "TIP_PLACEHOLDER": "Propina",
      "ORDER_RIDE_CONFIRM": "Confirmar viaje",
      "ELECTRIC_PLACEHOLDER": "Eléctrico",
      "NOTE": "Nota",
      "CUSTOMER_NOTE_PLACEHOLDER": "Instrucciones para la entrega al cliente. (opcional)",
      "PICKUP_NOTE_PLACEHOLDER": "Instrucciones para la recogida en la tienda. (opcional)",
      "CANNABIS": "Cannabis",

      //Ride Preview
      "FROM_ADDRESS": "Dirección de Origen",
      "TO_ADDRESS": "Dirección de Destino",
      "SENDING_REQUEST": "Enviando solicitud...",
      "BRAVO": "¡Bravo!",
      "CONFIRM": "Confirmar",
      "CANCEL": "Cancelar",
      "TRACKING_ID_CONFIRMATION": "¡El viaje ha sido solicitado! Por favor espera unos minutos para que la página de seguimiento se actualice. ID de seguimiento:",
      "START_LOCATION": "Ubicación de inicio",
      "RIDE_SHARING": "Viaje Compartido",
      "CONFIRM_ORDER": "Confirmar pedido",
      "SELECT_RIDE_TYPE": "Selecciona tu tipo de viaje",
      "NO_DRIVERS": "Servicio no disponible.",
      "TRY_AGAIN_LATER": "Lamentamos mucho esto, nos faltan conductores en este momento. Por favor, intenta más tarde.",
      "ORDER_ERROR": "Ha ocurrido un error al solicitar el viaje.",

      //Mia Rides List
      "DATE": "Fecha",
      "STATUS": "Estado",
      "CUSTOMER_FIRST_NAME": "Nombre del Cliente",
      "CUSTOMER_LAST_NAME": "Apellido del Cliente",
      "CUSTOMER_PHONE": "Teléfono del Cliente",
      "CUSTOMER_EMAIL": "Correo del Cliente",
      "RIDE_TYPE": "Tipo de Viaje",
      "NO_LIVE_RIDES": "¡No hay viajes activos aún!",
      "SCHEDULED_DATE": "Hora de Despacho",
      "ONSITE_DATE": "Fecha y Hora en Sitio",
      "DELIVERED_BY_DATE": "Entregado Por",
      "ORDER_NUMBER": "Número de Pedido",
      "IS_STARTED": "¿Ha comenzado?",
      "IS_EN_ROUTE": "¿Conductor Asignado?",
      "NUMBER_CUSTOMER_RIDES": "Número de pedidos asociados",
      "STEP_ORDER": "Orden en la ruta",

      //Ride request status
      "SUCCESS": "Éxito",
      "NO_DRIVER": "Sin Conductor",
      "CANCELLED_BY_COMMUNITY": "Cancelado por Eva",
      "CANCELLED_BY_RIDER": "Cancelado por el pasajero",
      "CANCELLED_BY_DRIVER": "Cancelado por el conductor",
      "NOT_YET_ACCEPTED": "Buscando conductor",
      "LIVE": "En proceso",
      "LIVE_OTHER_STEP": "En proceso",

      //Ride History
      "RIDE_HISTORY": "Historial de Viajes",
      "RIDE_SERVICE_NAME": "Tipo de servicio",
      "UNKNOWN_CANCELLATION": "Cancelado",
      "RIDE_SHARING_SERVICE": "Viaje Compartido",
      "DELIVERY_SERVICE": "Entrega",
      "CANNABIS_SERVICE": "Cannabis",
      "BULK_SERVICE": "Entrega Masiva",
      "ERROR_ALERT": "Ha ocurrido un error",
      "TABLE_API_ERROR_1": "Hubo un problema al obtener los datos.",
      "TABLE_API_ERROR_2": "Por favor, intenta más tarde.",
      "FAILED": "Fallido",

      "ALL": "Todos",
      "IN_RIDE_STATUS": "En Viaje",
      "SCHEDULED_STATUS": "Programado",
      "DISPATCHING_STATUS": "Despachando",
      "TRANSIT_PICKUP": "En Tránsito a Recogida",
      "ARRIVED_PICKUP": "Llegó a Recogida",
      "TRANSIT_DESTINATION": "En Tránsito a Destino",
      "ARRIVED_DESTINATION": "Llegó a Destino",
      "RETURNING": "Retornando",
      "CANCELLED": "Cancelado",

      //Account Settings
      "ACCOUNT_SETTINGS": "Configuración de Cuenta",
      "SEND_SMS_NOTI_TITLE": "¿Enviar Notificaciones SMS?",
      "SEND_EMAIL_NOTI_TITLE": "¿Enviar Notificaciones por Correo?",
      "SEND_SMS_NOTI_SUBTITLE": "Enviar ID de seguimiento a clientes por SMS",
      "SEND_EMAIL_NOTI_SUBTITLE": "Enviar ID de seguimiento a clientes por correo",
      "EMAIL_TITLE": "Correo Electrónico de la Empresa:",
      "EMAIL_SUBTITLE": "Nuevo Correo",
      "ADDRESS_TITLE": "Dirección de la Empresa:",
      "ADDRESS_SUBTITLE": "Dirección",
      "SEND_SMS_TRACKING": "Enviar Seguimiento por SMS",
      "SEND_EMAIL_TRACKING": "Enviar Seguimiento por Correo",
      "COMPANY_APARTMENT_NUMBER": "Número de Oficina de la Empresa",
      "SAVE": "Guardar",
      "RESET": "Restablecer",

      //Scheduled Ride View
      "SCHEDULED_RIDE": "Viaje Programado",
      "SCHEDULE_RIDE": "Hora del Viaje",
      "SCHEDULED_FOR_LATER": "Viaje Programado",
      "SCHEDULED_RIDE_BODY": "Este viaje está programado para suceder en:",

      // Table
      "ROW_PER_PAGE": "FILAS POR PÁGINA",

      //Countdown
      "DAYS": "Días",
      "HOURS": "Horas",
      "MINUTES": "Minutos",
      "SECONDS": "Segundos",

      "TOTAL_COST": "Cargo Total del Viaje",

      // Customize Email Default
      "EMAIL_TRACKING_LINK": "Link de Seguimiento: ",
      "EMAIL_THANK_YOU": "¡Agradecemos que seas parte de los miembros Eva!",
      "EMAIL_DATE_LINE": "Fecha:",
      "EMAIL_TRACK_NOW": "¡Seguir!",
      "PREVIEW": "Vista previa",
      "SUBJECT": "Asunto",
      "LOGO_URL": "URL del Logo",
      "MESSAGE_BODY": "Cuerpo del Mensaje",
      "CONTACT_INFO": "Información de Contacto",

      // Successful ride tracking email
      "EMAIL_TITLE_SUCCESS": "Seguimiento de Viaje en Vivo",
      "EMAIL_MESSAGE_SUCCESS": "Estimado miembro,\n¡Gracias por solicitar un viaje con Eva!\n¡Gracias por usar la aplicación Eva! Por favor, haz clic en el enlace para seguir tu pedido en tiempo real.",
      "EMAIL_MESSAGE1_SUCCESS": "Estimado miembro,",
      "EMAIL_MESSAGE2_SUCCESS": "¡Gracias por solicitar un viaje con Eva!",
      "EMAIL_MESSAGE3_SUCCESS": "¡Gracias por usar la aplicación Eva! Por favor, haz clic en el enlace para seguir tu pedido en tiempo real.",
      "EMAIL_SUBJECT_SUCCESS": "Seguimiento de Viaje en Vivo",

      // Failed ride tracking email
      "EMAIL_MESSAGE1_FAILED": "Estimado miembro,",
      "EMAIL_MESSAGE2_FAILED": "¡Gracias por solicitar un viaje con Eva!",
      "EMAIL_MESSAGE3_FAILED": "Nuestro servicio no está disponible en este momento. ¡Te notificaremos tan pronto como tu pedido sea enviado!",
      "EMAIL_SUBJECT_FAILED": "Servicio No Disponible",

      //404
      "404_TITLE": "404 - Página No Encontrada",
      "404_CLICK": "Haz clic ",
      "404_HERE": "aquí",
      "404_RETURN": " para volver a la página de inicio.",

      // Terms and conditions
      "TERMS_AND_CONDITIONS": "Términos y Condiciones",

      //New Tracker 
      "FR": "Français",
      "EN": "English",
      "MORE_INFO_AT": "Más información en ",
      "FOLLOW_THE_RIDE": "Tu transporte",
      "GET_APP": "¡Descarga la App!",
      "COMING_SOON": "Próximamente",
      "SEND": "Enviar",
      "BUSINESS_NAME": "Nombre de la Empresa",
      "TWO_FACTOR_AUTH_ERROR": "Hubo un error con la autenticación de dos factores. Por favor, intenta nuevamente.",
      "ORDER_LATE": "Pedido atrasado",
      "Phone": "Teléfono",
      "ORDER_DAMAGED": "Pedido dañado",
      "ORDER_NOT_DELIVERED": "Pedido no entregado",
      "ORDER_LATE_MSG": "Lamentamos el retraso en tu pedido.",
      "ORDER_DAMAGED_MSG": "Lamentamos que tu pedido haya llegado dañado.",
      "ORDER_NOT_DELIVERED_MSG": "Lamentamos que tu pedido no haya sido entregado.",
      "DRIVER_HASNT_ARRIVED": "El conductor no ha llegado",
      "DRIVER_HASNT_ARRIVED_MSG": "Lamentamos que el conductor no haya llegado.",
      "DRIVER_ISSUES": "Reportar Conductor",
      "DRIVER_ISSUES_MSG": "Lamentamos que hayas tenido un problema con tu conductor. Para proporcionar un mensaje más detallado, por favor selecciona la opción \"Otro\".",
      "CLICK_FOR_CONTACT": "Por favor, haz clic en el siguiente botón para alertar a Eva, y uno de nuestros representantes te contactará a la brevedad.",
      "ALERT_COOP": "Alertar a Eva",
      "CHANGE_DESTINATION": "Cambiar el destino",
      "REQUEST_REFUND": "Solicitar reembolso",
      "HELP": "Ayuda",
      "OTHER": "Otro",
      "THANK_YOU": "¡Gracias!",
      "MESSAGE_SENT": "Tu mensaje ha sido enviado.",
      "MESSAGE_ERROR": "Hubo un problema al enviar tu mensaje.",
      "TRY_AGAIN_LATER_2": "¡Por favor intenta más tarde!",
      "ITINERARY": "Itinerario",
      "DOWNLOAD_OUR_APPS": "¡Descarga nuestra app!",
      "DRIVER_FOUND": "¡Hemos encontrado un conductor para ti!",
      "STORE_CANCELLED": "La tienda canceló el viaje.",
      "DRIVER_CANCELLED": "El conductor canceló el viaje. Estamos buscando un nuevo conductor para tu pedido.",
      "DRIVER_CANCELLED_NO_RE_DISPATCH": "El conductor canceló el viaje.",
      "COOP_CANCELLED": "Un operador ha cancelado el viaje.",
      "PACKAGE_DELIVERED": "El viaje fue completado el ",
      "AT": "a las",
      "ORDER_STATUS": "Seguimiento de tu Pedido",
      "RIDE_STATUS": "Seguimiento de tu Viaje",
      "SCHEDULED": "Programado",
      "RIDE": "Viaje",
      "SCHEDULED_IN": "El viaje está programado para realizarse en",
      "SCHEDULED_ON": "El viaje está programado para realizarse el",
      "SCHEDULED_ON_DELIVER_BY": "Esta entrega programada llegará antes del",
      "SCHEDULED_BETWEEN": "entre",
      "SCHEDULED_AND": "y",
      "JOIN_US": "Contáctanos: ",
      "SUCCESS_RIDE_POPUP": "¡Gracias por apoyar a Eva! ¡Descarga nuestra app para viajes compartidos y sé parte de la solución junto a 50.000 miembros!",
      "LINK_PROVIDED": "El enlace proporcionado es",
      "NOT_VALID": "inválido",
      "URL_CORRECT": "Asegúrate de que la URL sea correcta.",
      "ARE_YOU": "¿Estás ",
      "SURE": "seguro",
      "WANT_TO_CANCEL": "que quieres cancelar?",
      "MIA_PRICE_DISCLAIMER": "El precio cotizado incluye subsidios gubernamentales, cargos aeroportuarios y otros cargos. Impuestos no incluidos.",
      "POWERED_BY_EVA": "Impulsado por Eva",
      "CONTENT_DISCLAIMER": "*Por favor nota que, como servicio de entrega, no somos responsables por pedidos erróneos.",

      //Colours
      "GREEN": "Verde",
      "RED": "Rojo",
      "BLUE": "Azul",
      "GREY": "Gris",
      "GRAY": "Gris",
      "BLACK": "Negro",
      "INDIGO": "Índigo",
      "ORANGE": "Naranjo",
      "YELLOW": "Amarillo",
      "PURPLE": "Morado",
      "WHITE": "Blanco",
      "BROWN": "Café",
      "PINK": "Rosado",
      "CITY": "Ciudad",

      "PROOF_OF_CUSTOMER_FAIL": "Verificación del cliente fallida",
      "PROOF_OF_ADDRESS_FAIL": "Verificación de dirección fallida",
      "BILLED_MINUTES": "Duración",
      "BILLED_DISTANCE": "Distancia",
      "IMPORT_ORDERS": "Importar Pedidos",
      "DRAG_DROP_FILE": "Arrastra y suelta",
      "CLICK_TO_UPLOAD_FILE": "o haz clic para subir un archivo",
      "FILE_UPLOAD_DISCLAIMER": "Hasta {{sizeInMB}}MB de tamaño, formatos aceptados: {{acceptedFormats}}",
      "FILE_TOO_BIG": "El archivo es demasiado grande.",
      "NO_PHONE_EMAIL_LOGIN_ERROR": "Por favor ingresa tu correo electrónico o número de teléfono.",
      "SELECTED_FILE": "Archivo seleccionado: ",
      "ROUTE_FILE": "Archivo de ruta",
      "IMPORT_ORDERS_SUCCESS_TITLE": "¡Pedidos importados exitosamente!",
      "WHITE_LABEL": "Personalización",
      "WHITE_LABEL_DELETE_TITLE": "Eliminar estilo",
      "WHITE_LABEL_DELETE_BODY": "¿Estás seguro de que quieres eliminar este estilo? Esta acción no se puede deshacer.",
      "WHITE_LABEL_DELETE_SUCCESS_TITLE": "¡Estilo eliminado!",
      "WHITE_LABEL_ACTIVATE_TITLE": "Activar estilo",
      "WHITE_LABEL_ACTIVATE_BODY": "¿Estás seguro de que quieres activar este estilo? Todos tus clientes verán esto en sus enlaces de seguimiento.",
      "WHITE_LABEL_ACTIVATE_SUCCESS_TITLE": "¡Estilo activado!",
      "WHITE_LABEL_DEACTIVATE_TITLE": "Desactivar estilo",
      "WHITE_LABEL_DEACTIVATE_BODY": "¿Estás seguro de que quieres desactivar este estilo? Todos tus clientes verán el enlace de seguimiento original de Eva.",
      "WHITE_LABEL_DEACTIVATE_SUCCESS_TITLE": "¡Estilo desactivado!",
      "WHITE_LABEL_NO_TRACKING_STYLES": "Aún no hay estilos.",
      "WHITE_LABEL_CREATE_NEW_STYLE": "Crear nuevo estilo",
      "WHITE_LABEL_NO_STLYE_FOUND_TITLE": "No se encontró el estilo",
      "WHITE_LABEL_NO_STLYE_FOUND_BODY": "No hay ningún estilo que coincida con el ID proporcionado.",
      "WHITE_LABEL_RESET_TITLE": "Restablecer formulario",
      "WHITE_LABEL_RESET_BODY": "¿Estás seguro de que quieres restablecer el estilo actual al predeterminado?",
      "WHITE_LABEL_SAVE_SUCCESS_TITLE": "¡Estilo guardado!",
      "WHITE_LABEL_SAVE_VALIDATION_TITLE": "Error",
      "WHITE_LABEL_SAVE_VALIDATION_BODY": "Se requiere un alias y una descripción para guardar un estilo.",

      "NO_INVOICES": "Sin boletas",
      "ALIAS": "Alias",
      "DESCRIPTION": "Descripción",
      "CREATED_AT": "Creado el",
      "UPDATED_AT": "Actualizado el",
      "ACTIONS": "Acciones",
      "ACTIVATE": "Activar",
      "DEACTIVATE": "Desactivar",
      "DELETE": "Eliminar",
      "EDIT": "Editar",
      "INFORMATION": "Información",
      "OPTIONS": "Opciones",

      // Style options
      "PRIMARY_COLOR": "Color Primario",
      "SECONDARY_COLOR": "Color Secundario",
      "BACKGROUND_COLOR": "Color de Fondo",
      "TEXT_COLOR": "Color de Texto",
      "TITLE_COLOR": "Color de Título",
      "HEADER_COLOR": "Color de Encabezado",
      "LINE_1_COLOR": "Color de Línea 1",
      "LINE_2_COLOR": "Color de Línea 2",
      "LOGO_REDIRECTION": "Redirección del Logo",
      "LOGO_ICON": "Ícono del Logo",
      "CAR_ICON": "Ícono de Auto",
      "CAR_ICON_NIGHT": "Ícono de Auto Nocturno",
      "CAR_ICON_WIDTH": "Ancho del Ícono de Auto",
      "CAR_ICON_HEIGHT": "Alto del Ícono de Auto",
      "PICKUP_ICON": "Ícono de Recogida",
      "PICKUP_ICON_NIGHT": "Ícono de Recogida Nocturno",
      "PICKUP_ICON_WIDTH": "Ancho del Ícono de Recogida",
      "PICKUP_ICON_HEIGHT": "Alto del Ícono de Recogida",
      "DESTINATION_ICON": "Ícono de Destino",
      "DESTINATION_ICON_NIGHT": "Ícono de Destino Nocturno",
      "DESTINATION_ICON_WIDTH": "Ancho del Ícono de Destino",
      "DESTINATION_ICON_HEIGHT": "Alto del Ícono de Destino",
      "TIME_OF_DAY": "Hora del día",
      "VIEW": "Vista",
      "DAY": "Día",
      "NIGHT": "Noche",
      "WHITE_LABEL_FILE_TOO_BIG": "Archivo demasiado grande - máximo 500kb",
      "GET_DEMO_LINK": "Obtener enlace de demostración compartible",
      "DRIVER_INFO_UNAVAILABLE": "La información del conductor no está disponible",
      "DELIVERY_SERVICE_BIKE": "Entrega en bicicleta",
      "DELIVERY_SERVICE_MOTORCYCLE": "Entrega en moto",
      "DELIVERY_SERVICE_AMBULANCE": "Ambulancia",
      "RIDE_SHARING_MOTO": "Viaje Compartido en Moto",
      "DELIVERY_TRICYCLE": "Entrega en Moto Triciclo",
      "RIDE_SHARING_TRICYCLE": "Viaje Compartido en Moto Triciclo",
      "SHUTTLE": "Transporte",
      "RIDE_SHARING_SERVICE_LUX": "Viaje Compartido Lux",
      "BLOCKCHAIN_ID": "ID de Cliente",
      "BLOCKCHAIN_ID_SUBTITLE": "Tu identificador único en la blockchain de Eva",
      "ACCESS_TOKEN": "Token de Acceso",
      "ACCESS_TOKEN_SUBTITLE": "Tu token para integraciones de terceros",
      "PROXY_ERROR": "No pudimos recuperar el número de teléfono. Por favor intenta nuevamente.",
      "CALL": "Llamar",
      "PROOF": "Comprobante",
      "BEFORE_WE_CONTINUE": "Antes de continuar...",
      "PROXY_PHONE_DISCLAIMER": "Este es un número de teléfono de un solo uso, que se puede llamar desde ",
      "NO_ACCOUNT_MICROSOFT": "No hay una cuenta Eva Business registrada con esta cuenta de Office 365",
      "MICROSOFT_LOG_IN": "Iniciar sesión con Microsoft",
      "OR_LOGIN": "O",
      "CASH_PAYMENT_ORDER_AMOUNT": "Monto en efectivo a pagar en la puerta por los productos entregados",
      "MIA_CASH_AMOUNT_DISCLAIMER": "Este es el monto en efectivo por los productos transportados que el conductor debe cobrar en la puerta. Deja vacío o 0 si no deseas cobrar efectivo en la puerta.",
      "ASK_FOR_DELIVERY_FEE": "¿El cliente pagará el cargo de envío en la puerta?",
      "MIA_CASH_AMOUNT_VALUE_DELIVERED_DISCLAIMER": "Marca si deseas que el cliente pague los cargos de envío en efectivo en la puerta.",

      // invoices
      "PAID": "Pagado",
      "PAYMENT": "Pago",
      "CREDIT_CARD": "Tarjeta de Crédito",
      "MANUAL": "Pago manual",
      "FROM_INVOICE": "Desde",
      "TO_INVOICE": "Hasta",
      "INVOICE_NUMBER": "Número de Boleta",
      "INVOICE_FILES": "Archivos",

      "SAVE_CARD": "Guardar Tarjeta",
      "CARD_IS_SET": "Tu tarjeta está configurada. No es necesaria ninguna otra acción a menos que hayas recibido un correo de error de pago fallido, en cuyo caso deberías ingresar otra tarjeta.",
      "SET_CARD": "Configurar o modificar tarjeta de crédito",
      "SAVE_CARD_SUCCESS": "Tu tarjeta ha sido guardada exitosamente, se intentará el pago al día siguiente si tenías una boleta pendiente.",
      "SAVE_CARD_FAILURE": "Tu tarjeta fue rechazada o necesita autenticación adicional. Intenta con otra tarjeta y/o completa el proceso de autenticación correctamente.",
      "PAYMENT_FOOTNOTE_1": "Al usar nuestros servicios de iniciación de pagos, aceptas que nos das el permiso necesario para procesar pagos en tu nombre.",
      "PAYMENT_FOOTNOTE_2": "Al usar nuestros servicios, aceptas una frecuencia mensual de pagos, a menos que se comunique lo contrario.",
      "PAYMENT_FOOTNOTE_3": "Al usar nuestros servicios, aceptas que el monto del pago será determinado según el uso de nuestros servicios, términos acordados y comunicados previamente vía correo electrónico."
    }
  },
};
