import "perfect-scrollbar/css/perfect-scrollbar.css";

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
// dinamically create dashboard routes
import dashboardRoutes from "../../routes/dashboard.jsx";
import i18n from "../../i18n";
// style for notifications
import { style } from "../../variables/Variables.jsx";

let ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
      currentLanguage: {
        Key: i18n.language,
        Label: i18n.language.toUpperCase(),
      },
    };
  }
  changeLanguage = (key) => {
    if (key){
      i18n.changeLanguage(key);
      this.setState({currentLanguage:{'Key':key,'Label':key.toUpperCase()}}); 
    }
  };
  componentDidMount() {
    this.changeLanguage();
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15,
    });
  }
  render() {
    
    const sidebar = this.props.location.pathname !== "/live_tracker" ? <Sidebar
    {...this.props}
    changeLanguage={this.changeLanguage}
    currentLanguage={this.state.currentLanguage.Label}
  /> : null;

    const header = this.props.location.pathname !== "/live_tracker" ?  <Header
    {...this.props}
    changeLanguage={this.changeLanguage}
    currentLanguage={this.state.currentLanguage.Label}
  />: null;

const footer = this.props.location.pathname !== "/live_tracker" ? <Footer fluid transparent/>: null;

    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
       {sidebar}
        <div
          className={this.props.location.pathname !== "/live_tracker" ? "main-panel" : ""}
          ref="mainPanel"
        >
         {header}
          {/*<div style={cutomCss}>*/}
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              if (prop.collapse) {
                return prop.views.map((prop, key) => {
                  if (prop.name === "Notifications") {
                    return (
                      <Route
                        path={prop.path}
                        key={key}
                        render={(routeProps) => (
                          <prop.component
                            {...routeProps}
                            handleClick={this.handleNotificationClick}
                          />
                        )}
                      />
                    );
                  } else {
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  }
                });
              } else {
                if (prop.redirect)
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                else
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
              }
            })}
          </Switch>
          {/*</div>*/}
          {footer}
        </div>
      </div>
    );
  }
}

export default Dashboard;

