import "./AdsBar.css";

import React, { Component } from "react";

import EvaButton from "../../../CustomComponents/Button/EvaButton";
import { Trans } from "react-i18next";
import img from "../../../../../assets/img/tracking_link_ad_1.png";

class AdsBar extends Component {
  onBtnClick = () => {
    window.open("https://eva.coop/#/download", "_blank");
  };

  render() {
    return (
      <div className="ad-container">
        <img className="ad-img" src={img} alt="PHONES" />
        <span className="ride-share-app"><Trans>EVA_RIDESHARE_APP</Trans></span>
        <span className="ride-share-app"><Trans>JOIN_THE_MOVEMENT</Trans></span>
        <EvaButton
          action={this.onBtnClick}
          label={<Trans>DOWNLOAD_OUR_APPS</Trans>}
        />
      </div>
    );
  }
}

export default AdsBar;

