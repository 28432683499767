import * as actionType from "../actions/action_types";

const initialState = {
  impersonated_partner: null,
  partners: [],
}


const PartnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case actionType.IMPERSONATE_PARTNER:
      return {
        ...state,
        impersonated_partner: action.payload,
      };
    default:
      return state;
  }
};

export default PartnersReducer;
