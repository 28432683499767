import { Trans } from "react-i18next";
import React from "react";

export const RideRequestStatus = {
    SUCCESS: 'SUCCESS',
    LIVE: 'LIVE',
    CANCELLED_BY_RIDER: 'CANCELLED_BY_RIDER',
    CANCELLED_BY_DRIVER: 'CANCELLED_BY_DRIVER',
    NO_DRIVER: 'NO_DRIVER',
    UNKNOWN_CANCELLATION: 'UNKNOWN_CANCELLATION',
    FAILED: 'FAILED',
    CANCELLED_BY_COMMUNITY: 'CANCELLED_BY_COMMUNITY',
    INVALID: 'INVALID',
    NOT_YET_ACCEPTED: 'NOT_YET_ACCEPTED',
    SCHEDULED_FOR_LATER: 'SCHEDULED_FOR_LATER',
    LIVE_OTHER_STEP: 'LIVE_OTHER_STEP'
}

export const RideRequestStatusV2 = {
    SCHEDULED: 1,
    DISPATCHING: 2,
    IN_TRANSIT_PICKUP: 3,
    ARRIVED_PICKUP: 4,
    TRANSIT_DESTINATION: 5,
    ARRIVED_DESTINATION: 6,
    RETURNING: 7,
    COMPLETED: 8,
    CANCELED: 9,
    RETURNED: 10, 
}

export const RideRequestStatusV2_LABELS = {
    1: <Trans>SCHEDULED_STATUS</Trans>,
    2: <Trans>DISPATCHING_STATUS</Trans>,
    3: <Trans>TRANSIT_PICKUP</Trans>,
    4: <Trans>ARRIVED_PICKUP</Trans>,
    5: <Trans>TRANSIT_DESTINATION</Trans>,
    6: <Trans>ARRIVED_DESTINATION</Trans>,
    7: <Trans>RETURNING</Trans>,
    8: <Trans>COMPLETED</Trans>,
    9: <Trans>CANCELLED</Trans>,
    10: <Trans>RETURNED</Trans>,
};
