import { Component } from "react";
import React from "react";
import logo from "../../assets/img/eva.png";

export default class LoadingIcon extends Component {
  state = {
    opacity: 90,
    increasing: false,
  };

  interval = null;

  componentDidMount() {
    this.interval = setInterval(() => {
      let opacity = this.state.opacity;
      let increasing = this.state.increasing;

      if (opacity >= 100) {
        this.setState({ increasing: false });
      }

      if (opacity <= 0) {
        this.setState({ increasing: true });
      }

      this.setState({ opacity: increasing ? opacity + 1 : opacity - 1 });
    }, 15);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        style={{
          margin: "20% auto",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img
            src={this.props.icon ? this.props.icon : logo}
            alt="LOADING_ICON"
            style={{ opacity: this.state.opacity / 100, maxHeight: "100px" }}
          />
        </div>
      </div>
    );
  }
}

