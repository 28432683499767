import * as actionType from "../actions/action_types";
import { CommunitySettingCodes, CommunitySettingDefaults } from "../const/CommunitySettings";
import { removeAccents } from "../views/util";

const initialState = null;

const CommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_COMMUNITY:
      let communitiesMap = {};
      for (const [communityName, communityData] of Object.entries(action.payload)) {
        const communitySettings = JSON.parse(communityData.settings);
        const currencySetting = communitySettings.find(e => e.code === CommunitySettingCodes.CURRENCY);
        const comm_currency_code = currencySetting && currencySetting.value || CommunitySettingDefaults.CURRENCY;
        communitiesMap[removeAccents(communityName)] = {
          comm_name: removeAccents(communityData.comm_name),
          comm_info: communityData.cie_info,
          comm_currency_code: comm_currency_code
        };
      }
      return communitiesMap;
    case actionType.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default CommunityReducer;
