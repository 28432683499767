import React, { Component } from "react";

import LiveTracker from "./LiveTracker";
import QueryString from "query-string";

class LiveTrackerPublic extends Component {


  render() {
    let trackingId =null;
    if (this.props.location){
      let parser = QueryString.parse(this.props.location.search);
      trackingId = parser.tracking_id;
      
    } else {
      trackingId = this.props.tracking_id;
    }
    return (
      <LiveTracker
        {...this.props}
        public
        tracking_id={trackingId}
      />
    );
  }
}

export default LiveTrackerPublic;

