import React, { Component } from "react";
import styles from "./OrderRideStyle.module.css";
import { Col, Grid, Row } from "react-bootstrap";
import i18n from "i18next";
import classnames from "classnames";
import PhoneInput from 'react-phone-input-2';
import { removeAccents } from '../util';

class OrderRide2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
      phoneError: false,
      nameError: false
    }
  }

  handleChangePhoneExtension = (e) => {
    if (!isNaN(e.target.value) || e.target.value.length === 0) {
      this.props.onChange({ [e.target.name]: e.target.value });
    }
  }

  validateForm = () => {
    let valid = true;
    const { customerEmail, customerPhone, customerFirstName } = this.props.data;
    
    if (customerEmail && !customerEmail.match(/[\w-]+@([\w-]+\.)+[\w-]+/)) {
      this.setState({ emailError: true });
      valid = false;
    }

    if (customerPhone && (customerPhone.length < 7 || customerPhone.length > 15)) {
      this.setState({ phoneError: true });
      valid = false;
    }

    if (customerFirstName && customerFirstName.length < 1) {
      this.setState({ nameError: true });
      valid = false;
    }

    return valid;
  }

  render() {
    const {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhone,
      customerPhoneExt,
      customerNote
    } = this.props.data;
    const { onChange } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <Grid className={styles.formContainer}>
          <Row>
            <Col md={12}>
              <div className={styles.center}>
                <p className={styles.optionalTitle}>
                  {i18n.t("CLIENT_TITLE")}
                </p>
                <div className={styles.line6} />
                <p className={styles.subtitle}>
                  {i18n.t("SENDING_EMAIL_SMS_MESSAGE")}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p className={styles.formTitle}>
                {i18n.t("FIRST_NAME")}
              </p>
              <input
                name="customerFirstName"
                value={removeAccents(customerFirstName)}
                placeholder={i18n.t("FIRST_NAME_PLACEHOLDER")}
                className={classnames(styles.inputForm, this.state.nameError ? styles.inputFormError : "")}
                tabIndex={1}
                onChange={(e) => {
                  if (this.state.nameError) {
                    this.setState({ nameError: false });
                  }
                  onChange({ [e.target.name]: removeAccents(e.target.value) })
                }}>
              </input>
            </Col>
            <Col md={6}>
              <p className={styles.formTitle}>
                {i18n.t("LAST_NAME")}
              </p>
              <input
                name="customerLastName"
                value={removeAccents(customerLastName)}
                placeholder={i18n.t("LAST_NAME_PLACEHOLDER")}
                className={styles.inputForm}
                tabIndex={2}
                onChange={(e) => onChange({ [e.target.name]: removeAccents(e.target.value) })}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className={styles.formTitle}>
                {i18n.t("EMAIL")}
              </p>
              <input
                name="customerEmail"
                value={customerEmail}
                placeholder={i18n.t("EMAIL_PLACEHOLDER")}
                className={classnames(styles.inputForm, this.state.emailError ? styles.inputFormError : "")}
                tabIndex={3}
                onChange={(e) => {
                  if (this.state.emailError) {
                    this.setState({ emailError: false });
                  }
                  onChange({ [e.target.name]: e.target.value })
                }}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <p className={styles.formTitle}>
                {i18n.t("TELEPHONE")}
              </p>
              <PhoneInput
                inputProps={{
                  name: "customerPhone",
                  tabIndex: 5,
                }}
                value={customerPhone}
                inputClass={classnames(styles.inputForm, styles.phoneInput, this.state.phoneError ? styles.inputFormError : "")}
                country="ca"
                masks={{"ci": '.. .. .. .. ..'}}
                specialLabel=""
                preferredCountries={['ca']}
                placeholder={i18n.t("TELEPHONE_PLACEHOLDER")}
                onChange={(value) => {
                  if (this.state.phoneError) {
                    this.setState({ phoneError: false });
                  }
                  onChange({ customerPhone: value });
                }}
                containerClass={styles.phoneInputContainer}
                buttonClass={styles.phoneDropdownButton}
              />
            </Col>
            <Col md={3}>
              <p className={classnames(styles.formTitle, styles.fieldNoHeader)}></p>
              <input
                name="customerPhoneExt"
                value={customerPhoneExt}
                placeholder={i18n.t("TEL_EXTENSION_PLACEHOLDER")}
                className={styles.inputForm}
                tabIndex={5}
                onChange={(e) => this.handleChangePhoneExtension(e)}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <p className={styles.formTitle}>
              {i18n.t("NOTE")}
              </p>
              <textarea
                name="customerNote"
                rows={3}
                placeholder={i18n.t("CUSTOMER_NOTE_PLACEHOLDER")}
                className={styles.inputForm}
                tabIndex={6}
                onChange={(e) => onChange({ [e.target.name]: removeAccents(e.target.value) })}
                value={removeAccents(customerNote)}>
              </textarea>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            <Disclaimer />
            </Col>
            <Col md={12}>
              <button className={styles.nextBtn} onClick={() => {
                if (this.validateForm()) this.props.nextStepClick();
              }}>
                <p className={styles.nextText}>
                  {i18n.t("NEXT")}
                </p>
              </button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

function Disclaimer() {
  if (i18n.language == 'fr') {
    return <p>En poursuivant, vous reconnaissez avoir lu la <a target="_blank" href="https://www.goeva.com/privacy-policy" className="underline hover:text-primary">politique de confidentialité</a> d'Eva.</p>
  }

  return <p>By proceeding, you acknowledge that you have read Eva's <a target="_blank" href="https://www.goeva.com/privacy-policy" className="underline hover:text-primary">Privacy Policy</a>.</p>;
}

export default OrderRide2;