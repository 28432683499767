import "./RideScreen.css";
import { RIDE_SHARING } from "../../../../const/RideTypes";
import React, { Component } from "react";
import AddressBar from "./AddressBar/AddressBar";
import AdsBar from "./AdsBar/AdsBar";
import DriverBar from "./DriverBar/DriverBar";
import { RideRequestStatus } from "../../../../const/RideRequestStatus";
import Separator from "../../CustomComponents/Separator/Separator";
import StatusBar from "./StatusBar/StatusBar";
import { Trans } from "react-i18next";
import NoteBar from "./NoteBar/NoteBar";

class RideScreen extends Component {
  state = {
    helpScreen: false,
  };

  componentDidMount() {
    const displayDriver =
      this.props.requestStatus === RideRequestStatus.LIVE || RideRequestStatus.LIVE_OTHER_STEP ||
      this.props.requestStatus === RideRequestStatus.SUCCESS;
    this.setState({ displayDriver });
  }

  render() {
    const registeredTimes = {
      expectedDeliveredByDatetime: (this.props.expectedDeliveredByDatetime) || '',
      acceptedTime: (this.props.activeRide && this.props.activeRide.accepted_time) || '',
      onSiteTime: (this.props.activeRide && this.props.activeRide.driver_onsite_time) || '',
      towardDestinationTime: (this.props.activeRide && this.props.activeRide.start_time) || ''
    }

    return (
      <div className="ride-screen-wrapper">
        <div className="title">
          <h1 style={{ color: this.props.style && this.props.style.primaryColor }}>
            <Trans>ORDER_STATUS</Trans>
          </h1>
          <Separator style={this.props.style} />
        </div>

        <section className="tracking-link-status-container">
          <div className="status-title">
            <h4><Trans>ORDER_NUMBER</Trans></h4>
            <h4 style={{ color: this.props.style && this.props.style.primaryColor }}>{this.props.order_number}</h4>
          </div>
        </section>
            <Separator style={this.props.style} />

        <StatusBar
          eta={this.props.eta}
          timeToPickupLocation={this.props.timeToPickupLocation}
          timeFromPickupLocationToDestination={this.props.timeFromPickupLocationToDestination}
          status={this.props.status}
          rideServiceTypeId={this.props.rideServiceTypeId}
          requestStatus={this.props.requestStatus}
          successDatetime={this.props.successDatetime}
          registeredTimes={registeredTimes}
          style={this.props.style}
          isMiaRide={this.props.isMiaRide}
        />
        <Separator style={this.props.style} />
        {this.props.driverProfile && 
          <>
            <DriverBar
              showProxyPopup={this.props.showProxyPopup}
              driverProfile={this.props.driverProfile}
              driverImage={this.props.driverImage}
              style={this.props.style}
              driverVehicle={this.props.activeRide && this.props.activeRide.driver_vehicle}
              showDemo={this.props.showDemo}
              callerPhone={this.props.callerPhone}
              community={this.props.activeRide.comm}
            />
            <Separator style={this.props.style} />
          </>
        }
        <AddressBar
          version={this.state.displayDriver ? 1 : 0}
          fromAddress={this.props.pickup.address}
          toAddress={this.props.destination.address}
          style={this.props.style}
        />
        <Separator style={this.props.style} />
        {this.props.note &&
          <>
            <NoteBar
              note={this.props.note}
              style={this.props.style}
            />
            <Separator style={this.props.style} />
          </>
        }
        {this.props.isBusiness ||
          <AdsBar />
        }
      </div>
    );
  }
}

export default RideScreen;

