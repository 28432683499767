import ridesharing from "../assets/img/ridesharing.png";
import delivery from "../assets/img/delivery.png";
import cannabis from "../assets/img/cannabis.png";
import bike from "../assets/img/bike.png";
import bulk from "../assets/img/van.png";
import ambulance from "../assets/img/ambu.png";
import lux from "../assets/img/comfort.png";
import moto from "../assets/img/vespa.png";
import RideServiceNames from "./RideServiceNames";

export const RideTypes = [
    "",
    "RIDE_SHARING",
    "DELIVERY",
    "CANNABIS",
    "BULK",
    "DELIVERY_SERVICE_BIKE",
    "DELIVERY_SERVICE_MOTORCYCLE",
    "DELIVERY_SERVICE_AMBULANCE",
    "RIDE_SHARING_SERVICE_LUX",
    "RIDE_SHARING_MOTO",
    "DELIVERY_SERVICE_TRICYCLE",
    "RIDE_SHARING_SERVICE_TRICYCLE",
    "SHUTTLE"
]

export const RIDE_SHARING = 1;
export const DELIVERY = 2;
export const CANNABIS = 3;
export const BULK = 4;
export const DELIVERY_SERVICE_BULK = 4;
export const DELIVERY_SERVICE_AMBULANCE= 5;

export const RideTypeIds = {
    RIDE_SHARING: RIDE_SHARING,
    DELIVERY: DELIVERY,
    CANNABIS: CANNABIS, 
    BULK: BULK,
    DELIVERY_SERVICE_AMBULANCE: DELIVERY_SERVICE_AMBULANCE,
    DELIVERY_SERVICE_BULK: DELIVERY_SERVICE_BULK
}

export const RideServiceInfos = {
    1: {
        image: ridesharing,
        text: RideServiceNames[1]
    },
    2: {
        image: delivery,
        text: RideServiceNames[2]
    },
    3: {
        image: cannabis,
        text: RideServiceNames[3]
    },
    4: {
        image: bulk,
        text: RideServiceNames[4]
    },
    5: {
        image: ambulance,
        text: RideServiceNames[5]
    }
}