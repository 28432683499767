import axios from "axios";
import { logout } from "../actions/index";
import { store } from "../store/index";

const evaApiAddress = "http://localhost:5000/";

const instance = axios.create({
  baseURL: evaApiAddress,
  timeout: 8000,
});

const encodeGetParams = (params) =>
  Object.entries(params)
    .map((kv) => kv.map(encodeURIComponent).join("="))
    .join("&");

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

export function sendLoggedGET(endpoint, ignore404 = true) {
  console.log('evaApiAddress', evaApiAddress);

  const url = `${evaApiAddress}${endpoint}`;

  console.log('url', url);

  return instance
    .get(url, {
      params: {},
      timeout: 8000,
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      switch (error.response.status) {
        case 401:
          store.dispatch(logout());
          break;
        case 404:
          if (!ignore404) {
            window.location.href = "/not_found";
            return;
          } else {
            throw error;
          }
          break;
        default:
          throw error;
      }
    });
}

export function getMicrosoftAuthUrl(client_id = process.env.REACT_APP_MS_SAML_CLIENT) {
  const endpoint = `sso/${client_id}/init`;
  console.log('client_id', client_id);
  console.log('endpoint', endpoint);

  return sendLoggedGET(endpoint)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export async function getPhoneProxy(rider, driver, comm) {
  const params = encodeGetParams({
    rider_phone: rider,
    driver_phone: driver,
    community: comm,
  });

  return sendLoggedGET(`proxy_connection?${params}`)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
