import "./Invalid.css";

import React, { Component } from "react";

import AdsBar from "../Drawer/RideScreen/AdsBar/AdsBar";
import Separator from "../CustomComponents/Separator/Separator";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { formatPhoneNumber } from "../../../helpers/viewsHelper";

class Invalid extends Component {
  render() {
    return (
      <div className="invalid-link-container">
        <div className="invalid-link-title">
          <Trans>LINK_PROVIDED</Trans>{" "}
          <span style={{borderColor: this.props.style && this.props.style.primaryColor}} className="underlined-eva"><Trans>NOT_VALID</Trans></span>
        </div>
        <div className="invalid-link-midsection-wrapper">
          <div className="main-text-container">
            <div className="main-text-title"><Trans>URL_CORRECT</Trans></div>
            <div className="main-text-paragraph">
              {/* <Trans>FOR_MORE_INFO</Trans>:<br /><br />
              <a href={"tel:" + this.props.communityInfo.en.phone} className="contact-link">
                {formatPhoneNumber(this.props.communityInfo.en.phone)}
              </a> */}
            </div>
            <div className="main-text-contact">
              <a href="contactus@goeva.com" className="contact-link">
                contactus@goeva.com
              </a>
            </div>
          </div>
        </div>
        <div className="ads-div-invalid">
        <Separator style={this.props.style} />
        
        </div>
        <div className="marketing-wrapper">
            <div className="marketing-container">
          <AdsBar />
        </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //communityInfo: JSON.parse(state.community.comm_info),
  };
};

export default connect(mapStateToProps)(Invalid)