//Styles
import "./RidePreviewStyles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { dayMapstyle, nightMapstyle } from "../../const/MapBoxStyles";

import { Component } from "react";
import DeckGL from "@deck.gl/react";
import React from "react";
import { StaticMap } from "react-map-gl";
import { Trans } from "react-i18next";
import { getOSRMDirections } from "../../helpers/routingHelper";
import { isDay } from "../../helpers/viewsHelper";
import {
  mapboxToken,
} from "../../variables/secretEnvVariables";
import pickup_pin_day from "../../assets/img/pickup_pin_new.png";
import pickup_pin_night from "../../assets/img/pickup_pin_night.png";
//Images
import pin from "../../assets/img/pin_new.png";

/**
 * Shows a from/to map for an ordered ride preview
 */
export default class RidePreviewMap extends Component {
  isDay = isDay();

  state = {
    viewState: {
        latitude: 0,
        longitude: 0,
        zoom: 10,
      },
    points: [],
    mapStyle: this.isDay ? dayMapstyle : nightMapstyle,
    fixedEta: null,
    distance: null
  };

  async componentDidMount() {
    const viewState = {
        latitude: this.props.fromCoords.lat,
        longitude: this.props.fromCoords.lng,
        zoom: 10,
      };
    this.setState({
      viewState,
      fixedEta: this.props.fixedEta || null,
      distance: this.props.distance || null,
    });

    await this.setDirections();
    await this.setPoints();
  }

  renderTooltip = () => {
    const { hoveredObject, pointerX, pointerY } = this.state || {};
    return (
      hoveredObject && (
        <div
          style={{
            padding: "20px",
            color: "white",
            backgroundColor: "black",
            position: "absolute",
            zIndex: 5,
            pointerEvents: "none",
            left: pointerX,
            top: pointerY,
          }}
        >
          {hoveredObject.name} <br /> {hoveredObject.address}
        </div>
      )
    );
  };

  setDirections = async () => {
    const fromCoords = [this.props.fromCoords.lon, this.props.fromCoords.lat];
    const toCoords = [this.props.toCoords.lon, this.props.toCoords.lat];
    const points = [fromCoords, toCoords];
    console.log('points', points);
    const { directions, timeEstimation } = await getOSRMDirections(points);
    await this.setState({ directions, eta: timeEstimation });
  };

  setPoints = async () => {
    const { fromCoords, toCoords } = this.props;
    const points = [
      {
        coordinates: [fromCoords.lng, fromCoords.lat],
        name: <Trans>START_LOCATION</Trans>,
        address: this.props.startLocation,
        img: this.isDay ? pickup_pin_day : pickup_pin_night,
        size: 5,
        width: 128,
        height: 128,
        anchorY: 128,
      },
      {
        coordinates: [toCoords.lng, toCoords.lat],
        name: <Trans>DESTINATION</Trans>,
        address: this.props.endLocation,
        img: pin,
        size: 5,
        width: 128,
        height: 128,
        anchorY: 128,
      }
    ];
    this.setState({ points });
  };

  onViewStateChange({ viewState }) {
    this.setState({ viewState });
  }

  render() {
    const eta = (
      <div className="over-map-div" id="eta-label">
        <b>
          <Trans>BILLED_MINUTES</Trans>: {this.state.fixedEta || this.state.eta} <Trans>min</Trans>
          <br />
          <Trans>BILLED_DISTANCE</Trans>: {this.state.distance} <Trans>km</Trans>
        </b>
      </div>
    );

    const layers = [
      new GeoJsonLayer({
        id: "directions",
        data: this.state.directions,
        opacity: 1,
        stroked: false,
        filled: false,
        lineWidthMinPixels: 5,
        parameters: {
          depthTest: false,
        },
        getLineColor: [0,81,184],
        getLineWidth: 5,
        pickable: true,
      }),

      new IconLayer({
        id: "points",
        data: this.state.points,
        pickable: true,
        getIcon: (d) => ({
          url: d.img,
          width: d.width,
          height: d.height,
          anchorY: d.anchorY,
        }),
        getAngle: (d) => d.angle,
        sizeScale: 15,
        getPosition: (d) => d.coordinates,
        getSize: (d) => d.size,
        onHover: (info) =>
          this.setState({
            hoveredObject: info.object,
            pointerX: info.x,
            pointerY: info.y,
          }),
      }),
    ];

    return (
      <div height='100%' style={{position: 'relative'}}>
        {eta}
        <DeckGL
          layers={layers}
          viewState={this.state.viewState}
          controller={true}
          pickingRadius={5}
          height="50vh"
          onViewStateChange={(viewState) => this.onViewStateChange(viewState)}
        >
          <StaticMap
            reuseMaps
            preventStyleDiffing={true}
            mapStyle={this.state.mapStyle}
            mapboxApiAccessToken={mapboxToken}
          />
          {this.renderTooltip()}
        </DeckGL>
      </div>
    );
  }
}

