import AccountSettings from "../views/AccountSettings/AccountSettings.jsx";
import Error404 from "../views/Errors/Error404"
import Home from "../views/Home/Home.jsx";
import LiveTrackerList from "../views/LiveTracker/LiveTrackerList.jsx";
import LiveTrackerMia from "../views/LiveTracker/LiveTrackerMia.jsx";
// import OrderRideWrapper from "../views/OrderRide/OrderRideWrapper.jsx";
import OrderRideWrapper from "../views/OrderRide/OrderRideWrapper.jsx";
import React from "react";
import RideHistory from "../views/RideHistory/RideHistory.jsx";
import BulkRideUpload from "../views/BulkRideUpload/BulkRideUpload.jsx";
import { Trans } from "react-i18next";
import WhiteLabelList from "../views/WhiteLabel/WhiteLabelList.jsx";
import WhiteLabelForm from "../views/WhiteLabel/WhiteLabelForm.jsx";
import Payment from "../views/Payment/Payment.jsx";

var dashboardRoutes = [
  {
    path: "/home",
    name: <Trans>HOME</Trans>,
    icon: "pe-7s-graph",
    component: Home
  },
  {
    path: "/live_tracker_list",
    name: <Trans>LIVETRACKER</Trans>,
    icon: "pe-7s-bell",
    component: LiveTrackerList
  },
  {
    path: "/live_tracker",
    name: <Trans>LIVETRACKER</Trans>,
    hideOnSidebar: true,
    component: LiveTrackerMia
  },
  {
    path: "/not_found",
    hideOnSidebar: true,
    name: <Trans>404</Trans>,
    component: Error404
  },
  {
    path: "/order_ride",
    name: <Trans>ORDER_RIDE</Trans>,
    icon: "pe-7s-way",
    component: OrderRideWrapper
  },
  {
    path: "/bulk_upload",
    name: <Trans>IMPORT_ORDERS</Trans>,
    icon: "pe-7s-file",
    component: BulkRideUpload,
    hideOnSidebar: true

  },
  {
    path: "/history",
    name: <Trans>RIDE_HISTORY</Trans>,
    icon: "pe-7s-car",
    component: RideHistory
  },
  //{
  //  path: "/white_label_list",
  //  name: <Trans>WHITE_LABEL</Trans>,
  //  icon: "pe-7s-pen",
  //  component: WhiteLabelList
  //},
  {
    path: "/invoices",
    name: <Trans>PAYMENT</Trans>,
    icon: "pe-7s-cash",
    component: Payment
  },
  //{
  //  path: "/white_label/:white_label_id",
  //  hideOnSidebar: true,
  //  name: <Trans>WHITE_LABEL</Trans>,
  //  component: WhiteLabelForm,
  //},
  //{
  //  path: "/account_settings",
  //  name: <Trans>ACCOUNT_SETTINGS</Trans>,
  //  icon: "pe-7s-settings",
  //  component: AccountSettings
  //},
  {
    path: "/",
    name: <Trans>HOME</Trans>,
    component: Home,
    hideOnSidebar: true
  },
];
export default dashboardRoutes;
