import React, { Component } from "react";
import { getTrackingStylesPublic } from "../../helpers/communityApiHelper";
import LiveTracker from "./LiveTracker";
import QueryString from "query-string";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import { DAY, NIGHT } from "../WhiteLabel/Constants";
import { RideRequestStatus } from "../../const/RideRequestStatus";
import TabSelector from "../WhiteLabel/components/TabSelector";
import "./LiveTracker.css";

class LiveTrackerDemoPublic extends Component {
  state = {
    activeStyle: null,
    timeOfDay: DAY,
    view: RideRequestStatus.LIVE,
  };

  componentDidMount = async () => {
    const style_id = QueryString.parse(this.props.location.search).style_id;
    const rider_account = QueryString.parse(
      this.props.location.search
    ).rider_account;
    const trackingLinkStyles = await getTrackingStylesPublic(rider_account);
    const activeStyle = trackingLinkStyles.find(
      (e) => parseInt(e.id) === parseInt(style_id)
    );
    this.setState({ activeStyle });
  };

  render() {
    if (!this.state.activeStyle) return null;
    const style = this.state.activeStyle;
    style["refreshId"] = Math.random();
    style["timeOfDay"] = this.state.timeOfDay;
    style["forceMapUpdate"] = true;

    return (
      <div>
        <div className="live-tracker-demo-control-panel">
          <div className="demo-control-row">
            <div className="demo-center-text">
              <Trans>TIME_OF_DAY</Trans>:
            </div>
            <TabSelector
              tabs={[DAY, NIGHT]}
              value={this.state.timeOfDay}
              onChange={(e) => {
                this.setState({ timeOfDay: e });
              }}
            />
          </div>
          <div className="demo-control-row">
            <div className="demo-center-text">
              <Trans>View</Trans>:
            </div>
            <select
              className="white-label-form-dropdown"
              value={this.state.view}
              onChange={(e) => {
                this.setState({ view: e.target.value });
              }}
            >
              <option value={RideRequestStatus.LIVE}>{i18n.t("LIVE")}</option>
              <option value={RideRequestStatus.SCHEDULED_FOR_LATER}>
                {i18n.t("SCHEDULED_FOR_LATER")}
              </option>
              <option value={RideRequestStatus.CANCELLED_BY_RIDER}>
                {i18n.t("UNKNOWN_CANCELLATION")}
              </option>
              <option value={RideRequestStatus.SUCCESS}>
                {i18n.t("SUCCESS")}
              </option>
            </select>
          </div>
        </div>
        <LiveTracker
          {...this.props}
          showDemo={{
            showPage: this.state.view,
            style: style,
            refreshId: Math.random(),
          }}
        />
      </div>
    );
  }
}

export default LiveTrackerDemoPublic;
