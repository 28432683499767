import "./ProgressionTracker.css";

import React, { Component } from "react";

import { LiveRideStatus } from "../../../../../const/ActiveRideStatus";
import { RIDE_SHARING, DELIVERY, CANNABIS } from "../../../../../const/RideTypes";
import { RideRequestStatus } from "../../../../../const/RideRequestStatus";
import { Trans } from "react-i18next";
import classNames from 'classnames';
import moment from 'moment';
import Moment from 'react-moment';

class StatusBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      atDestinationTime: ''
    };
  }

  formatTime = (timeToFormat) => {
    let formattedTime;

    if (typeof timeToFormat !== 'undefined' && timeToFormat !== null && timeToFormat !== '') {
      const timeFormat = 'HH[h]mm';
      formattedTime = moment.utc(timeToFormat).local().format(timeFormat);
    } else {
      formattedTime = '';
    }

    return formattedTime;
  }

  render() {
    // Faking destination arrival time since it's not returned by the API (and may not exist)
    if (this.state.atDestinationTime === '') {
      this.setState({ atDestinationTime: moment() })
    };

    const stepsList = [
      {
        isVisible: true,
        iconName: "car-icon",
        translationKey: "ON_WAY_TOWARDS_PICKUP",
        timeRecorded: this.props.registeredTimes.acceptedTime,
        minutesToNextStep: this.props.timeToPickupLocation,
      },
      {
        isVisible: true,
        iconName: "delivery-icon",
        translationKey: "PICKING_UP_PACKAGE",
        timeRecorded: this.props.registeredTimes.onSiteTime,
        minutesToNextStep: null,
      },
      {
        isVisible: this.props.rideServiceTypeId !== RIDE_SHARING,
        iconName: "route-icon",
        translationKey: "OUT_FOR_DELIVERY",
        // towardDestinationTime normally shows in ON_WAY_TOWARDS_RIDER_DESTINATION in a single destination ride.
        timeRecorded: this.props.registeredTimes.towardDestinationTime,
        minutesToNextStep: null,
      },
      {
        isVisible: true,
        iconName: "car-icon",
        translationKey: "ON_WAY_TOWARDS_PACKAGE_DESTINATION",
        // towardDestinationTime doesn't shows in multi-destination rides.
        timeRecorded: this.props.isMultiDestination ? null : this.props.registeredTimes.towardDestinationTime,
        minutesToNextStep: this.props.timeFromPickupLocationToDestination,
      },
      {
        isVisible: true,
        iconName: "flag-icon",
        translationKey: "ARRIVED_PACKAGE_DESTINATION",
        timeRecorded: this.state.atDestinationTime,
        minutesToNextStep: null,
      },
    ];

    let currentStep = 1;
    if (this.props.requestStatus === RideRequestStatus.SUCCESS) {
      currentStep = stepsList.length;
    } else if (this.props.requestStatus === RideRequestStatus.LIVE || this.props.requestStatus === RideRequestStatus.LIVE_OTHER_STEP) {
      switch (this.props.status) {
        case LiveRideStatus.ON_WAY_TOWARDS_PICKUP:
          currentStep = 1;
          break;
        case LiveRideStatus.PICKING_UP_PACKAGE:
          currentStep = 2;
          break;
        case LiveRideStatus.OUT_FOR_DELIVERY:
          currentStep = 3;
          break;
        case LiveRideStatus.ON_WAY_TOWARDS_DESTINATION:
          currentStep = 4;
          break;
        case LiveRideStatus.ARRIVED:
          currentStep = 5;
          break;
        default:
          break;
      }
    }

    const getTimeEstimationComponent = () => {
      let componentToReturn = null;
      switch (this.props.rideServiceTypeId) {
        case RIDE_SHARING:
          componentToReturn = <Trans i18nKey="RIDE_ESTIMATION" components={[<strong><Moment format='HH[h]mm' add={{ minutes: this.props.eta }} /></strong>]} />
          break;
        case CANNABIS:
          const expectedDeliveredByDatetimeFormatted = this.formatTime(this.props.registeredTimes.expectedDeliveredByDatetime);
          const expectedDeliveredByDatetime = moment(expectedDeliveredByDatetimeFormatted, "HH[h]mm").format("HH[h]mm");
          componentToReturn = <Trans i18nKey="DELIVERY_ESTIMATION" components={[<strong>{expectedDeliveredByDatetime}</strong>]} />
          break;
        default:
          //Everything that is not risdesharing or delivery will fall in the default case here as a non-special delivery
          componentToReturn = <Trans i18nKey="DELIVERY_ESTIMATION" components={[<strong><Moment format='HH[h]mm' add={{ minutes: this.props.eta }} /></strong>]} />
      }
      return componentToReturn;
    }

    return (
      <div className="progression-tracker-container">
        <div className='vertical-stepper' style={{ color: this.props.style && this.props.style.textColor}}>
          {stepsList.map((step, index) => {
            const isActive = (index + 1) === currentStep;
            const isDone = (index + 1) < currentStep;
            const isNotLast = (index + 1) < stepsList.length;
            return (
              <>
                {step.isVisible &&
                  <>
                    <div className={classNames("step", { "active-step": isActive }, { "done-step": isDone })} style={{opacity: isActive || '0.7'}}>
                      <div className={step.iconName} style={{ backgroundColor: (isActive || isDone) ? this.props.style && this.props.style.primaryColor : this.props.style && this.props.style.textColor }} />
                      <div className="text-wrapper">
                        <p style={{ color: this.props.style && this.props.style.textColor}}><Trans>{step.translationKey}</Trans></p>
                        <p style={{ color: this.props.style && this.props.style.textColor}}>{(isDone || isActive) && this.formatTime(step.timeRecorded)}</p>
                      </div>
                    </div>
                    {isNotLast &&
                      <div className={classNames("in-between-line", { "done-step-line": isDone })} style={{ borderColor: (isActive || isDone) && this.props.style && this.props.style.primaryColor }}>
                        <span style={{ color: this.props.style && this.props.style.textColor}}>{isActive && step.minutesToNextStep && (`${step.minutesToNextStep} min`)}</span>
                      </div>
                    }
                  </>
                }
              </>
            );
          })}
          <p className="estimated-time-of-arrival" style={{ color: this.props.style && this.props.style.textColor}}>
            {getTimeEstimationComponent()}
          </p>
        </div>
      </div>
    );
  }
}

export default StatusBar;
