import { JsonRpc} from 'eosjs';
import { blockchainNetwork, contractName } from "../variables/secretEnvVariables";

const rpc = new JsonRpc(blockchainNetwork, { fetch });

export async function getCommunity(accountName) {
    return rpc.get_table_rows({
        code:contractName,
        scope:contractName,
        table:'communities',
        index_position: 1,
        key_type: "name",
        lower_bound: accountName,
        limit: 100000000,
        json: true,
    }).then(function(response) {
        let rows = response['rows'];
        for (let i = 0; i< rows.length; i++){
            let row = rows[i];
            if (row.comm_acct !== accountName){
                break
            }
            return row;
        }
    })
};