import "./Map.css";

import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import React, { Component } from "react";
import { dayMapstyle, nightMapstyle } from "../../../const/MapBoxStyles";

import DeckGL from "@deck.gl/react";
import { FlyToInterpolator } from "deck.gl";
import { StaticMap } from "react-map-gl";
import { Trans } from "react-i18next";
import driver_day from "../../../assets/img/driver2.png";
import driver_night from "../../../assets/img/driver.png";
import { isDay } from "../../../helpers/viewsHelper";
import { mapboxToken } from "../../../variables/secretEnvVariables";
import pickup_pin_day from "../../../assets/img/pickup_pin_new.png";
import pickup_pin_night from "../../../assets/img/pickup_pin_night.png";
import pin from "../../../assets/img/pin_new.png";

const bd = require("bigdecimal");

const REFRESH_RATE_SECONDS = 10;

class Map extends Component {
  isDay = isDay();

  firstRender = true;

  state = {
    currentDriverPosition: [0, 0],
    nextDriverPosition: [0, 0],
    latestBearing: 0,
    viewState: {
      latitude: 0,
      longitude: 0,
      zoom: 14,
      bearing: 0,
      pitch: 0
    },
    points: [{ coordinates: [0, 0] }],
    directions: {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [[0, 0]],
        type: "LineString",
      },
    },
    mapStyle: this.isDay ? dayMapstyle : nightMapstyle,
    forceUpdate: false
  };

  async componentDidMount() {
    window.addEventListener("focus", this.onWindowFocus);
    this.loadStyles();
  }

  loadStyles = async () => {

    let carIcon = driver_day,
      carIconNight = driver_night,
      destinationPinIcon = pin,
      destinationPinIconNight = pin,
      pickupPinIcon = pickup_pin_day,
      pickupPinIconNight = pickup_pin_night;

    const { style, directions, pickupDirections } = this.props;

    let mapStyle = this.state.mapStyle;

    if (style) {
      if (style.carIcon) carIcon = style.carIcon;
      if (style.carIconNight) carIconNight = style.carIconNight;
      if (style.destinationPinIcon)
        destinationPinIcon = style.destinationPinIcon;
      if (style.destinationPinIconNight)
        destinationPinIconNight = style.destinationPinIconNight;
      if (style.pickupPinIcon) pickupPinIcon = style.pickupPinIcon;
      if (style.pickupPinIconNight)
        pickupPinIconNight = style.pickupPinIconNight;

    if (style.timeOfDay) {
      this.isDay = style.timeOfDay === "DAY";
      mapStyle = this.isDay ? dayMapstyle : nightMapstyle;
    }
    }

    await this.setState({
      directions: directions || this.state.directions,
      pickupDirections,
      carIcon,
      carIconNight,
      destinationPinIcon,
      destinationPinIconNight,
      pickupPinIcon,
      pickupPinIconNight,
      mapStyle
    });

    if (style && style.forceMapUpdate) {
      this.setState({forceUpdate: true});
      this.forceUpdate();
    setTimeout(() => {
      this.setState({forceUpdate: false});
    }, 10)
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.isSameCoord(
        this.props.nextDriverPosition,
        nextProps.nextDriverPosition
      ) ||
      !this.isSameCoord(
        this.state.currentDriverPosition,
        nextState.currentDriverPosition
      ) ||
      !this.isSameCoord(
        [this.state.viewState.latitude, this.state.viewState.longitude],
        [nextState.viewState.latitude, nextState.viewState.longitude]
      ) ||
      !this.isSameCoord(
        this.state.points[0].coordinates,
        nextState.points[0].coordinates
      ) ||
      (nextProps.directions &&
        this.props.directions &&
        !this.isSameCoord(
          this.state.directions.geometry.coordinates[0],
          nextProps.directions.geometry.coordinates[0]
        )) ||
      this.state.hoveredObject !== nextState.hoveredObject ||
      (this.props.style && this.props.style.refreshId !== nextProps.style.refreshId) ||
      this.state.forceUpdate !== nextState.forceUpdate
    );
  }

  async componentDidUpdate(prevProps) {
    const {
      nextDriverPosition,
      pickup,
      destination,
      directions,
      pickupDirections,
    } = this.props;

    if (this.props.style && this.props.style.refreshId !== prevProps.style.refreshId) {
      this.loadStyles();
    }

    const viewState =
      this.state.viewState.latitude === 0
        ? {
            latitude: parseFloat(pickup.coords[1]),
            longitude: parseFloat(pickup.coords[0]),
            zoom: 13,
            bearing: 0,
            pitch: 0
          }
        : this.state.viewState;

    let currentDriverPosition;
    if (
      this.firstRender ||
      (this.state.currentDriverPosition[0] === 0 &&
        this.state.currentDriverPosition[1] === 0)
    ) {
      currentDriverPosition = nextDriverPosition;
      this.firstRender = false;
    } else {
      currentDriverPosition = this.state.currentDriverPosition;
    }

    await this.setState({
      nextDriverPosition,
      pickup,
      destination,
      directions: directions || this.state.directions,
      pickupDirections,
      viewState,
      currentDriverPosition,
    });
    await this.setPoints();

    if (
      !this.isSameCoord(
        this.props.nextDriverPosition,
        prevProps.nextDriverPosition
      )
    ) {
      this.startAnimation();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("focus", this.onWindowFocus);
    clearInterval(this.animationInterval);
  };

  renderTooltip = () => {
    const { hoveredObject, pointerX, pointerY } = this.state || {};
    return (
      hoveredObject && (
        <div
          style={{
            padding: "20px",
            color: "white",
            backgroundColor: "black",
            position: "absolute",
            zIndex: 5,
            pointerEvents: "none",
            left: pointerX,
            top: pointerY,
          }}
        >
          {hoveredObject.name} <br /> {hoveredObject.address}
        </div>
      )
    );
  };

  setPoints = async () => {
    const { currentDriverPosition, pickup, destination } = this.state;
    const points = [
      {
        coordinates: pickup.coords,
        name: <Trans>PICKUP</Trans>,
        address: pickup.address,
        img: this.isDay
          ? this.state.pickupPinIcon
          : this.state.pickupPinIconNight,
        size: 5,
        width: this.props.style ? this.props.style.pickupPinIconWidth : 128,
        height: this.props.style ? this.props.style.pickupPinIconHeight : 128,
        anchorY: 128,
      },
      {
        coordinates: destination.coords,
        name: <Trans>DESTINATION</Trans>,
        address: destination.address,
        img: this.isDay
          ? this.state.destinationPinIcon
          : this.state.destinationPinIconNight,
        size: 5,
        width: this.props.style
          ? this.props.style.destinationPinIconWidth
          : 128,
        height: this.props.style
          ? this.props.style.destinationPinIconHeight
          : 128,
        anchorY: 128,
      },
    ];

    if (
      this.state.currentDriverPosition &&
      this.state.currentDriverPosition[0] !== 0 &&
      this.state.currentDriverPosition[1] !== 0
    ) {
      points.push({
        coordinates: currentDriverPosition,
        name: <Trans>DRIVER</Trans>,
        address: "",
        img: this.isDay ? this.state.carIcon : this.state.carIconNight,
        size: 7,
        width:
          this.props.style && this.props.style.carIconWidth
            ? this.props.style.carIconWidth
            : 47,
        height:
          this.props.style && this.props.style.carIconHeight
            ? this.props.style.carIconHeight
            : 128,
        angle: this.getCarAngle(),
        anchorY: 64,
      });
    }
    this.setState({ points });
  };

  getCarAngle = () => {
    const p1 = this.state.currentDriverPosition;
    const p2 = this.state.nextDriverPosition;
    const bearing = this.getBearing(p1[1], p1[0], p2[1], p2[0]);
    const bearingAdjusted = bearing * -1;
    this.setState({ latestBearing: bearingAdjusted });
    return bearingAdjusted;
  };

  //****************************************************************************
  //Credit for the bearing function to @MerseyViking
  radians(n) {
    return n * (Math.PI / 180);
  }
  degrees(n) {
    return n * (180 / Math.PI);
  }

  getBearing(startLat, startLong, endLat, endLong) {
    startLat = this.radians(startLat);
    startLong = this.radians(startLong);
    endLat = this.radians(endLat);
    endLong = this.radians(endLong);

    let dLong = endLong - startLong;

    let dPhi = Math.log(
      Math.tan(endLat / 2.0 + Math.PI / 4.0) /
        Math.tan(startLat / 2.0 + Math.PI / 4.0)
    );
    if (Math.abs(dLong) > Math.PI) {
      if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong);
      else dLong = 2.0 * Math.PI + dLong;
    }

    return (this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
  }
  //****************************************************************************

  recenterCamera = async () => {
    await this.setState({
      viewState: {
        ...this.state.viewState,
        longitude: parseFloat(this.state.currentDriverPosition[0]),
        latitude: parseFloat(this.state.currentDriverPosition[1]),
        zoom: 14,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });
  };

  onViewStateChange({ viewState }) {
    if (viewState.zoom <= 2) return;
    this.setState({ viewState });
  }

  isSameCoord = (c1, c2) => {
    return JSON.stringify(c1) === JSON.stringify(c2);
  };

  animationInterval = null;
  nextPosition = null;

  onWindowFocus = () => {
    if (this.nextPosition === null) {
      return;
    }
    clearInterval(this.animationInterval);
    this.setState({
      currentDriverPosition: [
        this.nextPosition[0].doubleValue(),
        this.nextPosition[1].doubleValue(),
      ],
    });
    this.setPoints();
  };

  startAnimation = async () => {
    clearInterval(this.animationInterval);

    const p1 = this.state.currentDriverPosition;
    const p2 = this.state.nextDriverPosition;
    if (this.isSameCoord(p1, p2)) return;
    const fps = 30;
    const totalFrames = new bd.BigDecimal(REFRESH_RATE_SECONDS * fps);
    let frame = 0;
    const currentDriverPositionXBD = new bd.BigDecimal(p1[0]);
    const currentDriverPositionYBD = new bd.BigDecimal(p1[1]);
    const xDiff = new bd.BigDecimal(p2[0] - p1[0]);
    const yDiff = new bd.BigDecimal(p2[1] - p1[1]);
    const xInterval = xDiff.divide(totalFrames, 25, bd.RoundingMode.DOWN());
    const yInterval = yDiff.divide(totalFrames, 25, bd.RoundingMode.DOWN());
    const positions = [[currentDriverPositionXBD, currentDriverPositionYBD]];
    for (let i = 0; i < totalFrames - 1; i++) {
      positions.push([
        positions[i][0].add(xInterval),
        positions[i][1].add(yInterval),
      ]);
    }
    const that = this;
    this.nextPosition = positions[totalFrames - 1];

    //Interpolation
    this.animationInterval = setInterval(function animateCar() {
      if (frame >= totalFrames - 1) {
        clearInterval(this.animationInterval);
        return;
      }
      that.setState({
        currentDriverPosition: [
          positions[frame][0].doubleValue(),
          positions[frame][1].doubleValue(),
        ],
      });
      that.setPoints();
      frame++;
    }, (REFRESH_RATE_SECONDS * 1000) / totalFrames);
  };

  render() {
    const layers = [
      new GeoJsonLayer({
        id: "directions",
        data: this.state.directions,
        opacity: 1,
        stroked: false,
        filled: false,
        lineWidthMinPixels: 5,
        parameters: {
          depthTest: false,
        },
        getLineColor: this.props.style
          ? this.props.style.lineToDestination
          : [0, 81, 184],
        getLineWidth: 5,
        pickable: true,
      }),
      new GeoJsonLayer({
        id: "pickup_directions",
        data: this.state.pickupDirections,
        opacity: 1,
        stroked: false,
        filled: false,
        lineWidthMinPixels: 5,
        parameters: {
          depthTest: false,
        },
        getLineColor: this.props.style
          ? this.props.style.lineToPickup
          : [43, 179, 199],
        getLineWidth: 5,
        pickable: true,
      }),
      new IconLayer({
        id: "points",
        data: this.state.points.length === 1 ? [] : this.state.points,
        pickable: true,
        getIcon: (d) => {
          return {
          url: d.img,
          width: d.width,
          height: d.height,
          anchorY: d.anchorY,
        }},
        getAngle: (d) => d.angle,
        sizeScale: 10,
        getPosition: (d) => d.coordinates.map((c) => parseFloat(c)),
        getSize: (d) => d.size,
        onHover: (info) =>
          this.setState({
            hoveredObject: info.object,
            pointerX: info.x,
            pointerY: info.y,
          }),
      }),
    ];

    return (
      <div className="map-container">
        <div
          className="center-camera-button"
          onClick={() => this.recenterCamera()}
        >
          <div
            className="map-navigation-recenter-btn"
            style={{
              backgroundColor:
                this.props.style && this.props.style.primaryColor,
            }}
          />
        </div>
        {(this.state.forceUpdate === false) ? <DeckGL
          layers={layers}
          viewState={this.state.viewState || [0, 0]}
          controller={true}
          pickingRadius={5}
          onViewStateChange={(viewState) => this.onViewStateChange(viewState)}
        >
          <StaticMap
            reuseMaps
            preventStyleDiffing={true}
            mapStyle={this.state.mapStyle}
            mapboxApiAccessToken={mapboxToken}
          />
          {this.renderTooltip()}
        </DeckGL> : null}
      </div>
    );
  }
}

export default Map;
