import axios from "axios";

import { store } from "../store/index";
import { removeAccents } from "../views/util";
import { logout } from "../actions";



const API_ADDRESS_V3 = process.env.REACT_APP_API_ADDRESS_V3;
const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;


const encodeGetParams = (p) =>
    Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

let storeState = store.getState();
store.subscribe(() => {
    storeState = store.getState();
});


// Create axios instance with default config
const axiosInstance = axios.create({
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'CaptchaToken': process.env.REACT_APP_CAPCHA_TOKEN,
    }
});

// Add a request interceptor to automatically add auth token and captcha token
axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        if (state.userData && state.userData.userAuthKey) {
            config.headers['Authorization'] = state.userData.userAuthKey;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if error is 401 and request hasn't been retried yet
        if (error.response.status === 401) {

            try {
                // Clear user auth data from store
                store.dispatch(logout());

                // Redirect to login page
                window.location.href = '/login';

                return Promise.reject(new Error('Session expired. Please login again.'));
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);


export function signin(phone, email) {
    const endpoint = `${API_ADDRESS_V3}authentication/`;
    phone = phone ? "+" + phone : null;

    const payload = {};

    if (phone) {
        payload.phone = phone;
    } else if (email) {
        payload.email = email;
    }

    return axiosInstance.post(endpoint, payload)
        .then(response => {
            // Assuming the API response will include some kind of session/token information for the next step
            // console.log('Sign In Data', response.data);
            return {
                success: response.data.status
            };
        })
        .catch(error => {
            console.error('Signin Error:', error);
            return { success: false };
        });
}

export const authenticateUserPartner = async (phone, email, token) => {
    const endpoint = `${API_ADDRESS_V3}authentication/partner`;
    if (phone) phone = "+" + phone;
    token = parseInt(token); // Convert token to an integer


    const response = await axiosInstance.put(endpoint, {
        phone: phone,
        token: token,
        email: email
    });

    return response.data;
    //} catch (error) {
    //    throw error;
    //}
};

export const getTrackingObject = async (trackingId) => {
    console.log('storeState.userData.authToken', storeState.userData.authToken);
    const endpoint = `${API_ADDRESS}api/v1/mia/get_tracking_obj?tracking_id=${trackingId}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export const getCommunitySettings = async (zone_id) => {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_community_settings?zone_id=${zone_id}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export const getRideRequestStatus = async (ride_request_id) => {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_ride_request_status?ride_id=${ride_request_id}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getTrackingStylesPublic(ride_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_tracking_styles?ride_id=${ride_id}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getActiveRide(ride_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_active_ride?ride=${ride_id}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getCompletedRide(ride_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_completed_ride_public?ride=${ride_id}`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getDriverPosition(driver_account) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_driver_last_location?driver_account=${driver_account}`;
    const response = await axiosInstance.get(endpoint).catch(error => {
        console.log('error', error);
    }
    );
    if (response.data.lat == 0 && response.data.lon == 0) {
        return null;
    }
    if (response) {
        return response.data;
    }
}

export async function getAccountSettings(impersonate_id = null) {
    let endpoint = `${API_ADDRESS}api/v1/mia/get_account_settings`;

    if (impersonate_id) {
        endpoint = `${endpoint}?impersonate_id=${impersonate_id}`
    }

    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getRideServicesByType(impersonate_id = null) {
    let endpoint = `${API_ADDRESS}api/v1/mia/get_ride_services_by_type`;

    if (impersonate_id) {
        endpoint = `${endpoint}?impersonate_id=${impersonate_id}`
    }

    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export async function getCommunitySettingsMia() {
    const endpoint = `${API_ADDRESS}api/v1/website/get_community_settings_mia`;
    const response = await axiosInstance.get(endpoint);

    return response.data;
}

export function getAddressAutoComplete(lat, lon, query) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_address_autocomplete?lat=${lat}&lon=${lon}&query=${query}`;
    return axiosInstance.get(endpoint);
}

export async function getAddressDetails(address) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_address_details?address=${address}`;
    return axiosInstance.get(endpoint);
}

export async function getQuote(fromLat, fromLon, toLat, toLon, rideServiceId, impersonate_id) {
    let endpoint = `${API_ADDRESS}api/v1/mia/get_quote_logged?from_lat=${fromLat}&from_lon=${fromLon}&to_lat=${toLat}&to_lon=${toLon}&ride_service_id=${rideServiceId}`;

    if (impersonate_id) {
        endpoint = `${endpoint}&impersonate_id=${impersonate_id}`
    }

    return axiosInstance.get(endpoint)
}

export async function getCompletedCorporateRides(data) {
    const params = encodeGetParams({
        ...data
    });
    const response = await axiosInstance.get(`${API_ADDRESS}api/v1/mia/get_completed_corporate_rides?${params}`);
    console.log('response data', response.data);
    return response.data;
}

export async function getCorporateRides(data) {
    const params = encodeGetParams({
        ...data
    });
    const response = await axiosInstance.get(`${API_ADDRESS}api/v1/mia/get_corporate_rides?${params}`);
    console.log('response data', response.data);
    return response.data;
}



export async function cancelRide(tracking_id) {
    const body = {
        tracking_id: tracking_id,
    };
    return axiosInstance.post(`${API_ADDRESS}api/v1/mia/cancel_ride_mia`, body);
}

export async function getDeliveryProofExistence(tracking_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/delivery_proof_existence/${tracking_id}`;
    return axiosInstance.get(endpoint);
}

export async function getDeliveryProof(tracking_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/delivery_proof/${tracking_id}`;
    return axiosInstance.get(endpoint);
}

/*

export function getDeliveryProof(tracking_id) {
  return sendLoggedGET(`rides/${tracking_id}/delivery-proof`, true, API_VERSION_2, { responseType: "blob" });
}

export function getDeliveryProofExistence(tracking_id) {
  return sendLoggedGET(`rides/${tracking_id}/delivery-proof?exist=true`, true, API_VERSION_2)
    .then(response => {
      console.log(response.data.exists)
      return response.data.exists
    })
    .catch(error => false);
}

  */
export async function getCompletedTrackingObj(tracking_id) {
    const endpoint = `${API_ADDRESS}api/v1/mia/get_completed_tracking_obj?tracking_id=${tracking_id}`;
    return axiosInstance.get(endpoint);
}


/*export function getMicrosoftAuthUrl(client_id = process.env.REACT_APP_MS_SAML_CLIENT) {
  const endpoint = `sso/${client_id}/init`;
  console.log('client_id', client_id);
  console.log('endpoint', endpoint);

  return sendLoggedGET(endpoint)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}*/

export async function getMicrosoftAuthUrl(client_id = process.env.REACT_APP_MS_SAML_CLIENT) {
    const endpoint = `${API_ADDRESS}api/v2/authentication/sso/454/init`;
    return axios.get(endpoint, {
        headers: {
            'accept': 'application/json',
            'CaptchaToken': process.env.REACT_APP_CAPCHA_TOKEN,
            'Content-Type': 'application/json',
        },
    });
}



export async function callRide(
    fromAddress,
    toAddress,
    fromCoords,
    toCoords,
    ride_service_type_id,
    first_name,
    last_name,
    email,
    phone,
    onsiteDatetime,
    orderReferenceNumber,
    pickupCompanyName,
    pickUpPhone,
    tipGivenAmountToken,
    note,
    pickupNote,
    isDeliveryFeePaidInCashAtDoor,
    cashPaymentOrderValue,
    fromApartmentNumber,
    toApartmentNumber,
    impersonate_id,
) {
    first_name = first_name || "---"
    last_name = last_name || "---"
    email = email || 'noreply@goeva.com'
    const addAptNum = (a, n) =>
        a.slice(0, a.indexOf(",")) + ` #${n}` + a.slice(a.indexOf(","));
    if (fromApartmentNumber)
        fromAddress = addAptNum(fromAddress, fromApartmentNumber);
    if (toApartmentNumber) toAddress = addAptNum(toAddress, toApartmentNumber);
    console.log('cashPaymentOrderValue', cashPaymentOrderValue);
    const totalOrderAmount = cashPaymentOrderValue ? cashPaymentOrderValue : 0;
    const pay_at_door = true ? cashPaymentOrderValue > 0 : false;
    console.log('isDeliveryFeePaidInCashAtDoor', isDeliveryFeePaidInCashAtDoor);
    const body = {
        from_latitude: fromCoords["lat"],
        from_longitude: fromCoords["lon"],
        to_latitude: toCoords["lat"],
        to_longitude: toCoords["lon"],
        from_address: removeAccents(fromAddress),
        to_address: removeAccents(toAddress),
        customer_first_name: removeAccents(first_name),
        customer_last_name: removeAccents(last_name),
        customer_phone: phone,
        customer_email: email,
        ride_service_type_id: ride_service_type_id,
        onsite_datetime: onsiteDatetime,
        order_number: orderReferenceNumber,
        pick_up_company_name: removeAccents(pickupCompanyName),
        pick_up_company_phone: pickUpPhone,
        tip_token_amount: tipGivenAmountToken,
        note: note,
        pickup_note: pickupNote,
        total_order_amount: totalOrderAmount,
        pay_at_door,
        pay_at_door_include_delivery_fee: isDeliveryFeePaidInCashAtDoor
    };
    if (phone.length <= 1) {
        delete body['customer_phone']
    }
    if (!pickupCompanyName || pickupCompanyName.length <= 1) {
        delete body['pick_up_company_name']
    }
    if (!pickUpPhone || pickUpPhone.length <= 1) {
        delete body['pick_up_company_phone']
    }


    // impersonated_partner
    if (impersonate_id) {
        body['impersonate_id'] = impersonate_id;
    }

    return axiosInstance.post(`${API_ADDRESS}api/v1/mia/call_ride_mia`, body);
}


export async function getPartners() {
    return axiosInstance.get(`${API_ADDRESS}api/v1/mia/partners`);
}